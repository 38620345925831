import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { Route, useHistory } from 'react-router-dom'
import InventoryProjectionsChart from './charts/InventoryProjectionsChart'
import { Spin, Table, Column, Modal, Menu, Dropdown, Icon } from './common/Ant'
import { Bread, Crumb } from './common/Bread'
import { Anchor, ActionAnchor } from './common/Anchor'
import { H3, H4 } from './common/Headers'
import Divider from './common/Divider'
import Select, { Option } from './common/Select'
import EditInventoryProposal from './EditInventoryProposal'
import ConvertInventoryProposal from './ConvertInventoryProposal'
import { dateFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'
import { createQueryString } from '../utils/queryParams'
import {
  POD_STATUS,
  GATEWAY_STATUS,
  FLOW_MONITOR_STATUS,
  RESOURCE_TYPES,
} from '../constants'

const InventoryForecasts = ({
  counts,
  projections: {
    monthlyProjections,
    upcomingInstalls,
    upcomingUninstalls,
    proposedInventory,
    meta,
  },
  podMetadata,
  getAllDeployments,
  getInventoryProjections,
  getPodMetadata,
  isLoading,
  match,
  createInventoryProposal,
  updateInventoryProposal,
  getInventoryProposal,
  deleteInventoryProposal,
  convertInventoryProposal,
  proposal,
}) => {
  const history = useHistory()

  const {
    firmwareVersions,
    productVersions,
    leakFirmwareVersions,
    leakProductVersions,
    evacFirmwareVersions,
    evacProductVersions,
  } = podMetadata
  const allFirmwareVersions = [
    ...new Set([...firmwareVersions, ...leakFirmwareVersions, ...evacFirmwareVersions]),
  ]
  const allProductVersions = [
    ...new Set([...productVersions, ...leakProductVersions, ...evacProductVersions]),
  ]

  const [isLegendVisible, setIsLegendVisible] = useState(false)
  const [podStatus, setPodStatus] = useState(undefined)
  const [podProductVersion, setPodProductVersion] = useState(['2.3.1', '2.3.2'])
  const [podFirmwareVersion, setPodFirmwareVersion] = useState(undefined)
  const [flowMonitorStatus, setFlowMonitorStatus] = useState(undefined)
  const [gatewayStatus, setGatewayStatus] = useState(undefined)

  useEffect(() => {
    getAllDeployments()
    getInventoryProjections(createQueryString({ podProductVersion }))
    getPodMetadata()
  }, [
    getAllDeployments,
    getInventoryProjections,
    getPodMetadata,
    podProductVersion,
  ])

  const handleFilterChange = keyName => value => {
    getInventoryProjections(
      createQueryString({
        ...{ podStatus, podFirmwareVersion, podProductVersion },
        [keyName]: value,
      })
    )

    switch (keyName) {
      case 'podStatus':
        setPodStatus(value)
        break
      case 'gatewayStatus':
        setGatewayStatus(value)
        break
      case 'flowMonitorStatus':
        setFlowMonitorStatus(value)
        break
      case 'podFirmwareVersion':
        setPodFirmwareVersion(value)
        break
      case 'podProductVersion':
        setPodProductVersion(value)
        break
    }
  }

  const handleModalCancel = () => {
    history.push(match.url)
  }

  const handleEditProposal = x => {
    history.push(`${match.url}/proposal/${x.slug}/edit`)
  }

  const handleConvertProposal = x => {
    history.push(`${match.url}/proposal/${x.slug}/convert`)
  }

  const setRowBackgroundColor = date =>
    moment(date).isBefore(moment(new Date())) ? 'bg-light-red' : ''

  return (
    <div className="InventoryForecasts">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>
              <Anchor to="/inventory/all">Inventory</Anchor>
            </Crumb>
            <Crumb>Forecasts</Crumb>
          </Bread>
        </div>
        <div className="flex flex-wrap justify-center mb3">
          <span>
            <Anchor to={`${match.url}/proposal/create`}>
              Create Inventory Proposal
            </Anchor>
          </span>
        </div>
      </div>
      <Divider />
      {isLoading ? (
        <Spin size="large" className="w-100 center mt5" />
      ) : (
        <>
          <H3 className="tc">
            Inventory Projections and Deployment Requirements
          </H3>

          <InventoryProjectionsChart
            counts={counts}
            monthlyProjections={monthlyProjections}
          />

          <div className="flex-l items-center ph5-l mb3">
            <Select
              className="w-100 w-third-l mr3-l"
              placeholder="Smart Pod Status"
              mode="multiple"
              input={{
                value: podStatus,
                onChange: handleFilterChange('podStatus'),
              }}
              filterable
            >
              {Object.keys(POD_STATUS).map(x => (
                <Option value={x} key={x}>
                  {toTitleCase(POD_STATUS[x])}
                </Option>
              ))}
            </Select>
            <Select
              className="w-100 w-third-l mr3-l"
              placeholder="Smart Pod Firmware Version"
              mode="multiple"
              input={{
                value: podFirmwareVersion,
                onChange: handleFilterChange('podFirmwareVersion'),
              }}
              filterable
            >
              {allFirmwareVersions
                .filter(x => !!x)
                .map(x => (
                  <Option value={x} key={x}>
                    {x}
                  </Option>
                ))}
            </Select>
            <Select
              className="w-100 w-third-l"
              placeholder="Smart Pod Product Version"
              mode="multiple"
              input={{
                value: podProductVersion,
                onChange: handleFilterChange('podProductVersion'),
              }}
              filterable
            >
              {allProductVersions
                .filter(x => !!x)
                .map(x => (
                  <Option value={x} key={x}>
                    {x}
                  </Option>
                ))}
            </Select>
          </div>
          <div className="flex-l items-center ph5-l mb3">
            <Select
              className="w-100 w-50-l mr3-l"
              placeholder="Network Gateway Status"
              mode="multiple"
              input={{
                value: gatewayStatus,
                onChange: handleFilterChange('gatewayStatus'),
              }}
              filterable
            >
              {Object.keys(GATEWAY_STATUS).map(x => (
                <Option value={x} key={x}>
                  {toTitleCase(GATEWAY_STATUS[x])}
                </Option>
              ))}
            </Select>
            <Select
              className="w-100 w-50-l"
              placeholder="Water Monitor Status"
              mode="multiple"
              input={{
                value: flowMonitorStatus,
                onChange: handleFilterChange('flowMonitorStatus'),
              }}
              filterable
            >
              {Object.keys(FLOW_MONITOR_STATUS).map(x => (
                <Option value={x} key={x}>
                  {toTitleCase(FLOW_MONITOR_STATUS[x])}
                </Option>
              ))}
            </Select>
          </div>

          <div className="mb5 ph5-l">
            <div className="flex items-center mb3">
              <H3 inline noMargin>
                Legend
              </H3>
              <span
                className="pointer link dark-blue"
                onClick={() => setIsLegendVisible(!isLegendVisible)}
              >
                {isLegendVisible ? 'Hide' : 'Show'}
              </span>
            </div>
            {isLegendVisible && (
              <>
                <div className="bg-light-gray pa3 br2 mb3">
                  <H4>Smart Pods</H4>
                  <p>Loss rates assumed in projections:</p>
                  <ul className="list">
                    <li>
                      On-Site:{' '}
                      <span className="b">{meta.pods.lostOnSiteRate}</span>
                    </li>
                    <li>
                      Refurbishment:{' '}
                      <span className="b">{meta.pods.refurbishmmentRate}</span>
                    </li>
                  </ul>
                  <p>Statuses included in projections: </p>
                  <ul className="list">
                    {meta.pods.countedStatuses.map(x => (
                      <li className="b" key={x}>
                        {toTitleCase(x)}
                      </li>
                    ))}
                  </ul>
                  <p>Product versions included in projections: </p>
                  <ul className="list">
                    {meta.pods.productVersions.map(x => (
                      <li className="b" key={x}>
                        {x}
                      </li>
                    ))}
                  </ul>
                  <p>Firmware versions included in projections: </p>
                  <ul className="list">
                    {meta.pods.firmwareVersions.map(x => (
                      <li className="b" key={x}>
                        {x}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="bg-light-gray pa3 br2 mb3">
                  <H4>Network Gateways</H4>
                  <p>Statuses included in projections: </p>
                  <ul className="list">
                    {meta.gateways.countedStatuses.map(x => (
                      <li className="b" key={x}>
                        {toTitleCase(x)}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="bg-light-gray pa3 br2 mb3">
                  <H4>Water Monitors</H4>
                  <p>Statuses included in projections: </p>
                  <ul className="list">
                    {meta.flowMonitors.countedStatuses.map(x => (
                      <li className="b" key={x}>
                        {toTitleCase(x)}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
          <Divider />
        </>
      )}
      <H4>Upcoming Installs</H4>
      <Table
        dataSource={upcomingInstalls}
        rowKey="deploymentSlug"
        pagination={false}
        size="small"
        scroll={{ x: 500 }}
        tableLayout="auto"
        rowClassName={record => setRowBackgroundColor(record.estimatedDate)}
      >
        <Column
          title="Deployment"
          dataIndex="description"
          render={(text, record) => (
            <Anchor
              to={`/sites/${record.siteSlug}/deployments/${record.deploymentSlug}`}
            >
              {text ? text : dateFormatter(record.estimatedDate)}
            </Anchor>
          )}
        />
        <Column
          title="Site"
          dataIndex="siteName"
          render={(text, record) => (
            <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
          )}
        />
        <Column
          title="Estimated Date"
          dataIndex="estimatedDate"
          render={text => (text ? dateFormatter(text) : '--')}
        />
        <Column title="Smart Pods" dataIndex="pods" />
        <Column title="Network Gateways" dataIndex="gateways" />
        <Column title="Water Monitors" dataIndex="flowMonitors" />
      </Table>
      <H4>Upcoming Uninstalls</H4>
      <Table
        dataSource={upcomingUninstalls}
        rowKey="deploymentSlug"
        pagination={false}
        size="small"
        scroll={{ x: 500 }}
        tableLayout="auto"
        rowClassName={record => setRowBackgroundColor(record.estimatedDate)}
      >
        <Column
          title="Deployment"
          dataIndex="description"
          render={(text, record) => (
            <Anchor
              to={`/sites/${record.siteSlug}/deployments/${record.deploymentSlug}`}
            >
              {text ? text : dateFormatter(record.estimatedDate)}
            </Anchor>
          )}
        />
        <Column
          title="Site"
          dataIndex="siteName"
          render={(text, record) => (
            <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
          )}
        />
        <Column
          title="Estimated Date"
          dataIndex="estimatedDate"
          render={text => (text ? dateFormatter(text) : '--')}
        />
        <Column title="Smart Pods" dataIndex="pods" />
        <Column title="Network Gateways" dataIndex="gateways" />
        <Column title="Water Monitors" dataIndex="flowMonitors" />
      </Table>
      <H4>Inventory Proposals</H4>
      <Table
        dataSource={proposedInventory}
        rowKey="slug"
        pagination={false}
        size="small"
        scroll={{ x: 500 }}
        tableLayout="auto"
        rowClassName={record => setRowBackgroundColor(record.estimatedDate)}
      >
        <Column
          title="Estimated Date"
          dataIndex="estimatedDate"
          render={text => (text ? dateFormatter(text) : '--')}
        />
        <Column
          title="Resource Type"
          dataIndex="resourceType"
          render={toTitleCase}
        />
        <Column title="Quantity" dataIndex="quantity" />
        <Column
          title="Product Version"
          dataIndex="productVersion"
          render={text => (text ? text : '--')}
        />

        <Column
          width={100}
          render={(text, record) => {
            const menu = (
              <Menu>
                <Menu.Item key="edit-inventory-proposal">
                  <ActionAnchor onClick={() => handleEditProposal(record)}>
                    Edit
                  </ActionAnchor>
                </Menu.Item>
                {[RESOURCE_TYPES.POD, RESOURCE_TYPES.LEAK_POD, RESOURCE_TYPES.EVAC_POD].indexOf(
                  record.resourceType
                ) !== -1 && (
                  <Menu.Item key="generate-pillar-ids">
                    <ActionAnchor onClick={() => handleConvertProposal(record)}>
                      Convert to Inventory
                    </ActionAnchor>
                  </Menu.Item>
                )}
                <Menu.Item key="delete-inventory-proposal">
                  <ActionAnchor
                    onClick={() =>
                      deleteInventoryProposal(
                        record.slug,
                        createQueryString({
                          podStatus,
                          podFirmwareVersion,
                          podProductVersion,
                        })
                      )
                    }
                  >
                    Delete
                  </ActionAnchor>
                </Menu.Item>
              </Menu>
            )

            return (
              <Dropdown overlay={menu} trigger={['click']}>
                <a className="ant-dropdown-link">
                  Actions <Icon type="down" />
                </a>
              </Dropdown>
            )
          }}
        />
      </Table>
      <Route
        path={[
          `${match.url}/proposal/create`,
          `${match.url}/proposal/:slug/edit`,
          `${match.url}/proposal/:slug/convert`,
        ]}
        exact
        render={props => (
          <>
            <Helmet>
              <title>Inventory Proposal</title>
            </Helmet>
            <Modal
              className="Modal"
              visible
              footer={null}
              onCancel={handleModalCancel}
              destroyOnClose
            >
              {global.location.pathname.indexOf('/convert') !== -1 ? (
                <ConvertInventoryProposal
                  proposal={proposal}
                  convertInventoryProposal={convertInventoryProposal}
                  getInventoryProposal={getInventoryProposal}
                  {...props}
                />
              ) : (
                <EditInventoryProposal
                  podMetadata={podMetadata}
                  proposal={proposal}
                  createInventoryProposal={createInventoryProposal}
                  updateInventoryProposal={updateInventoryProposal}
                  handleModalCancel={handleModalCancel}
                  getInventoryProposal={getInventoryProposal}
                  getInventoryProjections={() =>
                    getInventoryProjections(
                      createQueryString({
                        podStatus,
                        podFirmwareVersion,
                        podProductVersion,
                      })
                    )
                  }
                  {...props}
                />
              )}
            </Modal>
          </>
        )}
      />
    </div>
  )
}

InventoryForecasts.propTypes = {
  getAllDeployments: PropTypes.func.isRequired,
  getInventoryProjections: PropTypes.func.isRequired,
  getPodMetadata: PropTypes.func.isRequired,
  counts: PropTypes.object.isRequired,
  projections: PropTypes.object.isRequired,
  podMetadata: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.shape({ url: PropTypes.string }).isRequired,
  createInventoryProposal: PropTypes.func.isRequired,
  updateInventoryProposal: PropTypes.func.isRequired,
  getInventoryProposal: PropTypes.func.isRequired,
  deleteInventoryProposal: PropTypes.func.isRequired,
  convertInventoryProposal: PropTypes.func.isRequired,
  proposal: PropTypes.object.isRequired,
}

export default InventoryForecasts
