import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import moment from 'moment'
import { Table, Column, Spin, RadioButton, RadioGroup } from './common/Ant'
import Select, { Option } from './common/Select'
import { Anchor } from './common/Anchor'
import { Bread, Crumb } from './common/Bread'
import InputContainer from '../containers/common/InputContainer'
import { dateFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'
import { configurePagination, escapeRegExp } from '../utils/helpers'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  DEPLOYMENT_STATUS,
  DEPLOYMENT_TYPE,
  INSTALL_TYPE,
  VIEW_ALL,
} from '../constants'

class AllDeployments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.deployments,
      searchText: '',
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      installType: undefined,
      status: undefined,
      siteSlug: undefined,
    }
  }

  static propTypes = {
    deployments: PropTypes.arrayOf(PropTypes.object).isRequired,
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    meta: PropTypes.object.isRequired,
    getAllDeployments: PropTypes.func.isRequired,
    getAllSites: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    isDeploymentLoading: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    this.handleSearch()
  }

  // TODO possibly move most of these API calls into DeploymentsPage?
  componentDidMount() {
    const {
      getAllDeployments,
      getAllSites,
      updateQueryParams,
      url,
    } = this.props

    const { page, perPage, status, siteSlug } = parseQueryParams(
      global.location
    )
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      status: status
        ? status
        : [
            DEPLOYMENT_STATUS.CREATED,
            DEPLOYMENT_STATUS.PACKING,
            DEPLOYMENT_STATUS.PACKED,
            DEPLOYMENT_STATUS.SHIPPED,
          ],
      siteSlug,
    }

    this.setState(query)

    const qs = createQueryString(query)

    getAllDeployments(qs)
    getAllSites(createQueryString({ perPage: 'all' }))

    updateQueryParams({
      pathname: url,
      search: qs,
    })
  }

  handleChange = (pagination, filters, sorter) => {
    const { url, getAllDeployments, updateQueryParams } = this.props
    const { perPage, page, installType, status, siteSlug } = this.state

    if (page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
        installType,
        status,
        siteSlug,
      }

      const qs = createQueryString(query)

      getAllDeployments(qs)

      updateQueryParams({
        pathname: url,
        search: qs,
      })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllDeployments, updateQueryParams } = this.props
    const { page, perPage, installType, status, siteSlug } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    const query = Object.assign(
      { page, perPage, installType, status, siteSlug },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllDeployments(qs)

    updateQueryParams({ search: qs })
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.deployments
        .map(record => {
          const match =
            (record.description && record.description.match(regex)) ||
            record.installType.match(regex) ||
            record.status.match(regex)

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  render() {
    const { sites, isDeploymentLoading, meta } = this.props
    const {
      data,
      sortedInfo,
      searchText,
      perPage,
      installType,
      status,
      siteSlug,
    } = this.state

    return (
      <section className="mb5 AllDeployments">
        <div className="mb3">
          <Bread>
            <Crumb>Deployments</Crumb>
          </Bread>
        </div>
        {isDeploymentLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Fragment>
            <div className="flex-l items-center-l">
              <RadioGroup
                onChange={({ target: { value } }) =>
                  this.handleFilterChange('installType')(value)
                }
                value={installType}
                className="w-25-l mb3 mr3 mr3-l"
                size="small"
              >
                <RadioButton value={undefined}>All</RadioButton>
                <RadioButton value={INSTALL_TYPE.INSTALL}>Install</RadioButton>
                <RadioButton value={INSTALL_TYPE.UNINSTALL}>
                  Uninstall
                </RadioButton>
              </RadioGroup>
              <Select
                className="w-50-l mr3-l"
                placeholder="Status"
                mode="multiple"
                input={{
                  value: status,
                  onChange: this.handleFilterChange('status'),
                }}
                filterable
              >
                {['all', ...Object.keys(DEPLOYMENT_STATUS)].map(x => (
                  <Option value={DEPLOYMENT_STATUS[x] || x} key={x}>
                    {toTitleCase(DEPLOYMENT_STATUS[x]) || VIEW_ALL}
                  </Option>
                ))}
              </Select>
              <Select
                className="w-25-l"
                placeholder="Site"
                mode="multiple"
                input={{
                  value: siteSlug,
                  onChange: this.handleFilterChange('siteSlug'),
                }}
                filterable
              >
                {[{ slug: 'all', name: VIEW_ALL }, ...sites].map(x => (
                  <Option value={x.slug} key={x.slug}>
                    {x.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="table-filter">
              <InputContainer
                input={{
                  placeholder: 'Filter',
                  value: searchText,
                  onChange: this.handleInputChange,
                }}
              />
            </div>
            <Table
              bordered
              dataSource={data}
              onChange={this.handleChange}
              pagination={configurePagination({
                perPage,
                onShowSizeChange: (page, perPage) =>
                  this.handleFilterChange('perPage')(perPage),
                ...meta,
              })}
              rowKey="slug"
              scroll={{ x: 650 }}
              tableLayout="auto"
              rowClassName={record =>
                moment(record.estimatedDate).isBefore(moment(new Date()))
                  ? 'bg-light-red'
                  : ''
              }
            >
              <Column
                title="Deployment"
                dataIndex="description"
                width={100}
                sorter={sortBy('description')}
                sortOrder={
                  sortedInfo.field === 'description' && sortedInfo.order
                }
                render={(text, record) => {
                  const link = record.description
                    ? record.description
                    : dateFormatter(record.estimatedDate)

                  return (
                    <Anchor
                      to={`/sites/${record.siteSlug}/deployments/${record.slug}`}
                    >
                      {link}
                    </Anchor>
                  )
                }}
              />
              <Column
                title="Site"
                dataIndex="siteName"
                width={50}
                sorter={sortBy('siteName')}
                sortOrder={sortedInfo.field === 'siteName' && sortedInfo.order}
              />
              <Column
                title="Status"
                dataIndex="status"
                width={50}
                sorter={sortBy('status')}
                sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
                render={(text, record) =>
                  record.status ? toTitleCase(record.status) : '--'
                }
              />
              <Column
                title="Estimated Date"
                dataIndex="estimatedDate"
                width={100}
                sorter={sortBy('estimatedDate')}
                sortOrder={
                  sortedInfo.field === 'estimatedDate' && sortedInfo.order
                }
                render={(text, record) => dateFormatter(record.estimatedDate)}
              />
              <Column
                title="Smart Pods"
                dataIndex="estimatedPods"
                width={50}
                render={(text, record) => {
                  if (record.status === DEPLOYMENT_STATUS.CREATED) {
                    return record.estimatedPods
                      ? `${record.estimatedPods} (Est.)`
                      : '--'
                  } else {
                    return record.actualPods ? record.actualPods : '--'
                  }
                }}
              />
              <Column
                title="Leak Pucks"
                dataIndex="estimatedLeakPods"
                width={50}
                render={(text, record) => {
                  if (record.status === DEPLOYMENT_STATUS.CREATED) {
                    return record.estimatedLeakPods
                      ? `${record.estimatedLeakPods} (Est.)`
                      : '--'
                  } else {
                    return record.actualLeakPods ? record.actualLeakPods : '--'
                  }
                }}
              />
              <Column
                title="Evac Pods"
                dataIndex="estimatedEvacPods"
                width={50}
                render={(text, record) => {
                  if (record.status === DEPLOYMENT_STATUS.CREATED) {
                    return record.estimatedEvacPods
                      ? `${record.estimatedEvacPods} (Est.)`
                      : '--'
                  } else {
                    return record.actualEvacPods ? record.actualEvacPods : '--'
                  }
                }}
              />
              <Column
                title="Network Gateways"
                dataIndex="estimatedGateways"
                width={50}
                render={(text, record) => {
                  if (record.status === DEPLOYMENT_STATUS.CREATED) {
                    return record.estimatedGateways
                      ? `${record.estimatedGateways} (Est.)`
                      : '--'
                  } else {
                    return record.actualGateways ? record.actualGateways : '--'
                  }
                }}
              />
              <Column
                title="Water Monitors"
                dataIndex="estimatedFlowMonitors"
                width={50}
                render={(text, record) => {
                  if (record.status === DEPLOYMENT_STATUS.CREATED) {
                    return record.estimatedFlowMonitors
                      ? `${record.estimatedFlowMonitors} (Est.)`
                      : '--'
                  } else {
                    return record.actualFlowMonitors
                      ? record.actualFlowMonitors
                      : '--'
                  }
                }}
              />
              <Column
                title="Gateway Supplies"
                dataIndex="anyGatewaySupply"
                width={100}
                sorter={sortBy('anyGatewaySupply')}
                sortOrder={
                  sortedInfo.field === 'anyGatewaySupply' && sortedInfo.order
                }
                render={(text, record) =>
                  record.anyGatewaySupply ? 'Yes' : 'No'
                }
              />
              <Column
                title="Install Type"
                dataIndex="installType"
                width={100}
                sorter={sortBy('installType')}
                sortOrder={
                  sortedInfo.field === 'installType' && sortedInfo.order
                }
                render={(text, record) =>
                  record.installType ? toTitleCase(record.installType) : '--'
                }
              />
              <Column
                title="Deployed"
                dataIndex="deploymentType"
                width={100}
                sorter={sortBy('deploymentType')}
                sortOrder={
                  sortedInfo.field === 'deploymentType' && sortedInfo.order
                }
                render={(text, record) =>
                  record.deploymentType
                    ? toTitleCase(DEPLOYMENT_TYPE[record.deploymentType])
                    : '--'
                }
              />
              <Column
                title="Shipped"
                width={100}
                dataIndex="shippingDate"
                render={(text, record) =>
                  record.shippingDate
                    ? dateFormatter(record.shippingDate)
                    : '--'
                }
              />
              <Column
                title="Delivered"
                width={100}
                dataIndex="deliveryDate"
                render={(text, record) =>
                  record.deliveryDate
                    ? dateFormatter(record.deliveryDate)
                    : '--'
                }
              />
            </Table>
          </Fragment>
        )}
      </section>
    )
  }
}

export default AllDeployments
