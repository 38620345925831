import produce from 'immer'
import { combineReducers } from 'redux'
import {
  getAllFlowMonitorsAction,
  getAllSiteFlowMonitorAction,
  getFlowMonitorDataAction,
  getFlowMonitorAction,
  getAllAvailableFlowMonitorsBySiteAction,
  getFlowMonitorEditHistoryAction,
  getFlowMonitorAssignmentHistoryAction,
  getValveStatusHistoryAction,
  getAllAvailableWaterValvesAction,
  getAllAvailableWaterMetersAction,
  getAllWaterMetersAction,
  getWaterMeterAction,
  getAllWaterValvesAction,
  getWaterValveAction,
} from '../actions/flowMonitorsActions'
import { toTitleCase } from '../utils/textFormatters'
import { showErrorMessageAction } from '../actions/uiActions'
import { pickErrorMessage } from '../utils/helpers'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllFlowMonitorsAction.SUCCESS:
      case getAllWaterMetersAction.SUCCESS:
      case getAllWaterValvesAction.SUCCESS:
        payload.items.forEach(flowMonitor => {
          draft[flowMonitor.id] = flowMonitor
        })
        break
      case getAllAvailableFlowMonitorsBySiteAction.SUCCESS:
      case getAllAvailableWaterMetersAction.SUCCESS:
      case getAllAvailableWaterValvesAction.SUCCESS:
        payload.forEach(flowMonitor => {
          draft[flowMonitor.id] = flowMonitor
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllFlowMonitorsAction.SUCCESS:
      case getAllWaterMetersAction.SUCCESS:
      case getAllWaterValvesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(flowMonitor => flowMonitor.id)
        )
        break
    }
  })

const availableVisibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailableFlowMonitorsBySiteAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(flowMonitor => flowMonitor.id)
        )
        break
    }
  })

const availableVisibleWaterValveIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailableWaterValvesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(waterValve => waterValve.id)
        )
        break
    }
  })

const availableVisibleWaterMeterIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailableWaterMetersAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.map(waterMeter => waterMeter.id)
        )
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllFlowMonitorsAction.SUCCESS:
      case getAllWaterMetersAction.SUCCESS:
      case getAllWaterValvesAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const allSiteFlowMonitorsData = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllSiteFlowMonitorAction.SUCCESS:
        draft.splice(0, draft.length, ...payload)
        break
    }
  })

const allFlowMonitorData = (state = {}, { type, payload }) =>
    produce(state, draft => {
    console.log("Flow Monitor Data: ", payload)
    switch (type) {
      case getFlowMonitorDataAction.SUCCESS:
        const readingNames = {
          lastHour: 'Last Hour',
          last24Hours: 'Last 24 Hours',
          last3Days: 'Last 3 Days',
          lastWeek: 'Last Week',
        }
        const tableData =
          payload && payload.lastAvgData
            ? Object.keys(readingNames).map(key => {
                const avgValue = payload.lastAvgData[`${key}Avg`]
                  ? parseFloat(payload.lastAvgData[`${key}Avg`]).toFixed(2)
                  : '--'
                return {
                  name: readingNames[key],
                  avgValue: avgValue,
                }
              })
            : []

        payload['tableData'] = tableData

        Object.assign(draft, payload)
        break
    }
  })

const current = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getFlowMonitorAction.SUCCESS:
      case getWaterMeterAction.SUCCESS:
      case getWaterValveAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const editHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getFlowMonitorEditHistoryAction.SUCCESS:
        const editHistory = action.payload.items
        const newEditHistory = []

        if (editHistory.length) {
          editHistory.forEach((row, i) => {
            const { updatedBy, date, changedFields } = row
            let changes
            try {
              changes = JSON.parse(changedFields)
            } catch (error) {
              showErrorMessageAction(pickErrorMessage(error))
            }

            // each row has an updatedBy, date, field changed, to, from
            if (changes) {
              Object.keys(changes).forEach((field, j) => {
                let to = changes[field].to
                let from = changes[field].from

                // Dates are in the format YYYY/MM/DD
                try {
                  to = JSON.parse(to)
                } catch (error) {
                  if (!to) {
                    to = '--'
                  } else if (field === 'built_date') {
                    to = to
                      .split('-')
                      .map(date => parseInt(date.trim(), 10))
                      .join('/')
                  } else if (field === 'status') {
                    to = toTitleCase(to)
                  } else {
                    showErrorMessageAction(pickErrorMessage(error))
                  }
                }

                try {
                  from = JSON.parse(from)
                } catch (error) {
                  if (!from) {
                    from = '--'
                  } else if (field === 'built_date') {
                    from = from
                      .split('-')
                      .map(date => parseInt(date.trim(), 10))
                      .join('/')
                  } else if (field === 'status') {
                    from = toTitleCase(from)
                  } else {
                    showErrorMessageAction(pickErrorMessage(error))
                  }
                }

                // unique IDs are hard to come by...
                const tableId = i.toString() + j.toString()

                // push the new value
                newEditHistory.push({
                  updatedBy,
                  date,
                  field,
                  to,
                  from,
                  tableId,
                })
              })
            }
          })
        }

        draft.splice(0, draft.length, ...newEditHistory)
        break
    }
  })

const assignmentHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getFlowMonitorAssignmentHistoryAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.items)
        break
    }
  })

const valveStatusHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getValveStatusHistoryAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload)
        break
    }
  })

const flowMonitorsReducer = combineReducers({
  byId,
  visibleIds,
  current,
  meta,
  allFlowMonitorData,
  allSiteFlowMonitorsData,
  availableVisibleIds,
  availableVisibleWaterValveIds,
  availableVisibleWaterMeterIds,
  assignmentHistory,
  valveStatusHistory,
  editHistory,
})

const getFlowMonitor = (state, id) => state.byId[id]
const getCurrentFlowMonitor = state => state.current
const getVisibleFlowMonitors = state =>
  state.visibleIds.map(id => getFlowMonitor(state, id))
const getCurrentWaterMeter = state => state.current
const getVisibleWaterMeters = state =>
  state.visibleIds.map(id => getFlowMonitor(state, id))
const getCurrentWaterValve = state => state.current
const getVisibleWaterValves = state =>
  state.visibleIds.map(id => getFlowMonitor(state, id))
const getFlowMonitorsMeta = state => state.meta
const getAvailableVisibleWaterValves = state =>
  state.availableVisibleWaterValveIds.map(id => getFlowMonitor(state, id))
const getAvailableVisibleWaterMeters = state =>
  state.availableVisibleWaterMeterIds.map(id => getFlowMonitor(state, id))
const getVisibleAllFlowMonitorsData = ({ allSiteFlowMonitorsData }) =>
  allSiteFlowMonitorsData
const getVisibleFlowMonitorData = ({ allFlowMonitorData }) => allFlowMonitorData
const getAvailableVisibleFlowMonitors = state =>
  state.availableVisibleIds.map(id => getFlowMonitor(state, id))
const getVisibleFlowMonitorEditHistory = state => state.editHistory
const getVisibleFlowMonitorAssignmentHistory = state => state.assignmentHistory
const getVisibleValveStatusHistory = state => state.valveStatusHistory

export {
  flowMonitorsReducer as default,
  getCurrentFlowMonitor,
  getVisibleFlowMonitors,
  getFlowMonitorsMeta,
  getVisibleAllFlowMonitorsData,
  getVisibleFlowMonitorData,
  getAvailableVisibleFlowMonitors,
  getVisibleFlowMonitorEditHistory,
  getVisibleFlowMonitorAssignmentHistory,
  getVisibleValveStatusHistory,
  getAvailableVisibleWaterValves,
  getAvailableVisibleWaterMeters,
  getCurrentWaterMeter,
  getVisibleWaterMeters,
  getCurrentWaterValve,
  getVisibleWaterValves,
}
