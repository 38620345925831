import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import { Spin } from '../common/Ant'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { required, isNumeric, isAlphaNumeric } from '../../utils/validators'
import { H3 } from '../common/Headers'
import { WATER_METER_TYPES } from '../../constants'

class EditWaterMeterForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    isWaterMeterLoading: PropTypes.bool,
    error: PropTypes.string,
  }

  render() {
    const {
      submitAction,
      handleSubmit,
      submitting,
      isWaterMeterLoading,
      error,
    } = this.props

    const submit = handleSubmit(submitAction)

    return isWaterMeterLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EditWaterMeterForm">
        <Form onSubmit={submit}>
          <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
            <H3>Water Meter Info </H3>
            <div className="flex-ns">
              <Field
                name="meterSerialId"
                type="text"
                component={InputContainer}
                label="Serial id"
                validate={[required, isAlphaNumeric]}
                className="mr3-ns w-third-ns"
              />
              <Field
                name="meterType"
                component={SelectContainer}
                label="Meter type"
                className="w-third-ns mr3-ns"
                validate={[required]}
                placeholder="Select a Meter Type"
                filterable
              >
                {Object.keys(WATER_METER_TYPES).map(x => (
                  <Option value={x} key={x}>
                    {WATER_METER_TYPES[x]}
                  </Option>
                ))}
              </Field>
              <Field
                name="meterDescription"
                type="text"
                component={InputContainer}
                label="Description"
                className="w-third-ns"
              />
            </div>
            <div className="flex-ns">
              <Field
                name="pipeOd"
                type="text"
                component={InputContainer}
                label="Pipe OD"
                validate={[required, isNumeric]}
                className="mr3-ns w-25-ns"
              />
              <Field
                name="pipeId"
                type="text"
                component={InputContainer}
                label="Pipe ID"
                validate={isNumeric}
                className="mr3-ns w-25-ns"
              />
              <Field
                name="pipeMaxFlow"
                type="text"
                component={InputContainer}
                label="Max Flow"
                validate={[required, isNumeric]}
                className="mr3-ns w-25-ns"
              />
              <Field
                name="pipeMinFlow"
                type="text"
                component={InputContainer}
                label="Min Flow"
                validate={[required, isNumeric]}
                className="w-25-ns"
              />
            </div>
            <div className="flex-ns">
              <Field
                name="pipeMaxTemp"
                type="text"
                component={InputContainer}
                label="Max Temp"
                validate={[required, isNumeric]}
                className="mr3-ns w-25-ns"
              />
              <Field
                name="pipeMinTemp"
                type="text"
                component={InputContainer}
                label="Min Temp"
                validate={[required, isNumeric]}
                className="mr3-ns w-25-ns"
              />
              <Field
                name="flowOffset"
                type="text"
                component={InputContainer}
                label="Flow Offset"
                validate={[required, isNumeric]}
                className="mr3-ns w-25-ns"
              />
              <Field
                name="fpsConstant"
                type="text"
                component={InputContainer}
                label="Flow Constant (fps/mA)"
                validate={[required, isNumeric]}
                className="w-25-ns"
              />
            </div>
          </div>
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/inventory/all/water-meters" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditWaterMeterForm' })(EditWaterMeterForm)
