import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import { Spin } from '../common/Ant'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { required, isNumeric, isAlphaNumeric } from '../../utils/validators'
import { H3 } from '../common/Headers'
import {
  WATER_METER_TYPES,
  WATER_VALVE_TYPES,
  FLOW_MONITOR_STATUS,
  WATER_VALVE_STATUS,
} from '../../constants'
import { toTitleCase } from '../../utils/textFormatters'

let availableWaterMeters = []
let availableWaterValves = []
class EditFlowMonitorForm extends Component {
  static propTypes = {
    setWaterMeterFields: PropTypes.func.isRequired,
    setWaterValveFields: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object).isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    allAvailableWaterValves: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableWaterMeters: PropTypes.arrayOf(PropTypes.object).isRequired,
    isFlowMonitorLoading: PropTypes.bool,
    error: PropTypes.string,
  }

  state = {
    disableValveSerial:
      this.props.initialValues && this.props.initialValues.valveId
        ? true
        : false,
    disableValve:
      this.props.initialValues && this.props.initialValues.valveId
        ? false
        : true,
    disableMeterSerial:
      this.props.initialValues && this.props.initialValues.meterId
        ? true
        : false,
    disableMeter:
      this.props.initialValues && this.props.initialValues.meterId
        ? false
        : true,
    currentValveStatus:
      this.props.initialValues && this.props.initialValues.valveId
        ? this.props.initialValues.valveStatus
        : '',
  }

  componentDidUpdate(prevProps, prevState) {
    const { initialValues } = this.props

    if (initialValues && prevProps.initialValues) {
      //update water meter states
      if (initialValues.meterId !== prevProps.initialValues.meterId) {
        this.setState({
          disableMeterSerial: initialValues.meterId ? true : false,
          disableMeter: initialValues.meterId ? false : true,
        })
      }
      //update water valve states
      if (initialValues.valveId !== prevProps.initialValues.valveId) {
        this.setState({
          disableValveSerial: initialValues.valveId ? true : false,
          disableValve: initialValues.valveId ? false : true,
          currentValveStatus: initialValues.valveId
            ? initialValues.valveStatus
            : '',
        })
      }
    }
  }

  handleValveChange = value => {
    if (value > 0) {
      //existing valve
      const currentValve = availableWaterValves.filter(x => x.id === value)
      this.props.setWaterValveFields(currentValve)
      this.setState({
        disableValve: false,
        disableValveSerial: true,
        currentValveStatus: currentValve.length
          ? currentValve[0].valveStatus
          : '',
      })
    } else {
      this.props.setWaterValveFields([])
      if (!value) {
        //unassigned valve
        this.setState({
          disableValve: true,
          disableValveSerial: true,
          currentValveStatus: '',
        })
      } else {
        //new valve
        this.setState({
          disableValve: false,
          disableValveSerial: false,
          currentValveStatus: '',
        })
      }
    }
  }

  handleMeterChange = value => {
    if (value > 0) {
      //existing valve
      this.setState({ disableMeter: false, disableMeterSerial: true })
      this.props.setWaterMeterFields(
        availableWaterMeters.filter(x => x.id === value)
      )
    } else {
      this.props.setWaterMeterFields([])
      if (!value) {
        //unassigned valve
        this.setState({ disableMeter: true, disableMeterSerial: true })
      } else {
        //new valve
        this.setState({ disableMeter: false, disableMeterSerial: false })
      }
    }
  }

  render() {
    const {
      submitAction,
      handleSubmit,
      submitting,
      allAvailablePods,
      allAvailableWaterValves,
      allAvailableWaterMeters,
      assetLocations,
      initialValues,
      isFlowMonitorLoading,
      error,
    } = this.props
    const {
      disableValve,
      disableValveSerial,
      disableMeter,
      disableMeterSerial,
      currentValveStatus,
    } = this.state

    const submit = handleSubmit(submitAction)

    availableWaterMeters = [...allAvailableWaterMeters]
    //add current water meter to available
    if (initialValues && initialValues.meterId) {
      availableWaterMeters.push({
        id: initialValues.meterId,
        meterSerialId: initialValues.meterSerialId,
        meterType: initialValues.meterType,
        pipeId: initialValues.pipeId,
        pipeOd: initialValues.pipeOd,
        pipeMaxFlow: initialValues.pipeMaxFlow,
        pipeMaxTemp: initialValues.pipeMaxTemp,
        pipeMinFlow: initialValues.pipeMinFlow,
        pipeMinTemp: initialValues.pipeMinTemp,
        meterDescription: initialValues.meterDescription,
        flowOffset: initialValues.flowOffset,
      })
    }

    availableWaterValves = [...allAvailableWaterValves]
    //add current water valve to available
    if (initialValues && initialValues.valveId) {
      availableWaterValves.push({
        id: initialValues.valveId,
        valveSerialId: initialValues.valveSerialId,
        valveType: initialValues.valveType,
        valveStatus: initialValues.valveStatus,
        valvePipeId: initialValues.valvePipeId,
        valvePipeOd: initialValues.valvePipeOd,
        valveDescription: initialValues.valveDescription,
      })
    }

    return isFlowMonitorLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EditFlowMonitorForm">
        <Form onSubmit={submit}>
          <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
            <H3>Monitor Info </H3>
            <div className="flex-ns">
              <Field
                name="pillarId"
                type="text"
                component={InputContainer}
                label="Pillar ID"
                validate={[required, isAlphaNumeric]}
                className="mr3-ns w-30-ns"
              />
              <Field
                name="proxletId"
                type="text"
                component={InputContainer}
                label="Proxlet ID"
                validate={[required, isAlphaNumeric]}
                className="mr3-ns w-30-ns"
              />
              <Field
                name="podPillarId"
                component={SelectContainer}
                label="POD"
                placeholder="Select a pod"
                className="mr3-ns w-30-ns"
                filterable
              >
                {[
                  { id: 0, pillarId: '-- Unassigned --' },
                  ...allAvailablePods,
                ].map(x => (
                  <Option value={x.id} key={x.id}>
                    {x.pillarId}
                  </Option>
                ))}
              </Field>
              <Field
                name="piSerialId"
                type="text"
                component={InputContainer}
                label="Pi Serial"
                validate={[required, isAlphaNumeric]}
                className="w-40-ns"
              />
            </div>
            <div className="flex-ns">
              <Field
                name="status"
                component={SelectContainer}
                label="Status"
                placeholder="Select a status"
                className="mr3-ns w-30-ns"
                filterable
              >
                {Object.keys(FLOW_MONITOR_STATUS).map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(FLOW_MONITOR_STATUS[x])}
                  </Option>
                ))}
              </Field>
              <Field
                name="assetLocationId"
                component={SelectContainer}
                label="Asset Location"
                className="w-30-ns mr3-ns"
                disabled={
                  initialValues &&
                  initialValues.status === FLOW_MONITOR_STATUS.DEPLOYED
                }
                props={{ placeholder: 'Select an Asset Location' }}
                filterable
              >
                {[
                  { id: 0, pillarId: '-- Unassigned --' },
                  ...assetLocations,
                ].map(x => (
                  <Option value={x.id} key={x.id}>
                    {x.pillarId}
                  </Option>
                ))}
              </Field>
              <Field
                name="description"
                type="text"
                component={InputContainer}
                label="Description"
                className="w-40-ns"
              />
            </div>
            <Divider />
            <H3>Water Meter Info </H3>
            <div className="flex-ns">
              <Field
                name="meterId"
                component={SelectContainer}
                label="Selected Meter"
                placeholder="Select a meter"
                className="mr3-ns w-25-ns"
                onChange={this.handleMeterChange}
                validate={[required]}
                filterable
              >
                {[
                  { id: 0, meterSerialId: '-- Unassigned --' },
                  { id: -1, meterSerialId: '-- Create New --' },
                  ...availableWaterMeters,
                ].map(x => (
                  <Option value={x.id} key={x.id}>
                    {x.meterSerialId}
                  </Option>
                ))}
              </Field>
              <Field
                name="meterSerialId"
                type="text"
                component={InputContainer}
                label="Meter Serial"
                validate={
                  disableMeterSerial || disableMeter
                    ? []
                    : [required, isAlphaNumeric]
                }
                className="mr3-ns w-25-ns"
                disabled={disableMeterSerial || disableMeter}
              />
              <Field
                name="meterType"
                component={SelectContainer}
                label="Meter type"
                className="mr3-ns w-25-ns"
                validate={disableMeter ? [] : [required]}
                placeholder="Select a Meter Type"
                disabled={disableMeter}
                filterable
              >
                {Object.keys(WATER_METER_TYPES).map(x => (
                  <Option value={x} key={x}>
                    {WATER_METER_TYPES[x]}
                  </Option>
                ))}
              </Field>
              <Field
                name="meterDescription"
                type="text"
                component={InputContainer}
                label="Description"
                className="w-25-ns"
                disabled={disableMeter}
              />
            </div>
            <div className="flex-ns">
              <Field
                name="pipeOd"
                type="text"
                component={InputContainer}
                label="Pipe OD"
                validate={disableMeter ? [] : [required, isNumeric]}
                className="mr3-ns w-25-ns"
                disabled={disableMeter}
              />
              <Field
                name="pipeId"
                type="text"
                component={InputContainer}
                label="Pipe ID"
                validate={disableMeter ? [] : isNumeric}
                className="mr3-ns w-25-ns"
                disabled={disableMeter}
              />
              <Field
                name="pipeMaxFlow"
                type="text"
                component={InputContainer}
                label="Max Flow"
                validate={disableMeter ? [] : [required, isNumeric]}
                className="mr3-ns w-25-ns"
                disabled={disableMeter}
              />
              <Field
                name="pipeMinFlow"
                type="text"
                component={InputContainer}
                label="Min Flow"
                validate={disableMeter ? [] : [required, isNumeric]}
                className="w-25-ns"
                disabled={disableMeter}
              />
            </div>
            <div className="flex-ns">
              <Field
                name="pipeMaxTemp"
                type="text"
                component={InputContainer}
                label="Max Temp"
                validate={disableMeter ? [] : [required, isNumeric]}
                className="mr3-ns w-25-ns"
                disabled={disableMeter}
              />
              <Field
                name="pipeMinTemp"
                type="text"
                component={InputContainer}
                label="Min Temp"
                validate={disableMeter ? [] : [required, isNumeric]}
                className="mr3-ns w-25-ns"
                disabled={disableMeter}
              />
              <Field
                name="flowOffset"
                type="text"
                component={InputContainer}
                label="Flow Offset"
                validate={disableMeter ? [] : [required, isNumeric]}
                className="mr3-ns w-25-ns"
                disabled={disableMeter}
              />
              <Field
                name="fpsConstant"
                type="text"
                component={InputContainer}
                label="Flow Constant (fps/mA)"
                className="w-25-ns"
                disabled={disableMeter}
              />
            </div>
            <Divider />
            <H3> Water Valve Info</H3>
            <div className="flex-ns">
              <Field
                name="valveId"
                component={SelectContainer}
                label="Selected Valve"
                placeholder="Select a valve"
                className="mr3-ns w-third-ns"
                onChange={this.handleValveChange}
                validate={[required]}
                filterable
              >
                {[
                  { id: 0, valveSerialId: '-- Unassigned --' },
                  { id: -1, valveSerialId: '-- Create New --' },
                  ...availableWaterValves,
                ].map(x => (
                  <Option value={x.id} key={x.id}>
                    {x.valveSerialId}
                  </Option>
                ))}
              </Field>
              <Field
                name="valveSerialId"
                type="text"
                component={InputContainer}
                label="Valve serial"
                validate={
                  disableValveSerial || disableValve
                    ? []
                    : [required, isAlphaNumeric]
                }
                className="mr3-ns w-third-ns"
                disabled={disableValveSerial || disableValve}
              />
              <Field
                name="valveType"
                component={SelectContainer}
                label="Valve type"
                className="w-third-ns"
                validate={disableValve ? [] : [required]}
                placeholder="Select a Meter Type"
                disabled={disableValve}
                filterable
              >
                {Object.keys(WATER_VALVE_TYPES).map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(WATER_VALVE_TYPES[x])}
                  </Option>
                ))}
              </Field>
            </div>
            <div className="flex-ns">
              <Field
                name="valveStatus"
                component={SelectContainer}
                label="Valve status"
                placeholder="Select a status"
                className="mr3-ns w-25-ns"
                validate={disableValve ? [] : [required]}
                disabled={
                  disableValve ||
                  currentValveStatus === WATER_VALVE_STATUS.MAINTENANCE
                }
                filterable
              >
                {// only allow select valve status to change
                // NOT ALLOWED: x->MAINTENANCE or OPEN->CLOSE or CLOSE->OPEN
                Object.keys(WATER_VALVE_STATUS)
                  .filter(v =>
                    currentValveStatus === WATER_VALVE_STATUS.OPEN
                      ? v === WATER_VALVE_STATUS.OPEN ||
                        v === WATER_VALVE_STATUS.MISSING
                      : currentValveStatus === WATER_VALVE_STATUS.CLOSE
                      ? v === WATER_VALVE_STATUS.CLOSE ||
                        v === WATER_VALVE_STATUS.MISSING
                      : v !== WATER_VALVE_STATUS.MAINTENANCE
                  )
                  .map(x => (
                    <Option value={x} key={x}>
                      {toTitleCase(WATER_VALVE_STATUS[x])}
                    </Option>
                  ))}
              </Field>
              <Field
                name="valvePipeOd"
                type="text"
                component={InputContainer}
                label="Valve pipe OD"
                validate={disableValve ? [] : [required, isNumeric]}
                className="mr3-ns w-25-ns"
                disabled={disableValve}
              />
              <Field
                name="valvePipeId"
                type="text"
                component={InputContainer}
                label="Valve pipe ID"
                validate={disableValve ? [] : isNumeric}
                className="mr3-ns w-25-ns"
                disabled={disableValve}
              />
              <Field
                name="valveDescription"
                type="text"
                component={InputContainer}
                label="Valve description"
                className="w-25-ns"
                disabled={disableValve}
              />
            </div>
          </div>
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/inventory/all/flow-monitors" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditFlowMonitorForm' })(EditFlowMonitorForm)
