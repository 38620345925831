import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Anchor } from '../common/Anchor'
import FormHeader from '../common/FormHeader'
import Label from '../common/Label'
import api from '../../services/api'

import Form from '../common/Form'
import { reduxForm, Field } from 'redux-form'
import Divider from '../common/Divider'
import { H3 } from '../common/Headers'
import { Spin } from '../common/Ant'


const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const statusList = ['Closed', 'Open']

// Allow minutes to be in increments of 15
const MINUTE_TICKS = 15



class InputField extends Component {
  static propTypes = {
    // pillarId: PropTypes.number.isRequired,
    id: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    match: PropTypes.object
  }

  render() {
    return (
      <span className="flex flex-column mh3 mv1">
        <label className='b' htmlFor={this.props.id}>{this.props.label}</label>
        <input
          type={this.props.type ? this.props.type : "text"}
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          required={this.props.required ? this.props.required : false}
          disabled={this.props.disabled ? this.props.disabled : false}
        />
      </span>
    )
  }
}

class SelectField extends Component {
  static propTypes = {
    // pillarId: PropTypes.number.isRequired,
    id: PropTypes.string,
    options: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    match: PropTypes.object
  }

  render() {
    return (
      <span className='flex flex-column mh3 mv1'>
        <label className='b' htmlFor={this.props.id}>{this.props.label}</label>
        <select
          type="number"
          id={this.props.id}
          name={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          required
        >
          {this.props.options.map((e, index) => (
            <option value={index}>{e}</option>
          ))}
        </select>
      </span>
    )
  }
}


class FlowMonitorSchedule extends Component {
  static propTypes = {
    // pillarId: PropTypes.number.isRequired,
    match: PropTypes.object
  }

  constructor(props) {
    console.log("Flow Monitor Schedule constructor")
    super(props)

    this.state = {
        isLoading: true,
        windows: [{
            startDay: 0,
            startTime: '',
            endDay: 0,
            endTime: '',
            threshold: 0
        }],
        monitor: null,
        inactive_threshold: 0,
        inactive_valve: 0,
    }

    // Setters
    this.setLoading = this.setLoading.bind(this)
    this.setWindows = this.setWindows.bind(this)
    this.setMonitor = this.setMonitor.bind(this)
    this.setInactiveThreshold = this.setInactiveThreshold.bind(this)
    this.setInactiveStatus = this.setInactiveStatus.bind(this)

    // API
    this.getFlowMonitor = this.getFlowMonitor.bind(this)
    this.getFlowSchedule = this.getFlowSchedule.bind(this)

    // Window Management
    this.handleAddWindow = this.handleAddWindow.bind(this)
    this.handleRemoveWindow = this.handleRemoveWindow.bind(this)
    this.handleCopyWindow = this.handleCopyWindow.bind(this)

    // Form Functions
    // this.cleanMinute = this.cleanMinute.bind(this)
    this.handleWindowInputChange = this.handleWindowInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    console.log("component did mount")
    
    this.getFlowMonitor()
    this.getFlowSchedule()
  }

  // ================
  // Setters
  // ================
  setLoading(isLoading) {
    this.setState({
        isLoading: isLoading,
        windows: this.state.windows,
        monitor: this.state.monitor,
        inactive_threshold: this.state.inactive_threshold,
        inactive_valve: this.state.inactive_valve
    })
  }

  setWindows(windows) {
    this.setState({
        isLoading: this.state.isLoading,
        windows: windows,
        monitor: this.state.monitor,
        inactive_threshold: this.state.inactive_threshold,
        inactive_valve: this.state.inactive_valve
    })
  }

  setMonitor(monitor) {
    this.setState({
        isLoading: this.state.isLoading,
        windows: this.state.windows,
        monitor: monitor,
        inactive_threshold: this.state.inactive_threshold,
        inactive_valve: this.state.inactive_valve
    })
  }

  setInactiveThreshold(threshold) {
    this.setState({
        isLoading: this.state.isLoading,
        windows: this.state.windows,
        monitor: this.state.monitor,
        inactive_threshold: threshold,
        inactive_valve: this.state.inactive_valve
    })
  }

  setInactiveStatus(valve_status) {
    this.setState({
        isLoading: this.state.isLoading,
        windows: this.state.windows,
        monitor: this.state.monitor,
        inactive_threshold: this.state.inactive_threshold,
        inactive_valve: valve_status
    })
  }

  // ================
  // API Functions
  // ================
  getFlowSchedule() {
    const { match } = this.props
    const { id } = match.params;

    // Query api for flow schedule
    api.getFlowSchedule(id)
        .then(data => {
            this.setInactiveStatus(data['inactiveStatus'])
            this.setInactiveThreshold(data['inactiveThreshold'])
            this.setWindows(data['windows'])
            this.setLoading(false)
        })
        .catch(e => {
          console.log(e)
          this.setLoading(false)
        })
  }

  getFlowMonitor() {
    const { match } = this.props
    const { id } = match.params;

    console.log("get flow monitor")
    console.log("flow monitor: ")
    // console.log(flowMonitor)
    console.log("id : ", id)

    api.getFlowMonitor(id)
        .then(res => { this.setMonitor(res) })
  }

  // =================
  // Window Management
  // =================
  handleAddWindow() {
    const { windows } = this.state;

    windows.push({
        startDay: 0,
        startTime: '',
        endDay: 0,
        endTime: '',
        threshold: 0
    })

    this.setWindows(windows)
  }

  handleRemoveWindow(index) {
    const { windows } = this.state;

    windows.splice(index, 1);
    this.setWindows(windows)
  };

  handleCopyWindow(index) {
    const { windows } = this.state;
    
    let window = windows[index]
    let new_window = {
        startDay: window.startDay,
        startTime: window.startTime,
        endDay: window.endDay,
        endTime: window.endTime,
        threshold: window.threshold
    }

    windows.splice(index, 0, new_window);
    this.setWindows(windows)
  };

  // =================
  // Form Functions
  // =================
//   cleanMinute(ts) {
//     try {
//         let time_values = ts.split(":")
//         let minutes = parseInt(time_values[1])

//         if ((minutes % MINUTE_TICKS) < (MINUTE_TICKS / 2)) {
//             minutes = minutes - (minutes % MINUTE_TICKS)
//         } else {
//             minutes = Math.ceil(minutes / MINUTE_TICKS) * MINUTE_TICKS
//         }

//         return time_values[0] + ":" + minutes.toString()
//     } catch (e) {
//         return ts
//     }
//   }

  handleWindowInputChange(index, event) {
    const {windows} = this.state;
    windows[index][event.target.name] = event.target.value;

    // if (event.target.name === "startDay") {
    //     windows[index].startDay = event.target.value;
    // }
    // else if (event.target.name === "startTime") {
    //     let value = event.target.value;
    //     // let value = this.cleanMinute(event.target.value)
    //     windows[index].startTime = value;
    // }
    // else if (event.target.name === "endDay") {
    //     windows[index].endDay = event.target.value;
    // }
    // else if (event.target.name === "endTime") {
    //     let value = event.target.value;
    //     // let value = this.cleanMinute(event.target.value)
    //     windows[index].endTime = value;
    // }
    // else if (event.target.name === "threshold") {
    //     windows[index].threshold = event.target.value;
    // }

    this.setWindows(windows)
  }

  handleSubmit(event) {
    const { monitor, windows, inactive_threshold, inactive_valve } = this.state

    event.preventDefault();
    this.setLoading(true)

    api.pushFlowSchedule(monitor.id, windows, inactive_threshold, inactive_valve)
        .then(resp => {
            this.getFlowSchedule()
        })
        .catch(e => {
            console.error(e)
        })
  }

  render() {
    // const { pillarId } = this.props
    // const { id } = flowMonitor
    const { isLoading, windows, monitor, inactive_threshold, inactive_valve } = this.state

    console.log("- Windows: ", windows)

    if (isLoading) {
      return (<Spin size="large" className="w-100 center mv5" />)
    }
    else {
      const crumbs = [
        <Anchor to="/inventory/all">Inventory</Anchor>,
        <Anchor to="/inventory/all/flow-monitors">Water Monitors</Anchor>,
        monitor && <Anchor to={`/inventory/flow-monitors/${monitor.id}/details`}>{monitor.pillarId}</Anchor>,
        'Edit Flow Schedule',
      ]

      return (
        <section className="FlowMonitorSchedule">
          <FormHeader crumbs={crumbs} />

          <div className="flex justify-center">
            <Label>Setup schedule for Flow Monitor</Label>
          </div>

          <div className='ScheduleForm'>
            <form onSubmit={this.handleSubmit}>
              <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
                <span className='flex mv1 mh3'>
                  <H3>Schedule Info</H3>
                </span>
                <div className='flex'>
                  <InputField
                    label="Inactive Threshold (Gallons/min)"
                    type="text"
                    id="inactiveThreshold"
                    name="inactiveThreshold"
                    value={inactive_threshold}
                    onChange={(e) => {this.setInactiveThreshold(e.target.value)}}
                    required={true}
                  />
                  <SelectField
                    label="Inactive Status"
                    options={statusList}
                    id="inactiveValve"
                    name="inactiveValve"
                    value={inactive_valve}
                    onChange={(e) => {this.setInactiveStatus(e.target.value)}}
                    required={true}
                  />
                </div>
              </div>

              {windows.map((window, index) => (
                <div key={index}>
                  <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
                    <div className='flex'>
                      <span className='mh3 mv1'>
                        <H3>Window Event {index + 1}</H3>
                      </span>
                    </div>
                    
                    <div className='flex'>
                      <SelectField
                        label="Start Day"
                        options={daysOfWeek}
                        id={`startDay-${index}`}
                        name="startDay"
                        value={window.startDay}
                        onChange={(e) => {this.handleWindowInputChange(index, e)}}
                        required={true}
                      />
                      <InputField
                        label="Open Time"
                        type="time"
                        id={`startTime-${index}`}
                        name="startTime"
                        value={window.startTime}
                        onChange={(e) => {this.handleWindowInputChange(index, e)}}
                        required={true}
                      />
                      <InputField
                        label="Threshold (Gallons/min)"
                        type="text"
                        id={`threshold-${index}`}
                        name="threshold"
                        value={window.threshold}
                        onChange={(e) => {this.handleWindowInputChange(index, e)}}
                        required={true}
                      />
                    </div>
                    <div className='flex'>
                      <SelectField
                        label="End Day"
                        options={daysOfWeek}
                        id={`endDay-${index}`}
                        name="endDay"
                        value={window.endDay}
                        onChange={(e) => {this.handleWindowInputChange(index, e)}}
                        required={true}
                      />
                      <InputField
                        label="Close Time"
                        type="time"
                        id={`endTime-${index}`}
                        name="endTime"
                        value={window.endTime}
                        onChange={(e) => {this.handleWindowInputChange(index, e)}}
                        required={true}
                      />
                      <button type="button" className="pa1 ma3" onClick={() => this.handleRemoveWindow(index)}>Remove</button>
                      <button type="button" className="pa1 ma3" onClick={() => this.handleCopyWindow(index)}>Copy</button>
                    </div>
                  </div>
                </div>
              ))}

              <div className="mb3 ba b--light-gray br2 pa2 overflow-auto">
                <button type="button" className="pa1 ma3" onClick={this.handleAddWindow}>Add Window</button>
                <button className="pa1 ma3" type="submit">Submit</button>
              </div>
            </form>
          </div>
        </section>
      )
    }
  }
}


// const schedule_style = StyleSheet.create({
//     label: {
//         fontWeight: "bold",
//     }
// })

export default FlowMonitorSchedule
