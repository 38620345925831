import produce from 'immer'
import { combineReducers } from 'redux'
import sortBy from 'sort-by'
import {
  REMOVE_BUILDING_FROM_FLOOR,
  CLEAR_CURRENT_FLOOR,
  getAllFloorsAction,
  getFloorAction,
  updateFloorAction,
  getFloorSummaryAction,
  getAllFloorSummariesAction,
  getAllFloorThresholdUserMappingsAction,
  getAllFloorsByBuildingAction,
  getAllFloorSummariesByBuildingAction,
} from '../actions/floorsActions'

const getHealthyCount = x => {
  const count =
    x.totalCount - x.thresholdAlertsLocationCount - x.systemAlertsLocationCount

  return count > 0 ? count : 0
}

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action
    switch (type) {
      case getAllFloorsAction.SUCCESS:
        payload.forEach(floor => {
          draft[floor.id] = floor
        })
        break
      case getAllFloorsByBuildingAction.SUCCESS:
        payload.forEach(floor => {
          draft[floor.id] = floor
        })
        break
    }
  })

const floorSummaryById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action
    switch (type) {
      case getAllFloorSummariesAction.SUCCESS:
        payload.data.forEach(summary => (draft[summary.floorId] = summary))
        break
      case getAllFloorSummariesByBuildingAction.SUCCESS:
        payload.data.forEach(summary => (draft[summary.floorId] = summary))
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllFloorsAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.map(floor => floor.id))
        break
      case getAllFloorsByBuildingAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.map(floor => floor.id))
        break
    }
  })

const current = (state = { thresholds: [] }, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getFloorAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
      case getFloorSummaryAction.SUCCESS:
        draft.summary = action.payload
        break
      case updateFloorAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
      case REMOVE_BUILDING_FROM_FLOOR:
        draft.buildingId = null
        draft.buildingName = ''
        draft.buildingNumber = ''
        break
      case CLEAR_CURRENT_FLOOR:
        return {}
    }
  })

const thresholdUserMappings = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllFloorThresholdUserMappingsAction.SUCCESS:
        const result = payload.users
          .map(user => {
            const thresholds = payload.userThresholdMapping
              .filter(x => x.user === user.slug)
              .map(x => {
                const index = payload.thresholds
                  .map(y => y.thresholdSlug)
                  .indexOf(x.threshold)

                const active =
                  x.settings.smsNotifications || x.settings.emailNotifications
                const mappingId = x.settings.id

                return { ...payload.thresholds[index], active, mappingId }
              })

            return Object.assign(user, { thresholds })
          })
          .sort(sortBy('role'))

        draft.splice(0, draft.length, ...result)
        break
    }
  })

const floorsReducer = combineReducers({
  byId,
  visibleIds,
  current,
  floorSummaryById,
  thresholdUserMappings,
})

const getFloor = (state, floor) => state.byId[floor]
const getCurrentFloor = state => state.current
const getFloorSummaryById = (state, id) => state.floorSummaryById[id]

const getVisibleFloors = state =>
  state.visibleIds.map(id => getFloor(state, id)).sort(sortBy('sortOrder'))

// const getVisibleFloorSummaries = state =>
//   state.visibleIds.map(id => getFloorSummaryById(state, id))

const getVisibleFloorsWithSummaries = state =>
  state.visibleIds
    .map(id => {
      const floor = getFloor(state, id)
      const {
        stats: { leakPod, evacPod, smartPod, flowMonitor },
      } = floor

      return {
        ...floor,
        stats: {
          ...floor.stats,
          leakPod: {
            ...leakPod,
            healthyLocationsCount: getHealthyCount(leakPod),
          },
          evacPod: {
            ...evacPod,
            healthyLocationsCount: getHealthyCount(evacPod),
          },
          smartPod: {
            ...smartPod,
            healthyLocationsCount: getHealthyCount(smartPod),
          },
          flowMonitor: {
            ...flowMonitor,
            healthyLocationsCount: getHealthyCount(flowMonitor),
          },
        },
        summary: getFloorSummaryById(state, id),
      }
    })
    .sort(sortBy('sortOrder'))

const getFloorThresholdUserMappings = ({ thresholdUserMappings }) =>
  thresholdUserMappings

export {
  floorsReducer as default,
  getCurrentFloor,
  getVisibleFloors,
  getVisibleFloorsWithSummaries,
  getFloorThresholdUserMappings,
}
