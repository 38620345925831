import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
// import { Link } from 'react-router-dom'
import { H3 } from './common/Headers'
import ActiveAlerts from './ActiveAlerts'
import ActiveSites from './ActiveSites'
import { Spin, Tooltip } from './common/Ant'
import Divider from './common/Divider'

const calculatePercentage = (x, total) => {
  let result = Math.round((x * 100) / total)

  if (x > 0 && result === 0) {
    return 1
  }

  return result
}

// TODO rename AdminDashboard & assoc fns as no longer only an Admin dashboard
class AdminDashboard extends Component {
  static propTypes = {
    getDashboard: PropTypes.func.isRequired,
    gatewayAlerts: PropTypes.arrayOf(PropTypes.object),
    thresholdAlerts: PropTypes.arrayOf(PropTypes.object).isRequired,
    allActiveSites: PropTypes.arrayOf(PropTypes.object).isRequired,
    counts: PropTypes.object.isRequired,
    isAlertLoading: PropTypes.bool.isRequired,
    isSiteLoading: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { getDashboard } = this.props

    getDashboard()
  }

  render() {
    const {
      thresholdAlerts,
      gatewayAlerts,
      allActiveSites,
      counts,
      isAlertLoading,
      isSiteLoading,
    } = this.props

    const activeAlerts = thresholdAlerts.filter(x => x.alertActive)

    const {
      activePods,
      activeActionableAlerts,
      activeInformationalAlerts,
      activeSystemAlerts,
      activeFlowMonitors,
      activeActionableFlowAlerts,
      activeInformationalFlowAlerts,
      activeSystemFlowAlerts,
      activeLeakPods,
      activeActionableLeakAlerts,
      activeInformationalLeakAlerts,
      activeSystemLeakAlerts,
      activeEvacPods,
      activeActionableEvacAlerts,
      activeInformationalEvacAlerts,
      activeSystemEvacAlerts,
      activeGateways,
      activeGatewayAlerts,
    } = counts

    const totalPods =
      activePods +
      activeActionableAlerts +
      activeInformationalAlerts +
      activeSystemAlerts

    const totalFlowMonitors =
      activeFlowMonitors +
      activeActionableFlowAlerts +
      activeInformationalFlowAlerts +
      activeSystemFlowAlerts

    const totalLeakPods =
      activeLeakPods +
      activeActionableLeakAlerts +
      activeInformationalLeakAlerts +
      activeSystemLeakAlerts

    const totalEvacPods =
      activeEvacPods +
      activeActionableEvacAlerts +
      activeInformationalEvacAlerts +
      activeSystemEvacAlerts

    const totalGateways = activeGateways + activeGatewayAlerts

    const onlineLocationsPercentage = calculatePercentage(activePods, totalPods)
    const offlineLocationsPercentage = calculatePercentage(
      activeSystemAlerts,
      totalPods
    )
    const actionAlertLocationsPercentage = calculatePercentage(
      activeActionableAlerts,
      totalPods
    )
    const infoAlertLocationsPercentage = calculatePercentage(
      activeInformationalAlerts,
      totalPods
    )

    const onlineFlowLocationsPercentage = calculatePercentage(
      activeFlowMonitors,
      totalFlowMonitors
    )
    const offlineFlowLocationsPercentage = calculatePercentage(
      activeSystemFlowAlerts,
      totalFlowMonitors
    )
    const infoAlertFlowLocationsPercentage = calculatePercentage(
      activeInformationalFlowAlerts,
      totalFlowMonitors
    )
    const actionAlertFlowLocationsPercentage = calculatePercentage(
      activeActionableFlowAlerts,
      totalFlowMonitors
    )

    const onlineLeakPodsPercentage = calculatePercentage(
      activeLeakPods,
      totalLeakPods
    )
    const offlineLeakPodsPercentage = calculatePercentage(
      activeSystemLeakAlerts,
      totalLeakPods
    )
    const infoAlertLeakPodsPercentage = calculatePercentage(
      activeInformationalLeakAlerts,
      totalLeakPods
    )
    const actionAlertLeakPodsPercentage = calculatePercentage(
      activeActionableLeakAlerts,
      totalLeakPods
    )

    const onlineEvacPodsPercentage = calculatePercentage(
        activeEvacPods,
        totalEvacPods
      )
      const offlineEvacPodsPercentage = calculatePercentage(
        activeSystemEvacAlerts,
        totalEvacPods
      )
      const infoAlertEvacPodsPercentage = calculatePercentage(
        activeInformationalEvacAlerts,
        totalEvacPods
      )
      const actionAlertEvacPodsPercentage = calculatePercentage(
        activeActionableEvacAlerts,
        totalEvacPods
      )

    const offlineGatewaysPercentage = calculatePercentage(
      activeGatewayAlerts,
      totalGateways
    )

    const onlineGatewaysPercentage = calculatePercentage(
      activeGateways,
      totalGateways
    )

    return (
      <section className="AdminDashboard">
        <H3>Network Status</H3>
        <div className="mb4">
          {totalPods > 0 && (
            <div className="flex items-center mb2">
              <div className="w-20 b">Smart Pods</div>
              <div className="flex ba b--moon-gray br2 w-80">
                <Tooltip title={`${activePods} Smart Pods Healthy`}>
                  <div
                    className={cx('h1 bg-green', {
                      'br1 br--left':
                        !!offlineLocationsPercentage ||
                        !!infoAlertLocationsPercentage ||
                        !!actionAlertLocationsPercentage,
                      br1:
                        !offlineLocationsPercentage &&
                        !infoAlertLocationsPercentage &&
                        !actionAlertLocationsPercentage,
                    })}
                    style={{ width: `${onlineLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip title={`${activeSystemAlerts} Smart Pods Offline`}>
                  <div
                    className={cx('h1 bg-orange', {
                      'br1 br--left': !onlineLocationsPercentage,
                      'br1 br--right':
                        !infoAlertLocationsPercentage &
                        !actionAlertLocationsPercentage,
                      br1:
                        !onlineLocationsPercentage &&
                        !infoAlertLocationsPercentage &&
                        !actionAlertLocationsPercentage,
                    })}
                    style={{ width: `${offlineLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip title={`${activeActionableAlerts} Actionable Alerts`}>
                  <div
                    className={cx('h1 bg-dark-red', {
                      'br1 br--left':
                        !onlineLocationsPercentage &
                        !offlineLocationsPercentage,
                      'br1 br--right': !infoAlertLocationsPercentage,
                      br1:
                        !onlineLocationsPercentage &&
                        !infoAlertLocationsPercentage &&
                        !offlineLocationsPercentage,
                    })}
                    style={{ width: `${actionAlertLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeInformationalAlerts} Informational Alerts`}
                >
                  <div
                    className={cx('h1 bg-gold', {
                      'br1 br--right':
                        !!onlineLocationsPercentage ||
                        !!offlineLocationsPercentage ||
                        !!actionAlertLocationsPercentage,
                      br1:
                        !onlineLocationsPercentage &&
                        !offlineLocationsPercentage &
                          !actionAlertLocationsPercentage,
                    })}
                    style={{ width: `${infoAlertLocationsPercentage}%` }}
                  />
                </Tooltip>
              </div>
            </div>
          )}

          {totalFlowMonitors > 0 && (
            <div className="flex items-center mb2">
              <div className="w-20 b">Water Monitors</div>
              <div className="flex ba b--moon-gray br2 w-80">
                <Tooltip title={`${activeFlowMonitors} Water Monitors Healthy`}>
                  <div
                    className={cx('h1 bg-green', {
                      'br1 br--left':
                        !!offlineFlowLocationsPercentage ||
                        !!infoAlertFlowLocationsPercentage ||
                        !!actionAlertFlowLocationsPercentage,
                      br1:
                        !offlineFlowLocationsPercentage &&
                        !infoAlertFlowLocationsPercentage &&
                        !actionAlertFlowLocationsPercentage,
                    })}
                    style={{ width: `${onlineFlowLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeSystemFlowAlerts} Water Monitors Offline`}
                >
                  <div
                    className={cx('h1 bg-orange', {
                      'br1 br--left': !onlineFlowLocationsPercentage,
                      'br1 br--right':
                        !infoAlertFlowLocationsPercentage &
                        !actionAlertFlowLocationsPercentage,
                      br1:
                        !onlineFlowLocationsPercentage &&
                        !infoAlertFlowLocationsPercentage &&
                        !actionAlertFlowLocationsPercentage,
                    })}
                    style={{ width: `${offlineFlowLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeActionableFlowAlerts} Actionable Flow Alerts`}
                >
                  <div
                    className={cx('h1 bg-dark-red', {
                      'br1 br--left':
                        !onlineFlowLocationsPercentage &
                        !offlineFlowLocationsPercentage,
                      'br1 br--right': !infoAlertFlowLocationsPercentage,
                      br1:
                        !onlineFlowLocationsPercentage &&
                        !infoAlertFlowLocationsPercentage &&
                        !offlineFlowLocationsPercentage,
                    })}
                    style={{ width: `${actionAlertFlowLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeInformationalFlowAlerts} Informational Alerts`}
                >
                  <div
                    className={cx('h1 bg-gold', {
                      'br1 br--right':
                        !!onlineFlowLocationsPercentage ||
                        !!offlineFlowLocationsPercentage ||
                        !!actionAlertFlowLocationsPercentage,
                      br1:
                        !onlineFlowLocationsPercentage &&
                        !offlineFlowLocationsPercentage &
                          !actionAlertFlowLocationsPercentage,
                    })}
                    style={{ width: `${infoAlertFlowLocationsPercentage}%` }}
                  />
                </Tooltip>
              </div>
            </div>
          )}

          {totalLeakPods > 0 && (
            <div className="flex items-center mb2">
              <div className="w-20 b">Leak Pucks</div>
              <div className="flex ba b--moon-gray br2 w-80">
                <Tooltip title={`${activeLeakPods} Leak Pucks Healthy`}>
                  <div
                    className={cx('h1 bg-green', {
                      'br1 br--left':
                        !!offlineLeakPodsPercentage ||
                        !!infoAlertLeakPodsPercentage ||
                        !!actionAlertLeakPodsPercentage,
                      br1:
                        !offlineLeakPodsPercentage &&
                        !infoAlertLeakPodsPercentage &&
                        !actionAlertLeakPodsPercentage,
                    })}
                    style={{ width: `${onlineLeakPodsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip title={`${activeSystemLeakAlerts} Leak Pucks Offline`}>
                  <div
                    className={cx('h1 bg-orange', {
                      'br1 br--left': !onlineLeakPodsPercentage,
                      'br1 br--right':
                        !infoAlertLeakPodsPercentage &
                        !actionAlertLeakPodsPercentage,
                      br1:
                        !onlineLeakPodsPercentage &&
                        !infoAlertLeakPodsPercentage &&
                        !actionAlertLeakPodsPercentage,
                    })}
                    style={{ width: `${offlineLeakPodsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeActionableLeakAlerts} Actionable Alerts`}
                >
                  <div
                    className={cx('h1 bg-dark-red', {
                      'br1 br--left':
                        !onlineLeakPodsPercentage & !offlineLeakPodsPercentage,
                      'br1 br--right': !infoAlertLeakPodsPercentage,
                      br1:
                        !onlineLeakPodsPercentage &&
                        !infoAlertLeakPodsPercentage &&
                        !offlineLeakPodsPercentage,
                    })}
                    style={{ width: `${actionAlertLeakPodsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeInformationalLeakAlerts} Informational Alerts`}
                >
                  <div
                    className={cx('h1 bg-gold', {
                      'br1 br--right':
                        !!onlineLeakPodsPercentage ||
                        !!offlineLeakPodsPercentage ||
                        !!actionAlertLeakPodsPercentage,
                      br1:
                        !onlineLeakPodsPercentage &&
                        !offlineLeakPodsPercentage &
                          !actionAlertLeakPodsPercentage,
                    })}
                    style={{ width: `${infoAlertLeakPodsPercentage}%` }}
                  />
                </Tooltip>
              </div>
            </div>
          )}

          {totalEvacPods > 0 && (
            <div className="flex items-center mb2">
              <div className="w-20 b">Evac Pucks</div>
              <div className="flex ba b--moon-gray br2 w-80">
                <Tooltip title={`${activeEvacPods} Evac Pucks Healthy`}>
                  <div
                    className={cx('h1 bg-green', {
                      'br1 br--left':
                        !!offlineEvacPodsPercentage ||
                        !!infoAlertEvacPodsPercentage ||
                        !!actionAlertEvacPodsPercentage,
                      br1:
                        !offlineEvacPodsPercentage &&
                        !infoAlertEvacPodsPercentage &&
                        !actionAlertEvacPodsPercentage,
                    })}
                    style={{ width: `${onlineEvacPodsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip title={`${activeSystemEvacAlerts} Evac Pucks Offline`}>
                  <div
                    className={cx('h1 bg-orange', {
                      'br1 br--left': !onlineEvacPodsPercentage,
                      'br1 br--right':
                        !infoAlertEvacPodsPercentage &
                        !actionAlertEvacPodsPercentage,
                      br1:
                        !onlineEvacPodsPercentage &&
                        !infoAlertEvacPodsPercentage &&
                        !actionAlertEvacPodsPercentage,
                    })}
                    style={{ width: `${offlineEvacPodsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeActionableEvacAlerts} Actionable Alerts`}
                >
                  <div
                    className={cx('h1 bg-dark-red', {
                      'br1 br--left':
                        !onlineEvacPodsPercentage & !offlineEvacPodsPercentage,
                      'br1 br--right': !infoAlertEvacPodsPercentage,
                      br1:
                        !onlineEvacPodsPercentage &&
                        !infoAlertEvacPodsPercentage &&
                        !offlineEvacPodsPercentage,
                    })}
                    style={{ width: `${actionAlertEvacPodsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeInformationalEvacAlerts} Informational Alerts`}
                >
                  <div
                    className={cx('h1 bg-gold', {
                      'br1 br--right':
                        !!onlineEvacPodsPercentage ||
                        !!offlineEvacPodsPercentage ||
                        !!actionAlertEvacPodsPercentage,
                      br1:
                        !onlineEvacPodsPercentage &&
                        !offlineEvacPodsPercentage &
                          !actionAlertEvacPodsPercentage,
                    })}
                    style={{ width: `${infoAlertEvacPodsPercentage}%` }}
                  />
                </Tooltip>
              </div>
            </div>
          )}

          {totalGateways > 0 && (
            <div className="flex items-center mb2">
              <div className="w-20 b">Network Gateways</div>
              <div className="flex ba b--moon-gray br2 w-80">
                <Tooltip title={`${activeGateways} Network Gateways Healthy`}>
                  <div
                    className={cx('h1 bg-green br1', {
                      'br--left': !!offlineGatewaysPercentage,
                    })}
                    style={{ width: `${onlineGatewaysPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${activeGatewayAlerts} Network Gateways Offline`}
                >
                  <div
                    className={cx('h1 bg-orange br1', {
                      'br--right': !!onlineGatewaysPercentage,
                    })}
                    style={{ width: `${offlineGatewaysPercentage}%` }}
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </div>

        <Divider />
        <div>
          {isSiteLoading ||
          (!!allActiveSites.length && !allActiveSites[0].stats) ? (
            <Spin size="large" className="w-100 center mv5" />
          ) : (
            <ActiveSites sites={allActiveSites} />
          )}
        </div>
        <Divider />
        <div>
          {isAlertLoading ? (
            <Spin size="large" className="w-100 center mv5" />
          ) : (
            <ActiveAlerts
              assetAlerts={activeAlerts}
              gatewayAlerts={gatewayAlerts}
            />
          )}
        </div>
      </section>
    )
  }
}

export default AdminDashboard
