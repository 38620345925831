import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditLocationForm from './forms/EditLocationForm'
import { H2 } from './common/Headers'
import { updateLocationAction } from '../actions/locationsActions'
import { hasPermission } from '../utils/hasPermission'
import { flagEnabled } from '../utils/config'
import { createQueryString } from '../utils/queryParams'
import { USER_PERMISSIONS, POD_TYPES } from '../constants'

const isFlowMonitorEnabled = flagEnabled('REACT_APP_ENABLE_FLOW_MONITOR')

class EditLocation extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllTags: PropTypes.func.isRequired,
    currentLocation: PropTypes.object.isRequired,
    getLocation: PropTypes.func.isRequired,
    match: PropTypes.object,
    getAllAvailablePods: PropTypes.func.isRequired,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableLeakPods: PropTypes.arrayOf(PropTypes.object),
    allAvailableEvacPods: PropTypes.arrayOf(PropTypes.object),
    getAllAvailableFlowMonitorsBySite: PropTypes.func.isRequired,
    availableFlowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    addThresholdToLocation: PropTypes.func.isRequired,
    deleteThresholdFromLocation: PropTypes.func.isRequired,
    removePodFromLocation: PropTypes.func.isRequired,
    removeFlowMonitorFromLocation: PropTypes.func.isRequired,
    accountProfile: PropTypes.object.isRequired,
    escalationPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  componentDidMount() {
    const {
      match,
      getLocation,
      getAllAvailablePods,
      getAllAvailableFlowMonitorsBySite,
      getAllTags,
    } = this.props
    const { siteSlug, floorId, id } = match.params

    getLocation(siteSlug, floorId, id)
    getAllAvailablePods(siteSlug)
    getAllAvailablePods(
      siteSlug,
      createQueryString({ type: POD_TYPES.LEAK_POD })
    )
    getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.EVAC_POD })
      )
    isFlowMonitorEnabled &&
      hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
      getAllAvailableFlowMonitorsBySite(siteSlug)

    hasPermission(USER_PERMISSIONS.VIEW_TAG) &&
      getAllTags(
        createQueryString({
          perPage: 'all',
        })
      )
  }

  componentWillReceiveProps(nextProps) {
    const {
      match,
      accountProfile,
      getAllAvailableFlowMonitorsBySite,
      getAllAvailablePods,
    } = this.props
    const { siteSlug } = match.params

    if (!accountProfile.role && nextProps.accountProfile.role) {
      if (hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD)) {
        getAllAvailablePods(siteSlug)
        getAllAvailablePods(
          siteSlug,
          createQueryString({ type: POD_TYPES.LEAK_POD })
        )
        getAllAvailablePods(
            siteSlug,
            createQueryString({ type: POD_TYPES.EVAC_POD })
        )
      }
      isFlowMonitorEnabled &&
        hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
        getAllAvailableFlowMonitorsBySite(siteSlug)
    }
  }

  handleAddThresholdToLocation = val => {
    const { siteSlug, floorId, id } = this.props.match.params

    this.props.addThresholdToLocation(siteSlug, floorId, id, val)
  }

  handleDeleteThresholdFromLocation = thresholdId => {
    const { siteSlug, floorId, id } = this.props.match.params

    this.props.deleteThresholdFromLocation(siteSlug, floorId, id, thresholdId)
  }

  render() {
    const {
      tags,
      currentLocation,
      match: {
        params: { siteSlug, floorId },
      },
      allAvailablePods,
      allAvailableLeakPods,
      allAvailableEvacPods,
      availableFlowMonitors,
      removePodFromLocation,
      removeFlowMonitorFromLocation,
    } = this.props

    return (
      <section className="EditLocation">
        <H2>Edit Location</H2>
        <EditLocationForm
          tags={tags}
          submitAction={updateLocationAction}
          initialValues={currentLocation}
          podPillarId={currentLocation.podPillarId}
          flowMonitorPillarId={currentLocation.flowMonitorPillarId}
          siteSlug={siteSlug}
          floorId={floorId}
          allAvailablePods={allAvailablePods}
          allAvailableLeakPods={allAvailableLeakPods}
          allAvailableEvacPods={allAvailableEvacPods}
          availableFlowMonitors={availableFlowMonitors}
          removeFlowMonitorFromLocation={removeFlowMonitorFromLocation}
          removePodFromLocation={removePodFromLocation}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditLocation
