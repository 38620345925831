import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import sortBy from 'sort-by'
import BatchUpdateAlertsModal from './BatchUpdateAlertsModal'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Badge,
  RadioButton,
  RadioGroup,
  Spin,
  Tooltip,
} from './common/Ant'
import Select, { Option } from './common/Select'
import { H3 } from './common/Headers'
import { Anchor, ActionAnchor } from './common/Anchor'
import InputContainer from '../containers/common/InputContainer'
import DateRange from './common/DateRange'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { dateTimeFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  ALERT_TYPES,
  ALERT_STATUS,
  VIEW_ALL,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  ACTION_REPORT_STATE,
  USER_PERMISSIONS,
  ROLES,
  THRESHOLD_ALERT_TYPES,
  LOCATION_TYPES,
  THRESHOLD_DATA_SOURCES,
  SYSTEM_ALERT_TYPES,
} from '../constants'
import { configurePagination, escapeRegExp } from '../utils/helpers'

class AllPodAlerts extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.alerts,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      assetType: LOCATION_TYPES.POD,
      status: undefined,
      type: undefined,
      thresholdAlertType: undefined,
      siteSlug: undefined,
      floorId: undefined,
      thresholdSlug: undefined,
      alertSource: undefined,
      hasComments: undefined,
      to: undefined,
      from: undefined,
      isModalVisible: false,
      selectedAlerts: [],
    }
  }

  static propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.object).isRequired,
    getLocationAlertHistory: PropTypes.func,
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    locationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    locationFloorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    locationSiteSlug: PropTypes.string,
    hideAlert: PropTypes.func,
    unhideAlert: PropTypes.func,
    closeAlert: PropTypes.func,
    isLoading: PropTypes.bool.isRequired,
    meta: PropTypes.object,
    getAllAlerts: PropTypes.func,
    isRenderingDashboardView: PropTypes.bool,
    isLocationAlertHistory: PropTypes.bool,
    getAllSites: PropTypes.func,
    getAllThresholds: PropTypes.func,
    updateQueryParams: PropTypes.func,
    sites: PropTypes.arrayOf(PropTypes.object),
    thresholds: PropTypes.arrayOf(PropTypes.object),
    sectionTitle: PropTypes.string,
    generateAlertExport: PropTypes.func,
    batchUpdateAlerts: PropTypes.func,
    getAllTags: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.object),
  }

  componentWillReceiveProps() {
    this.handleSearch()
  }

  componentDidUpdate(prevProps) {
    const { getAllThresholds, accountProfile } = this.props
    if (accountProfile.role !== prevProps.accountProfile.role) {
      getAllThresholds &&
        hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) &&
        getAllThresholds(createQueryString({ perPage: 'all' }))
    }
  }

  componentDidMount() {
    const {
      getAllAlerts,
      getAllSites,
      getAllThresholds,
      getLocationAlertHistory,
      isLocationAlertHistory,
      getAllTags,
    } = this.props
    const {
      page,
      perPage,
      siteSlug,
      floorId,
      thresholdSlug,
      alertSource,
      type,
      thresholdAlertType,
      assetType,
      status,
      hasComments,
      to,
      from,
    } = parseQueryParams(global.location)

    !!getAllTags &&
      hasPermission(USER_PERMISSIONS.VIEW_TAG) &&
      getAllTags(
        createQueryString({
          perPage: 'all',
        })
      )

    let query
    if (isLocationAlertHistory) {
      const { locationSiteSlug, locationId, locationFloorId } = this.props
      query = {
        page: page ? parseInt(page, 10) : DEFAULT_PAGE,
        perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      }
      this.setState(query)
      getLocationAlertHistory &&
        getLocationAlertHistory(
          locationSiteSlug,
          locationFloorId,
          locationId,
          createQueryString(query)
        )
    } else {
      query = {
        page: page ? parseInt(page, 10) : DEFAULT_PAGE,
        perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
        siteSlug,
        floorId,
        thresholdSlug,
        alertSource,
        type,
        thresholdAlertType,
        assetType: assetType ? assetType : LOCATION_TYPES.POD,
        status,
        hasComments,
      }

      this.setState(query)

      if (from && to) {
        this.setState({
          from: moment(from * 1000),
          to: moment(to * 1000),
        })

        query = Object.assign(query, {
          from,
          to,
        })
      }

      const allResourcesQuery = createQueryString({ perPage: 'all' })

      getAllAlerts && getAllAlerts(createQueryString(query))
      getAllSites && getAllSites(allResourcesQuery)
      getAllThresholds &&
        hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) &&
        getAllThresholds(allResourcesQuery)
    }
  }

  handleChange = (pagination, filters, sorter) => {
    const {
      getAllAlerts,
      updateQueryParams,
      isRenderingDashboardView,
      isLocationAlertHistory,
      getLocationAlertHistory,
      locationSiteSlug,
      locationFloorId,
      locationId,
    } = this.props
    const {
      type,
      thresholdAlertType,
      assetType,
      status,
      siteSlug,
      floorId,
      thresholdSlug,
      alertSource,
      hasComments,
      page,
      perPage,
      to,
      from,
    } = this.state

    if (!isRenderingDashboardView && page !== pagination.current) {
      let query, qs

      if (isLocationAlertHistory) {
        query = {
          page: pagination.current,
          perPage,
        }
        qs = createQueryString(query)
        getLocationAlertHistory(
          locationSiteSlug,
          locationFloorId,
          locationId,
          qs
        )
      } else {
        query = {
          page: pagination.current,
          perPage,
          type,
          thresholdAlertType,
          assetType,
          status,
          siteSlug,
          floorId,
          thresholdSlug,
          alertSource,
          hasComments,
          to: to ? to.unix() : undefined,
          from: from ? from.unix() : undefined,
        }
        qs = createQueryString(query)
        getAllAlerts(qs)
      }

      updateQueryParams &&
        updateQueryParams({
          search: qs,
        })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
      searchText: '',
    })
  }

  handleFilterChange = keyName => value => {
    const {
      getAllAlerts,
      updateQueryParams,
      isLocationAlertHistory,
      getLocationAlertHistory,
      locationSiteSlug,
      locationFloorId,
      locationId,
    } = this.props

    const {
      page,
      perPage,
      siteSlug,
      floorId,
      thresholdSlug,
      alertSource,
      type,
      thresholdAlertType,
      assetType,
      status,
      hasComments,
      to,
      from,
    } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    this.setState({ [keyName]: newValue })

    let query, qs

    if (isLocationAlertHistory) {
      query = Object.assign(
        {
          page,
          perPage,
        },
        { [keyName]: newValue }
      )
      qs = createQueryString(query)
      getLocationAlertHistory(locationSiteSlug, locationFloorId, locationId, qs)
    } else {
      query = Object.assign(
        {
          siteSlug,
          floorId,
          thresholdSlug,
          alertSource,
          hasComments,
          type,
          thresholdAlertType,
          assetType,
          status,
          page,
          perPage,
          to: to ? to.unix() : undefined,
          from: from ? from.unix() : undefined,
        },
        { [keyName]: newValue }
      )
      // if we expect the number of results to change, we need to reset to the
      // first page so API does not return a 404
      if (keyName !== 'page' || keyName !== 'perPage') {
        query.page = DEFAULT_PAGE
        this.setState({ page: DEFAULT_PAGE })
      }

      qs = createQueryString(query)
      getAllAlerts(qs)
    }

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })
  }

  handleDateRangeChange = (from, to) => {
    const { getAllAlerts, updateQueryParams } = this.props
    const {
      perPage,
      siteSlug,
      floorId,
      thresholdSlug,
      alertSource,
      type,
      thresholdAlertType,
      assetType,
      status,
      hasComments,
    } = this.state

    const query = Object.assign(
      {
        siteSlug,
        floorId,
        thresholdSlug,
        alertSource,
        hasComments,
        type,
        thresholdAlertType,
        assetType,
        status,
        perPage,
      },
      {
        from: from ? from.unix() : undefined,
        to: to ? to.unix() : undefined,
        page: DEFAULT_PAGE,
      }
    )

    this.setState({
      page: DEFAULT_PAGE,
      from,
      to,
    })

    const qs = createQueryString(query)

    updateQueryParams &&
      updateQueryParams({
        search: qs,
      })

    getAllAlerts(qs)
  }

  handleInputChange = e => {
    this.setState({ searchText: e.currentTarget.value }, () =>
      this.handleSearch()
    )
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.alerts
        .map(record => {
          const match =
            (record.siteName && record.siteName.match(regex)) ||
            (record.locationName && record.locationName.match(regex)) ||
            (record.thresholdName && record.thresholdName.match(regex))

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  handleAlertExport = () => {
    const {
      siteSlug,
      floorId,
      thresholdSlug,
      alertSource,
      type,
      thresholdAlertType,
      assetType,
      status,
      hasComments,
      to,
      from,
    } = parseQueryParams(global.location)

    const query = {
      siteSlug,
      floorId,
      thresholdSlug,
      alertSource,
      type,
      thresholdAlertType,
      assetType,
      status,
      hasComments,
      to,
      from,
    }

    this.props.generateAlertExport(createQueryString(query))
  }

  handleRowSelectionChange = (selectedAlertIds, selectedAlerts) =>
    this.setState({ selectedAlerts })

  handleModalOpen = () => this.setState({ isModalVisible: true })
  handleModalCancel = () => this.setState({ isModalVisible: false })

  renderActionReportState = state => {
    switch (state) {
      case ACTION_REPORT_STATE.INCOMPLETE:
        return (
          <Tooltip title="Action Report Incomplete">
            <Icon type="bars" className="red" />
          </Tooltip>
        )
      case ACTION_REPORT_STATE.PARTIALLY_COMPLETE:
        return (
          <Tooltip title="Action Report Partially Complete">
            <Icon type="bars" className="yellow" />
          </Tooltip>
        )
      case ACTION_REPORT_STATE.COMPLETE:
        return (
          <Tooltip title="Action Report Complete">
            <Icon type="bars" className="green" />
          </Tooltip>
        )
      default:
        return null
    }
  }

  render() {
    const {
      hideAlert,
      unhideAlert,
      closeAlert,
      meta,
      windowWidth,
      isRenderingDashboardView,
      isLoading,
      isLocationAlertHistory,
      sites,
      thresholds,
      sectionTitle,
      batchUpdateAlerts,
      tags,
    } = this.props
    const {
      status,
      assetType,
      type,
      thresholdAlertType,
      data,
      sortedInfo,
      siteSlug,
      thresholdSlug,
      alertSource,
      hasComments,
      searchText,
      perPage,
      to,
      from,
      isModalVisible,
      selectedAlerts,
    } = this.state
    const isFixedColumn = windowWidth < 1224

    const isFiltering = !!(
      (status && status.length) ||
      (type && type.length) ||
      (thresholdAlertType && thresholdAlertType.length) ||
      (siteSlug && siteSlug.length) ||
      (thresholdSlug && thresholdSlug.length) ||
      (alertSource && alertSource.length) ||
      hasComments ||
      !!to ||
      !!from
    )

    return (
      <section className="mb3 AllPodAlerts">
        {!isLocationAlertHistory && isRenderingDashboardView && (
          <H3>{sectionTitle || 'Smart Pod Alerts'}</H3>
        )}
        {!isRenderingDashboardView && (
          <Fragment>
            {!isLocationAlertHistory && (
              <div>
                <div className="flex-l items-center-l">
                  <RadioGroup
                    onChange={({ target: { value } }) =>
                      this.handleFilterChange('assetType')(value)
                    }
                    value={assetType}
                    className="flex-l mb3 mr3"
                    size="small"
                  >
                    <RadioButton value={LOCATION_TYPES.POD}>Smart</RadioButton>
                    <RadioButton value={LOCATION_TYPES.LEAK_POD}>
                      Leak
                    </RadioButton>
                    <RadioButton value={LOCATION_TYPES.EVAC_POD}>
                      Evac
                    </RadioButton>
                  </RadioGroup>
                  <RadioGroup
                    onChange={({ target: { value } }) =>
                      this.handleFilterChange('status')(value)
                    }
                    value={status}
                    className="flex-l mb3 mr3"
                    size="small"
                  >
                    <RadioButton value={undefined}>All</RadioButton>
                    <RadioButton value={ALERT_STATUS.ACTIVE}>Open</RadioButton>
                    <RadioButton value={ALERT_STATUS.INACTIVE}>
                      Closed
                    </RadioButton>
                  </RadioGroup>
                  <RadioGroup
                    onChange={({ target: { value } }) =>
                      this.handleFilterChange('type')(value)
                    }
                    value={type}
                    className="flex-l mb3 mr3"
                    size="small"
                  >
                    <RadioButton value={undefined}>All</RadioButton>
                    <RadioButton value={ALERT_TYPES.THRESHOLD}>
                      Threshold
                    </RadioButton>
                    <RadioButton value={ALERT_TYPES.SYSTEM}>System</RadioButton>
                  </RadioGroup>

                  <RadioGroup
                    onChange={({ target: { value } }) =>
                      this.handleFilterChange('thresholdAlertType')(value)
                    }
                    value={thresholdAlertType}
                    className="flex-l mb3 mr3"
                    size="small"
                  >
                    <RadioButton value={undefined}>All</RadioButton>
                    <RadioButton value={THRESHOLD_ALERT_TYPES.ACTIONABLE}>
                      Actionable
                    </RadioButton>
                    <RadioButton value={THRESHOLD_ALERT_TYPES.INFORMATIONAL}>
                      Informational
                    </RadioButton>
                  </RadioGroup>
                  {hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) && (
                    <RadioGroup
                      onChange={({ target: { value } }) =>
                        this.handleFilterChange('hasComments')(value)
                      }
                      value={hasComments}
                      className="flex-l mb3 mr3-l"
                      size="small"
                    >
                      <RadioButton value={undefined}>All</RadioButton>
                      <RadioButton value="t">Commented</RadioButton>
                    </RadioGroup>
                  )}
                  {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                    <div className="mb3 mr3-l">
                      <ActionAnchor onClick={this.handleAlertExport}>
                        Export Smart Pod Alerts Data
                      </ActionAnchor>
                    </div>
                  )}

                  {selectedAlerts.length > 0 && (
                    <div className="mb3">
                      <ActionAnchor onClick={this.handleModalOpen}>
                        Bulk Update ({selectedAlerts.length.toString()} Alerts)
                      </ActionAnchor>
                    </div>
                  )}
                </div>
                <div className="flex-l items-center-l">
                  {hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) && (
                    <Select
                      className="w-third-l mr3-l"
                      placeholder="Threshold"
                      mode="multiple"
                      input={{
                        value: thresholdSlug,
                        onChange: this.handleFilterChange('thresholdSlug'),
                      }}
                      filterable
                    >
                      {[{ slug: 'all', name: VIEW_ALL }, ...thresholds].map(
                        x => (
                          <Option value={x.slug} key={x.slug}>
                            {x.name}
                          </Option>
                        )
                      )}
                    </Select>
                  )}
                  {hasPermission(USER_PERMISSIONS.VIEW_SITE) && (
                    <Select
                      className={
                        hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD)
                          ? 'w-third-l mr3-l'
                          : 'w-70-l mr3-l'
                      }
                      placeholder="Site"
                      mode="multiple"
                      input={{
                        value: siteSlug,
                        onChange: this.handleFilterChange('siteSlug'),
                      }}
                      filterable
                    >
                      {[{ slug: 'all', name: VIEW_ALL }, ...sites].map(x => (
                        <Option value={x.slug} key={x.slug}>
                          {x.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                  {hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) && (
                    <Select
                      className="w-third-l"
                      placeholder="Alert Source"
                      mode="multiple"
                      input={{
                        value: alertSource,
                        onChange: this.handleFilterChange('alertSource'),
                      }}
                      filterable
                    >
                      {[
                        { slug: 'all', name: VIEW_ALL },
                        ...Object.keys(THRESHOLD_DATA_SOURCES).map(x => ({
                          slug: x,
                          name: toTitleCase(x),
                        })),
                      ].map(x => (
                        <Option
                          value={THRESHOLD_DATA_SOURCES[x.slug]}
                          key={x.slug}
                        >
                          {x.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </div>
                <div>
                  <div className="w-100">
                    <DateRange
                      startValue={from}
                      className="flex"
                      endValue={to}
                      onClose={this.handleDateRangeChange}
                      hideTime
                      limitAfterToday
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="table-filter">
              <InputContainer
                input={{
                  placeholder: 'Filter',
                  value: searchText,
                  onChange: this.handleInputChange,
                }}
              />
            </div>
            <div className="dib red">
              {isFiltering && (
                <p>Found {meta.total} alerts matching your criteria</p>
              )}
            </div>
          </Fragment>
        )}
        {isLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowSelection={
              batchUpdateAlerts
                ? { onChange: this.handleRowSelectionChange }
                : null
            }
            rowKey="id"
            scroll={{
              x: hasPermission(USER_PERMISSIONS.EDIT_ALERT) ? 950 : 850,
            }}
            bordered
            tableLayout="auto"
            pagination={
              !isRenderingDashboardView &&
              configurePagination({
                perPage,
                onShowSizeChange: (page, perPage) =>
                  this.handleFilterChange('perPage')(perPage),
                ...meta,
              })
            }
          >
            <Column
              title="Trigger"
              dataIndex="thresholdName"
              sorter={sortBy('thresholdName')}
              sortOrder={
                sortedInfo.field === 'thresholdName' && sortedInfo.order
              }
              width={200}
              render={(text, record) => {
                const columnClassNames = cx('flex items-center', {
                  'o-30 i': record.hidden,
                })
                const anchor = (
                  <Anchor to={`/alerts/pods/${record.slug}`} className="pl2">
                    {text
                      ? text
                      : record.systemAlertType === SYSTEM_ALERT_TYPES.ANOMALY
                      ? 'Anomaly'
                      : record.systemAlertType === SYSTEM_ALERT_TYPES.WET_POD
                      ? 'Wet Smart Pod'
                      : 'Smart Pod Offline'}
                  </Anchor>
                )

                const actionReportState = this.renderActionReportState(
                  record.actionReportState
                )

                return !record.endTime ? (
                  <span className={columnClassNames}>
                    <Badge status="error" /> {actionReportState}{' '}
                    <span className="b">{anchor}</span>
                  </span>
                ) : (
                  <span className={columnClassNames}>
                    <Badge status="default" /> {actionReportState} {anchor}
                  </span>
                )
              }}
            />
            <Column
              title="Type"
              dataIndex={['threshold', 'thresholdAlertType']}
              sorter={sortBy('threshold.thresholdAlertType')}
              sortOrder={
                sortedInfo.field === 'threshold.thresholdAlertType' &&
                sortedInfo.order
              }
              width={100}
              render={(text, record) => (
                <div className="flex justify-center">
                  {record.threshold.thresholdAlertType ===
                  THRESHOLD_ALERT_TYPES.ACTIONABLE ? (
                    <Tooltip title="Actionable Alerts indicate suboptimal conditions on a Site and require immediate remedial action.">
                      <span className="br2 bg-dark-red white t-shadow-1 pa1 f7">
                        Actionable
                      </span>
                    </Tooltip>
                  ) : record.threshold.thresholdAlertType ===
                    THRESHOLD_ALERT_TYPES.INFORMATIONAL ? (
                    <Tooltip title="Informational Alerts provide insights into current conditions on a Site but do not require any immediate action.">
                      <span className="br2 bg-gold white t-shadow-1 pa1 f7">
                        Informational
                      </span>
                    </Tooltip>
                  ) : (
                    '--'
                  )}
                </div>
              )}
            />
            <Column
              title="Start"
              dataIndex="startTime"
              sorter={sortBy('startTime')}
              sortOrder={sortedInfo.field === 'startTime' && sortedInfo.order}
              width={200}
              render={text => (text ? dateTimeFormatter(text) : '--')}
            />
            <Column
              title="End"
              dataIndex="endTime"
              sorter={sortBy('endTime')}
              sortOrder={sortedInfo.field === 'endTime' && sortedInfo.order}
              width={200}
              render={text => (text ? dateTimeFormatter(text) : '--')}
            />
            {!isLocationAlertHistory && (
              <Column
                title="Site"
                dataIndex="siteName"
                sorter={sortBy('siteName')}
                sortOrder={sortedInfo.field === 'siteName' && sortedInfo.order}
                width={175}
                render={(text, record) => (
                  <Anchor to={`/sites/${record.siteSlug}`}>{text}</Anchor>
                )}
              />
            )}
            {!isLocationAlertHistory && (
              <Column
                title="Location"
                dataIndex="locationName"
                sorter={sortBy('locationName')}
                sortOrder={
                  sortedInfo.field === 'locationName' && sortedInfo.order
                }
                width={200}
                render={(text, record) => (
                  <Anchor
                    to={`/sites/${record.siteSlug}/floors/${record.floorId}/locations/${record.locationId}`}
                  >
                    {text}
                  </Anchor>
                )}
              />
            )}
            {!isLocationAlertHistory && (
              <Column
                title="Smart Pod"
                dataIndex="podPillarId"
                sorter={sortBy('podPillarId')}
                sortOrder={
                  sortedInfo.field === 'podPillarId' && sortedInfo.order
                }
                width={140}
                render={text => text || '--'}
              />
            )}
            {hasPermission(USER_PERMISSIONS.EDIT_ALERT) &&
              !isLocationAlertHistory && (
                <Column
                  fixed={isFixedColumn ? 'right' : false}
                  width={100}
                  render={(text, record) => {
                    const menu = (
                      <Menu>
                        <Menu.Item key="hide-alert">
                          {record.hidden ? (
                            <ActionAnchor
                              onClick={() => unhideAlert(record.id)}
                            >
                              Unhide
                            </ActionAnchor>
                          ) : (
                            <ActionAnchor onClick={() => hideAlert(record.id)}>
                              Hide
                            </ActionAnchor>
                          )}
                        </Menu.Item>
                        {record.alertActive && (
                          <Menu.Item key="close-alert">
                            <ActionAnchor onClick={() => closeAlert(record.id)}>
                              Close
                            </ActionAnchor>
                          </Menu.Item>
                        )}
                      </Menu>
                    )

                    return (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  }}
                />
              )}
          </Table>
        )}
        {!!batchUpdateAlerts && (
          <BatchUpdateAlertsModal
            selectedAlerts={selectedAlerts}
            tags={tags}
            isModalVisible={isModalVisible}
            handleModalCancel={this.handleModalCancel}
            batchUpdateAlerts={batchUpdateAlerts}
          />
        )}
      </section>
    )
  }
}

export default AllPodAlerts
