import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import cx from 'classnames'
import { CRS, icon, divIcon } from 'leaflet'
import { Map, ImageOverlay, Marker, ZoomControl } from 'react-leaflet'
import HeatmapLayer from 'react-leaflet-heatmap-layer'
import FloorSummary from './FloorSummary'
import FloorPlanSidebar from './FloorPlanSidebar'
import FloorPlanUpload from './FloorPlanUpload'
import EditLocationForm from './forms/EditLocationForm'
import { H3 } from './common/Headers'
import Checkbox from './common/Checkbox'
import { Icon, Modal, Spin, Collapse, Panel } from './common/Ant'
import Label from './common/Label'
import { ActionAnchor } from './common/Anchor'
import Select, { Option } from './common/Select'
import {
  createMapLocationAction,
  createGatewayMapLocationAction,
} from '../actions/locationsActions'
import { toTitleCase } from '../utils/textFormatters'
import { hasPermission, hasRole } from '../utils/hasPermission'
import {
  ALERT_TYPES,
  COLORS,
  SMART_SENSOR_TYPES,
  VISIBLE_READINGS,
  ZOOM_LEVELS,
  USER_PERMISSIONS,
  ROLES,
  DEFAULT_PLAN_ZOOM_LEVEL,
  THRESHOLD_ALERT_TYPES,
  ALL,
  VIEW_ALL,
  POD_TYPES,
} from '../constants'
import { createQueryString } from '../utils/queryParams'
import { flagEnabled } from '../utils/config'

import podAlert from '../assets/floor-plan/pod-action-alert.svg'
import podInfoAlert from '../assets/floor-plan/pod-info-alert.svg'
import podUnassigned from '../assets/floor-plan/pod-unassigned.svg'
import podOffline from '../assets/floor-plan/pod-offline.svg'
import podOnline from '../assets/floor-plan/pod-online.svg'

import flowOnline from '../assets/floor-plan/flow-monitor-online.svg'
import flowOffline from '../assets/floor-plan/flow-monitor-offline.svg'
import flowAlert from '../assets/floor-plan/flow-monitor-action-alert.svg'
import flowInfoAlert from '../assets/floor-plan/flow-monitor-info-alert.svg'

import leakPodOnline from '../assets/floor-plan/leak-pod.svg'
import leakPodOffline from '../assets/floor-plan/leak-pod-offline.svg'
import leakPodInfoAlert from '../assets/floor-plan/leak-pod-info-alert.svg'
import leakPodAlert from '../assets/floor-plan/leak-pod-action-alert.svg'

import evacPodOnline from '../assets/floor-plan/pod-online.svg'
import evacPodOffline from '../assets/floor-plan/pod-offline.svg'
import evacPodInfoAlert from '../assets/floor-plan/pod-info-alert.svg'
import evacPodAlert from '../assets/floor-plan/pod-action-alert.svg'

import gatewayOnline from '../assets/floor-plan/gateway-online.svg'
import gatewayOffline from '../assets/floor-plan/gateway-offline.svg'

const isFlowMonitorEnabled = flagEnabled('REACT_APP_ENABLE_FLOW_MONITOR')

const gradientDefault = {
  0.0: COLORS.DARK_BLUE,
  0.25: COLORS.BLUE,
  0.5: COLORS.YELLOW,
  0.7: COLORS.ORANGE,
  0.85: COLORS.LIGHT_RED,
  '1.0': COLORS.RED,
}

const gradientInverted = {
  0.0: COLORS.ORANGE,
  0.25: COLORS.YELLOW,
  0.5: COLORS.LIGHT_BLUE,
  0.7: COLORS.BLUE,
  0.85: COLORS.DARK_BLUE,
  '1.0': COLORS.NAVY,
}

const gradientDust = {
  0.0: COLORS.GREEN,
  0.25: COLORS.DARK_GREEN,
  0.5: COLORS.YELLOW,
  0.7: COLORS.ORANGE,
  0.85: COLORS.RED,
  '1.0': COLORS.DARK_RED,
}

const gradientSignalRssi = {
  0.0: COLORS.RED,
  0.3: COLORS.DARK_RED,
  0.5: COLORS.ORANGE,
  0.7: COLORS.YELLOW,
  0.85: COLORS.GREEN,
  '1.0': COLORS.DARK_GREEN,
}

class FloorPlan extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isEditing: false,
      isModalVisible: false,
      isHeatmapVisible: false,
      selectedHeatmapValue: SMART_SENSOR_TYPES.TEMPERATURE,
      planZoomLevel: DEFAULT_PLAN_ZOOM_LEVEL,
      radius: 35,
      blur: 8,
      intensityMax: 0.02,
      minOpacity: 0.5,
      locationType: 'pod',
      locations: this.props.locations,
      gatewayLocations: this.props.siteGateways
        ? this.props.siteGateways.filter(
            gateway =>
              gateway.coordinates && gateway.floorId === +this.props.floorId
          )
        : [],
      isGradientInverted: false,
      gradient: gradientDefault,
      isSidebarVisible: false,
      selectedItem: null,
      selectedLocationGroup: null,
    }
  }

  static propTypes = {
    getAllLocationsByFloor: PropTypes.func.isRequired,
    uploadFloorPlan: PropTypes.func,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    getAllGatewaysBySite: PropTypes.func,
    currentFloor: PropTypes.object.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    floorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    siteSlug: PropTypes.string,
    updateFloor: PropTypes.func,
    deleteMapLocation: PropTypes.func,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object),
    allAvailableLeakPods: PropTypes.arrayOf(PropTypes.object),
    allAvailableEvacPods: PropTypes.arrayOf(PropTypes.object),
    availableFlowMonitors: PropTypes.arrayOf(PropTypes.object),
    availableSiteGateways: PropTypes.arrayOf(PropTypes.object),
    siteGateways: PropTypes.arrayOf(PropTypes.object),
    setMapLocationCoordinates: PropTypes.func,
    updateMapLocationCoordinates: PropTypes.func,
    isLocationLoading: PropTypes.bool,
    isChartDataLoading: PropTypes.bool,
    getFloorSummary: PropTypes.func,
    updateGatewayMapLocation: PropTypes.func,
    deleteGatewayMapLocation: PropTypes.func,
    handleFloorPlanModalCancel: PropTypes.func,
    getAllLocationGroups: PropTypes.func,
    locationGroups: PropTypes.arrayOf(PropTypes.object),
    allowEditMode: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    isLocationLoading: false,
    handleFloorPlanModalCancel: () => undefined,
    locationGroups: [],
    allowEditMode: false,
  }

  componentWillReceiveProps(nextProps) {
    const { locations, locationGroups, siteGateways, floorId } = nextProps
    const { selectedLocationGroup } = this.state

    const gatewayLocations = siteGateways
      ? siteGateways.filter(
          gateway => gateway.coordinates && gateway.floorId === +floorId
        )
      : []

    this.setState({ gatewayLocations })

    if (!selectedLocationGroup) {
      this.setState({ locations })
    } else {
      const index = locationGroups
        .map(x => x.slug)
        .indexOf(selectedLocationGroup)

      if (index !== -1) {
        const nextLocationsSlugs = locationGroups[index].locations.map(
          x => x.slug
        )
        const nextLocations = locations.filter(
          x => nextLocationsSlugs.indexOf(x.slug) !== -1
        )
        this.setState({ locations: nextLocations })
      } else {
        this.setState({ locations })
      }
    }
  }

  componentDidMount() {
    const {
      siteSlug,
      floorId,
      getAllLocationsByFloor,
      getFloorSummary,
      getAllGatewaysBySite,
      currentFloor,
      getAllLocationGroups,
    } = this.props

    getAllLocationsByFloor(siteSlug, floorId)
    getFloorSummary && getFloorSummary(siteSlug, floorId)
    getAllGatewaysBySite && getAllGatewaysBySite(siteSlug)
    getAllLocationGroups &&
      getAllLocationGroups({
        siteSlug,
        qs: createQueryString({ perPage: ALL }),
      })

    this.setState({
      planZoomLevel: currentFloor.planZoomLevel
        ? currentFloor.planZoomLevel
        : DEFAULT_PLAN_ZOOM_LEVEL,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      siteSlug,
      floorId,
      getAllLocationsByFloor,
      currentFloor,
    } = this.props
    const { planZoomLevel } = this.state

    if (prevProps.floorId !== floorId) {
      // TODO this gets called twice on load!!!
      getAllLocationsByFloor(siteSlug, floorId)
    }

    if (prevProps.currentFloor.planZoomLevel !== currentFloor.planZoomLevel) {
      this.setState({ planZoomLevel: currentFloor.planZoomLevel })
    } else if (prevState.planZoomLevel !== planZoomLevel) {
      this.setState({ planZoomLevel })
    }
  }

  handleMarkerClick = selectedItem =>
    this.setState({
      isSidebarVisible: true,
      selectedItem,
    })

  handleIntensityExtraction = data => {
    const { selectedHeatmapValue, isGradientInverted } = this.state

    if (data[selectedHeatmapValue]) {
      const raw = parseFloat(data[selectedHeatmapValue])

      switch (selectedHeatmapValue) {
        case SMART_SENSOR_TYPES.TEMPERATURE:
          if (isGradientInverted) {
            return (raw - 100) / -100
          }
          return (raw + 20) / 120
        case SMART_SENSOR_TYPES.HUMIDITY:
          if (isGradientInverted) {
            return Math.pow(105.0 - raw, 1.52) / 1500
          }
          return Math.pow(raw + 5.0, 1.52) / 1500
        case SMART_SENSOR_TYPES.PRESSURE:
          if (isGradientInverted) {
            return Math.pow(30.95 - raw, 2.1) / 1.6
          }
          return Math.pow(raw - 28.57, 3) / 3.6
        case SMART_SENSOR_TYPES.DUST:
          return Math.pow(raw + 5, 1.005) / 250
        case SMART_SENSOR_TYPES.SIGNAL_RSSI:
          return Math.pow(Math.max(raw, -190) + 190, 1.05) * 0.007
      }
    }
  }

  handleMapClick = () => this.setState({ isSidebarVisible: false })

  handleMapDblclick = ({ latlng }) => {
    const coordinates = [latlng.lat, latlng.lng]
    this.props.setMapLocationCoordinates(coordinates)
    this.setState({
      isModalVisible: true,
      locationType: 'pod',
    })
  }

  handleMapDragend = location => alert => {
    const { siteSlug, floorId } = this.props

    const { lat, lng } = alert.target.getLatLng()
    this.props.updateMapLocationCoordinates(siteSlug, floorId, {
      ...location,
      coordinates: [lat, lng],
    })
  }

  handleGatewayMapDragend = gateway => e => {
    const { floorId, gatewayId, description } = gateway
    const { lat, lng } = e.target.getLatLng()
    this.props.updateGatewayMapLocation({
      floorId,
      gatewayId,
      description,
      coordinates: [lat, lng],
    })
  }

  handleLocationTypeChange = locationType => this.setState({ locationType })

  handleLocationGroupChange = selectedLocationGroup => {
    const { locations, locationGroups } = this.props

    let nextLocations
    const index = locationGroups.map(x => x.slug).indexOf(selectedLocationGroup)

    if (index !== -1) {
      const nextLocationsSlugs = locationGroups[index].locations.map(
        x => x.slug
      )
      nextLocations = locations.filter(
        x => nextLocationsSlugs.indexOf(x.slug) !== -1
      )
    } else {
      nextLocations = locations
    }

    this.setState({ selectedLocationGroup, locations: nextLocations })
  }

  handleGradientChange = () => {
    if (this.state.isGradientInverted) {
      this.setState({ gradient: gradientInverted })
    } else {
      this.setState({ gradient: gradientDefault })
    }
  }

  handleHeatmapChange = value => {
    this.setState({ selectedHeatmapValue: value })

    switch (value) {
      case SMART_SENSOR_TYPES.DUST:
        this.setState({
          gradient: gradientDust,
          intensityMax: 0.02,
          minOpacity: 0.05,
        })
        break
      case SMART_SENSOR_TYPES.SIGNAL_RSSI:
        this.setState({
          gradient: gradientSignalRssi,
          intensityMax: 0.02,
          minOpacity: 0.5,
        })
        break
      case SMART_SENSOR_TYPES.TEMPERATURE:
        this.setState({
          intensityMax: 0.02,
          minOpacity: 0.1,
        })
        this.handleGradientChange()
        break
      case SMART_SENSOR_TYPES.HUMIDITY:
        this.setState({
          intensityMax: 0.02,
          minOpacity: 0.1,
        })
        this.handleGradientChange()
        break
      case SMART_SENSOR_TYPES.PRESSURE:
        this.setState({
          intensityMax: 0.02,
          minOpacity: 0.2,
        })
        this.handleGradientChange()
        break
    }
  }

  handleModalSubmit = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  handleModalCancel = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  handleSetZoom = (value, ev) => {
    const planZoomLevel = Number(value)

    if (!isNaN(planZoomLevel)) {
      this.setState({ planZoomLevel })
    }
  }

  handleZoomSubmit = () => {
    const { updateFloor, currentFloor } = this.props
    const { planZoomLevel } = this.state

    updateFloor({ ...currentFloor, planZoomLevel })
  }

  renderGatewayMarker = gateway => {
    const { isEditing } = this.state

    const { gatewayName, coordinates, gatewayId, online } = gateway

    let iconUrl = online ? gatewayOnline : gatewayOffline

    const prefix = cx('tc b ba b--moon-gray br1 pa1', {
      'bg-washed-green': online,
      'bg-washed-orange': !online,
    })

    const html = `
      <div class="flex items-center justify-center">
        <img src=${iconUrl} class="w2 grow-large" />
      </div>
      <div class="flex justify-center marker-label">
        <span class="${prefix}">
          ${gatewayName}
        </span>
      </div>
    `

    const gatewayIcon = hasPermission(USER_PERMISSIONS.VIEW_GATEWAY)
      ? divIcon({
          className: 'pod-icon',
          html,
          iconSize: [36, 36],
          iconAnchor: [18, 18],
          popupAnchor: [0, -18],
        })
      : icon({
          iconUrl,
          iconSize: [36, 36],
          iconAnchor: [18, 18],
          popupAnchor: [0, -18],
        })

    const draggableProps = isEditing
      ? {
          draggable: true,
          onDragend: this.handleGatewayMapDragend(gateway),
        }
      : {}

    return (
      coordinates && (
        <Marker
          position={coordinates}
          key={gatewayId}
          icon={gatewayIcon}
          onClick={() => this.handleMarkerClick(gateway)}
          {...draggableProps}
        />
      )
    )
  }

  renderMarker = location => {
    const { isEditing } = this.state

    const {
      // id,
      slug,
      name,
      coordinates,
      activeAlerts = [],
      podPillarId,
      flowMonitorPillarId,
      alertActive,
      alertType,
      locationType,
      min = false,
      max = false,
    } = location

    let iconUrl = podUnassigned
    if (flowMonitorPillarId && hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR)) {
        iconUrl = flowOnline
    } else if (podPillarId) {
        if (locationType === POD_TYPES.LEAK_POD) { iconUrl = leakPodOnline }
        else if (locationType === POD_TYPES.EVAC_POD) { iconUrl = evacPodOnline }
        else { iconUrl = podOnline }
    }

    if (alertActive) {
      if (alertType === ALERT_TYPES.THRESHOLD && activeAlerts.length > 0) {
        const actionAlerts = activeAlerts.filter(
          x => x.thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE
        )
        const { thresholdAlertType } =
          actionAlerts.length > 0
            ? actionAlerts[actionAlerts.length - 1]
            : activeAlerts[activeAlerts.length - 1]

        if (
          flowMonitorPillarId &&
          hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR)
        ) {
          if (thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE) {
            iconUrl = flowAlert
          } else {
            iconUrl = flowInfoAlert
          }
        } else if (locationType === POD_TYPES.LEAK_POD) {
          if (thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE) {
            iconUrl = leakPodAlert
          } else {
            iconUrl = leakPodInfoAlert
          }
        } else if (locationType === POD_TYPES.EVAC_POD) {
            if (thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE) {
              iconUrl = evacPodAlert
            } else {
              iconUrl = evacPodInfoAlert
            }
        } else if (thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE) {
          iconUrl = podAlert
        } else {
          iconUrl = podInfoAlert
        }
      } else {
        iconUrl =
          flowMonitorPillarId &&
          hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR)
            ? flowOffline
            : locationType === POD_TYPES.LEAK_POD
            ? leakPodOffline
            : locationType === POD_TYPES.EVAC_POD
            ? evacPodOffline
            : podOffline
      }
    }

    const prefix = cx('tc ba b--moon-gray br1 ph1 mt1', {
      b: podPillarId || flowMonitorPillarId,
      'bg-washed-green': !max && !min && podPillarId && !alertActive,
      'bg-washed-red':
        !max && !min && alertActive && alertType === ALERT_TYPES.THRESHOLD,
      'bg-washed-orange':
        !max && !min && alertActive && alertType !== ALERT_TYPES.THRESHOLD,
      'bg-near-white': !max && !min && !podPillarId,
      'bg-red': max,
      'bg-blue': min,
    })

    const html = `
      <div class="flex items-center justify-center">
        <img src=${iconUrl} class="w2 grow-large" />
      </div>
      <div class="flex justify-center marker-label">
        <span class="${prefix}">
          ${name}
        </span>
      </div>
    `

    const podIcon = divIcon({
      className: 'pod-icon',
      html,
      iconSize: [36, 36],
      iconAnchor: [18, 18],
      popupAnchor: [0, -18],
    })

    const draggableProps = isEditing
      ? {
          draggable: true,
          onDragend: this.handleMapDragend(location),
        }
      : {}

    return (
      coordinates && (
        <Marker
          position={coordinates}
          key={slug}
          icon={podIcon}
          onClick={() => this.handleMarkerClick(location)}
          {...draggableProps}
        />
      )
    )
  }

  renderZoomControls = () => {
    const { planZoomLevel, isEditing } = this.state

    return (
      isEditing &&
      hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
        <div className="ba b--light-gray bg-near-white br2 mb3 pa2">
          <div className="flex items-center">
            <Label noMargin className="w3">
              Zoom
            </Label>
            <span className="mh2 b w3">{planZoomLevel}</span>
            <input
              className="mr4"
              type="range"
              defaultValue={0}
              min={ZOOM_LEVELS.MIN}
              max={ZOOM_LEVELS.MAX}
              step={0.25}
              value={planZoomLevel}
              onChange={e => this.handleSetZoom(e.currentTarget.value, e)}
            />
            <ActionAnchor onClick={this.handleZoomSubmit}>
              Save Zoom
            </ActionAnchor>
          </div>
        </div>
      )
    )
  }

  renderLegend = () => (
    <div className="ba b--light-gray br2 bg-near-white pa1 legend absolute left-1 top-1 z-5">
      <div className="flex items-center-l flex-column flex-row-l">
        <div className="b pr2-l tc tl-l mb1 mb0-l">Legend</div>
        <div className="flex items-center pr2-ns mb1 mb0-ns">
          <img src={podOnline} width={20} height={24} alt="Online" />
          <span className="ph1">Online</span>
        </div>
        <div className="flex items-center pr2-ns mb1 mb0-ns">
          <img src={podOffline} width={20} height={24} alt="Offline" />
          <span className="ph1">Offline</span>
        </div>
        <div className="flex items-center pr2-ns mb1 mb0-ns">
          <img src={podAlert} width={20} height={24} alt="Alert" />
          <span className="ph1">Alert</span>
        </div>
        <div className="flex items-center pr2-ns mb1 mb0-ns">
          <img src={podInfoAlert} width={20} height={24} alt="Alert" />
          <span className="ph1">Info Alert</span>
        </div>
        <div className="flex items-center pr2-ns mb1 mb0-ns">
          <img src={podUnassigned} width={20} height={24} alt="Unassigned" />
          <span className="ph1">Unassigned</span>
        </div>
        {isFlowMonitorEnabled &&
          hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
            <div className="flex items-center pr2-ns mb1 mb0-ns">
              <img src={flowOnline} width={20} height={24} alt="Flow-Monitor" />
              <span className="ph1">Water Monitor</span>
            </div>
          )}
        {this.state.gatewayLocations.length > 0 && (
          <div className="flex items-center pr2-ns mb1 mb0-ns">
            <img
              src={gatewayOnline}
              width={20}
              height={24}
              alt="Network Gateway"
            />
            <span className="ph1">Network Gateway</span>
          </div>
        )}
      </div>
    </div>
  )
  handleToggleEditMode = () => {
    this.setState({ isEditing: !this.state.isEditing })
  }

  render() {
    const {
      siteSlug,
      floorId,
      allAvailablePods,
      allAvailableLeakPods,
      allAvailableEvacPods,
      availableFlowMonitors,
      availableSiteGateways,
      currentFloor,
      isLocationLoading,
      deleteMapLocation,
      deleteGatewayMapLocation,
      snapshotData,
      getLocationSnapshot,
      handleFloorPlanModalCancel,
      uploadFloorPlan,
      locationGroups,
      allowEditMode,
    } = this.props

    const {
      isEditing,
      isModalVisible,
      planZoomLevel,
      isHeatmapVisible,
      selectedHeatmapValue,
      radius,
      blur,
      intensityMax,
      minOpacity,
      locationType,
      locations,
      gatewayLocations,
      gradient,
      isSidebarVisible,
      selectedItem,
      selectedLocationGroup,
    } = this.state

    const {
      id,
      planFileName,
      summary,
      floorplanHeight = 0,
      floorplanWidth = 0,
    } = currentFloor

    const bounds = [
      [0, 0],
      [floorplanHeight, floorplanWidth],
    ]

    const maxBounds = [
      [-200, -525],
      [floorplanHeight + 200, floorplanWidth + 525],
    ]

    const isGatewayLocationTypeSelected = locationType === 'gateway'
    const isFlowLocationTypeSelected = locationType === 'flow'
    const isLeakPodTypeSelected = locationType === 'leak'
    const isEvacPodTypeSelected = locationType === 'evac'

    const locationsWithReadings = locations.filter(
      x =>
        !!x.data &&
        moment(x.data.time).isAfter(moment(new Date()).subtract(30, 'm'))
    )

    const qs = createQueryString({
      locationIds: locations.map(x => x.id).join(','),
      columns: ['temp'],
    })

    return (
      <div className="FloorPlan mb3 relative">
        {planFileName ? (
          isLocationLoading ? (
            <div className="bg-white vh-75 flex items-center">
              <Spin size="large" className="w-100 center" />
            </div>
          ) : (
            <>
              {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
                <>
                  <Label>Filter Locations by Room</Label>
                  <Select
                    className="w-100"
                    placeholder="Select a Room"
                    input={{
                      value: selectedLocationGroup,
                      onChange: this.handleLocationGroupChange,
                    }}
                    filterable
                  >
                    {[
                      { slug: VIEW_ALL, name: VIEW_ALL },
                      ...locationGroups,
                    ].map(x => (
                      <Option value={x.slug} key={x.slug}>
                        {toTitleCase(x.name)}
                      </Option>
                    ))}
                  </Select>
                </>
              )}
              <div className="relative mb3 overflow-hidden">
                {isSidebarVisible && (
                  <FloorPlanSidebar
                    selectedItem={selectedItem}
                    siteSlug={siteSlug}
                    floorId={floorId}
                    deleteMapLocation={deleteMapLocation}
                    deleteGatewayLocation={deleteGatewayMapLocation}
                    snapshotData={snapshotData}
                    getLocationSnapshot={getLocationSnapshot}
                    handleCloseSidebar={this.handleMapClick}
                    handleFloorPlanModalCancel={handleFloorPlanModalCancel}
                  />
                )}
                {this.renderLegend()}
                <Map
                  className="ba b--gray br2 br--top vh-75 black-80 sans-serif f7 lh-copy bg-moon-gray z-3"
                  crs={CRS.Simple}
                  maxBounds={maxBounds}
                  center={[floorplanHeight / 2, floorplanWidth / 2]}
                  zoom={planZoomLevel}
                  minZoom={ZOOM_LEVELS.MIN}
                  maxZoom={ZOOM_LEVELS.MAX}
                  zoomSnap={0}
                  zoomDelta={0.25}
                  wheelPxPerZoomLevel={20}
                  attributionControl={false}
                  zoomControl={false}
                  doubleClickZoom={false}
                  onClick={this.handleMapClick}
                  zoomAnimationThreshold={ZOOM_LEVELS.MIN}
                  markerZoomAnimation={false}
                  onDblclick={
                    isEditing ? this.handleMapDblclick : () => undefined
                  }
                >
                  <div className="watermark w-100 h-100" />
                  {isHeatmapVisible && locationsWithReadings.length > 0 && (
                    <HeatmapLayer
                      points={locationsWithReadings}
                      latitudeExtractor={x => x.coordinates[0]}
                      longitudeExtractor={x => x.coordinates[1]}
                      intensityExtractor={x =>
                        this.handleIntensityExtraction(x.data)
                      }
                      minOpacity={minOpacity}
                      gradient={gradient}
                      max={intensityMax}
                      radius={radius}
                      blur={blur}
                    />
                  )}
                  {locations.map(this.renderMarker)}
                  <ImageOverlay
                    url={planFileName}
                    bounds={bounds}
                    opacity={0.97}
                  />
                  {gatewayLocations &&
                    gatewayLocations.map(this.renderGatewayMarker)}
                  <ZoomControl position="topright" />
                </Map>
                <FloorSummary summary={summary} />
              </div>

              {allowEditMode &&
                (hasPermission(USER_PERMISSIONS.EDIT_FLOOR) ||
                  hasPermission(USER_PERMISSIONS.EDIT_LOCATION)) && (
                  <Collapse
                    onChange={this.handleToggleEditMode}
                    className="mb3"
                  >
                    <Panel
                      header="Floor Plan Edit Mode"
                      key="floor-plan-settings"
                    >
                      <p>
                        <span className="b">
                          Floor Plan Edit Mode is enabled!
                        </span>
                        <br />
                      </p>
                      <p>
                        Double-click to create a new Location.
                        <br />
                        Click + drag a Location to change its position.
                      </p>
                      {hasPermission(USER_PERMISSIONS.EDIT_FLOOR) && (
                        <FloorPlanUpload
                          siteSlug={siteSlug}
                          floorId={floorId}
                          uploadFloorPlan={uploadFloorPlan}
                        />
                      )}
                      {this.renderZoomControls()}
                      {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
                        <div className="ba b--yellow br2 pa2 mb3 bg-light-yellow">
                          <Checkbox
                            type="checkbox"
                            label="Enable Experimental Heatmap"
                            noMargin
                            input={{
                              checked: isHeatmapVisible,
                              onChange: () =>
                                this.setState({
                                  isHeatmapVisible: !isHeatmapVisible,
                                }),
                            }}
                          />
                          {isHeatmapVisible && (
                            <div className="mt3">
                              <div>
                                <Select
                                  className="w-50"
                                  placeholder="Visible Heatmap Value"
                                  input={{
                                    value: selectedHeatmapValue,
                                    onChange: this.handleHeatmapChange,
                                  }}
                                  filterable
                                >
                                  {VISIBLE_READINGS.map(x => (
                                    <Option
                                      value={SMART_SENSOR_TYPES[x]}
                                      key={x}
                                    >
                                      {toTitleCase(x)}
                                    </Option>
                                  ))}
                                </Select>
                              </div>
                              <div className="flex items-center">
                                <Label className="w3">Intensity</Label>
                                <span className="mh2 b w3">{intensityMax}</span>
                                <input
                                  type="range"
                                  min={0.01}
                                  max={0.05}
                                  step={0.01}
                                  value={intensityMax}
                                  onChange={e =>
                                    this.setState({
                                      intensityMax: parseFloat(
                                        e.currentTarget.value
                                      ),
                                    })
                                  }
                                />
                              </div>
                              <div className="flex items-center">
                                <Label className="w3">Radius</Label>
                                <span className="mh2 b w3">{radius}</span>
                                <input
                                  type="range"
                                  min={25}
                                  max={100}
                                  value={radius}
                                  onChange={e =>
                                    this.setState({
                                      radius: parseFloat(e.currentTarget.value),
                                    })
                                  }
                                />
                              </div>
                              <div className="flex items-center">
                                <Label className="w3">Blur</Label>
                                <span className="mh2 w3 b">{blur}</span>
                                <input
                                  type="range"
                                  min={5}
                                  max={100}
                                  value={blur}
                                  onChange={e =>
                                    this.setState({
                                      blur: parseFloat(e.currentTarget.value),
                                    })
                                  }
                                />
                              </div>
                              <div className="flex items-center">
                                <Label className="w3">Opacity</Label>
                                <span className="mh2 w3 b">{minOpacity}</span>
                                <input
                                  type="range"
                                  min={0.01}
                                  max={1}
                                  step={0.01}
                                  value={minOpacity}
                                  onChange={e =>
                                    this.setState({
                                      minOpacity: parseFloat(
                                        e.currentTarget.value
                                      ),
                                    })
                                  }
                                />
                              </div>
                              <div className="flex items-center">
                                <label>
                                  Invert Gradient
                                  <input
                                    style={{ marginLeft: 10 }}
                                    type="checkbox"
                                    disabled={
                                      selectedHeatmapValue ===
                                        SMART_SENSOR_TYPES.DUST ||
                                      selectedHeatmapValue ===
                                        SMART_SENSOR_TYPES.SIGNAL_RSSI
                                    }
                                    onChange={e => {
                                      if (e.target.checked) {
                                        this.setState(
                                          { isGradientInverted: true },
                                          () =>
                                            this.handleHeatmapChange(
                                              selectedHeatmapValue
                                            )
                                        )
                                      } else {
                                        this.setState(
                                          { isGradientInverted: false },
                                          () =>
                                            this.handleHeatmapChange(
                                              selectedHeatmapValue
                                            )
                                        )
                                      }
                                    }}
                                  />
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </Panel>
                  </Collapse>
                )}

              <Modal
                title="Add Location"
                visible={isModalVisible}
                footer={null}
                onCancel={this.handleModalCancel}
                style={{ top: '1rem' }}
                destroyOnClose
              >
                <div className="flex-ns">
                  <Select
                    className="w-100-ns"
                    label="Select Location Type"
                    defaultValue={locationType}
                    input={{
                      value: locationType,
                      onChange: this.handleLocationTypeChange,
                    }}
                  >
                    <Option
                      value="gateway"
                      disabled={
                        !(availableSiteGateways && availableSiteGateways.length)
                      }
                    >
                      Network Gateway
                    </Option>
                    <Option value="pod">Smart Pod</Option>
                    <Option
                      value="leak"
                      disabled={
                        !(allAvailableLeakPods && allAvailableLeakPods.length)
                      }
                    >
                      Leak Puck
                    </Option>
                    <Option
                      value="evac"
                      disabled={
                        !(allAvailableEvacPods && allAvailableEvacPods.length)
                      }
                    >
                      Evac Pod
                    </Option>
                    {isFlowMonitorEnabled &&
                      hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
                        <Option
                          value="flow"
                          disabled={
                            !(
                              availableFlowMonitors &&
                              availableFlowMonitors.length
                            )
                          }
                        >
                          Water Monitor
                        </Option>
                      )}
                  </Select>
                </div>
                <EditLocationForm
                  submitAction={
                    isGatewayLocationTypeSelected
                      ? createGatewayMapLocationAction
                      : createMapLocationAction
                  }
                  isGatewayLocationTypeSelected={isGatewayLocationTypeSelected}
                  isAddingNewLocation
                  isFlowLocationTypeSelected={isFlowLocationTypeSelected}
                  isLeakPodTypeSelected={isLeakPodTypeSelected}
                  isEvacPodTypeSelected={isEvacPodTypeSelected}
                  allAvailablePods={allAvailablePods}
                  allAvailableLeakPods={allAvailableLeakPods}
                  allAvailableEvacPods={allAvailableEvacPods}
                  availableFlowMonitors={availableFlowMonitors}
                  availableSiteGateways={availableSiteGateways}
                  siteSlug={siteSlug}
                  floorId={floorId}
                  handleModalSubmit={this.handleModalSubmit}
                  handleModalCancel={this.handleModalCancel}
                />
              </Modal>
              {hasRole(
                ROLES.PILLAR_CONTRACTOR,
                ROLES.PILLAR_ADMIN,
                ROLES.SUPER_ADMIN
              ) ? (
                <div className="flex justify-between">
                  <Link
                    className={cx('link', {
                      'white hover-moon-gray': !this.props.updateFloor,
                      'dark-blue': this.props.updateFloor,
                    })}
                    to={`/print/sites/${siteSlug}/floors/${id}`}
                    target="_blank"
                  >
                    <Icon type="printer" /> Print Floor Plan
                  </Link>
                  <Link
                    onClick={handleFloorPlanModalCancel}
                    className={cx('link', {
                      'white hover-moon-gray': !this.props.updateFloor,
                      'dark-blue': this.props.updateFloor,
                    })}
                    to={`/sites/${siteSlug}/compare-locations${qs}`}
                  >
                    Compare All Locations on Floor
                  </Link>
                </div>
              ) : (
                <div className="flex justify-between">
                  <Link
                    onClick={handleFloorPlanModalCancel}
                    className="link white hover-moon-gray"
                    to={`/sites/${siteSlug}/compare-locations${qs}`}
                  >
                    Compare All Locations on Floor
                  </Link>
                </div>
              )}
            </>
          )
        ) : (
          <div className="vh-75 flex items-center justify-center bg-white">
            <div>
              <H3>Floor plan does not exist!</H3>
              {allowEditMode && hasPermission(USER_PERMISSIONS.EDIT_FLOOR) && (
                <FloorPlanUpload
                  siteSlug={siteSlug}
                  floorId={floorId}
                  uploadFloorPlan={uploadFloorPlan}
                  isNewFloorPlan
                />
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default FloorPlan
