import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Divider from './common/Divider'
import Input from './common/Input'
import Select, { Option } from './common/Select'
import DateSelector from './common/DateSelector'
import { H3 } from './common/Headers'
import Form from './common/Form'
import FormError from './common/FormError'
import Button from './common/Button'
import { toTitleCase } from '../utils/textFormatters'
import { RESOURCE_TYPES } from '../constants'

const allowedResourceTypes = [
  RESOURCE_TYPES.POD,
  RESOURCE_TYPES.LEAK_POD,
  RESOURCE_TYPES.EVAC_POD,
  RESOURCE_TYPES.GATEWAY,
  RESOURCE_TYPES.FLOW_MONITOR,
]

const EditInventoryProposal = ({
  proposal,
  podMetadata,
  createInventoryProposal,
  updateInventoryProposal,
  handleModalCancel,
  getInventoryProjections,
  getInventoryProposal,
  match: { params },
}) => {
  const [notes, setNotes] = useState('')
  const [resourceType, setResourceType] = useState(RESOURCE_TYPES.POD)
  const [productVersion, setProductVersion] = useState(undefined)
  const [quantity, setQuantity] = useState(0)
  const [estimatedDate, setEstimatedDate] = useState(moment())
  const [error, setError] = useState('')

  useEffect(() => {
    if (params.slug) {
      getInventoryProposal(params.slug)
    }
  }, [getInventoryProposal, params.slug])

  useEffect(() => {
    if (proposal.slug) {
      const {
        notes,
        resourceType,
        productVersion,
        quantity,
        estimatedDate,
      } = proposal

      setNotes(notes)
      setResourceType(resourceType)
      setProductVersion(productVersion)
      setQuantity(parseInt(quantity))
      setEstimatedDate(moment(estimatedDate))
    }
  }, [proposal])

  const handleSubmit = () => {
    if (!resourceType) {
      return setError('Resource Type is required.')
    } else if (!estimatedDate) {
      return setError('Estimated Date is required.')
    } else if (!quantity) {
      return setError('Quantity is required.')
    }

    const nextProposal = {
      resourceType,
      productVersion,
      quantity,
      notes,
      estimatedDate: estimatedDate.format('YYYY-MM-DD'),
    }

    !proposal.slug
      ? createInventoryProposal(nextProposal)
      : updateInventoryProposal({
          ...nextProposal,
          slug: proposal.slug,
        })

    handleModalCancel()
    getInventoryProjections()
  }

  return (
    <Form onSubmit={handleSubmit}>
      <H3>{!proposal.slug ? 'Create' : 'Update'} Inventory Proposal</H3>
      <div className="mb3">
        <div className="flex-l items-start-l">
          <Input
            type="number"
            label="Quantity"
            className="w-25-l w-100 mr3-l"
            placeholder="Quantity"
            input={{
              value: quantity,
              onChange: setQuantity,
            }}
          />
          <Select
            className="w-100 w-75-l"
            label="Resource Type"
            placeholder="Select an resource type"
            input={{
              value: resourceType,
              onChange: setResourceType,
            }}
            filterable
          >
            {Object.keys(RESOURCE_TYPES)
              .filter(x => allowedResourceTypes.indexOf(x) !== -1)
              .map(x => (
                <Option value={RESOURCE_TYPES[x]} key={RESOURCE_TYPES[x]}>
                  {toTitleCase(x)}
                </Option>
              ))}
          </Select>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-100"
            label="Product Version"
            placeholder="Product Version"
            disabled={
              resourceType !== RESOURCE_TYPES.POD &&
              resourceType !== RESOURCE_TYPES.LEAK_POD &&
              resourceType !== RESOURCE_TYPES.EVAC_POD
            }
            input={{
              value: productVersion,
              onChange: setProductVersion,
            }}
            filterable
          >
            {podMetadata.productVersions
              .filter(x => !!x)
              .map(x => (
                <Option value={x} key={x}>
                  {x}
                </Option>
              ))}
          </Select>
        </div>
        <Divider />
        <div className="flex-l">
          <DateSelector
            className="w-50-l mr3-l"
            label="Estimated Date"
            input={{
              value: estimatedDate,
              onChange: setEstimatedDate,
            }}
            disabled={proposal.slug && moment().isSameOrAfter(estimatedDate)}
            disableTime
            allowClear={false}
            disabledDateRangeType="before"
          />
        </div>
        <Divider />
        <div className="flex-l items-center-l">
          <Input
            label="Notes"
            className="w-100"
            placeholder="Notes"
            input={{
              value: notes,
              onChange: ev => setNotes(ev.currentTarget.value),
            }}
          />
        </div>
      </div>
      <Divider />
      <div className="mb3">
        <FormError error={error} />
        <div className="flex-ns justify-between items-center-ns">
          <Button
            className="mr3-ns mb3 mb0-ns"
            text="Submit"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Form>
  )
}

EditInventoryProposal.propTypes = {
  proposal: PropTypes.object.isRequired,
  podMetadata: PropTypes.object.isRequired,
  createInventoryProposal: PropTypes.func.isRequired,
  updateInventoryProposal: PropTypes.func.isRequired,
  handleModalCancel: PropTypes.func.isRequired,
  getInventoryProjections: PropTypes.func.isRequired,
  getInventoryProposal: PropTypes.func.isRequired,
  match: PropTypes.shape({ params: PropTypes.object }).isRequired,
}

export default EditInventoryProposal
