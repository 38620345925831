import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Progress } from './common/Ant'
import { Bread, Crumb } from './common/Bread'
import UsersCSVUpload from './UsersCSVUpload'
import SiteFloorsCSVDownload from './SiteFloorsCSVDownload'
import SiteCSVDownload from './SiteCSVDownload'
import Divider from './common/Divider'
import Logo from './common/Logo'
import { Anchor } from './common/Anchor'
import PillarTag from './common/PillarTag'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { renderSiteStatus } from '../utils/textFormatters'
import {
  SITE_STATUS,
  ALERT_STATUS,
  ALERT_TYPES,
  USER_PERMISSIONS,
  ROLES,
  THRESHOLD_ALERT_TYPES,
} from '../constants'

const SiteHeader = ({
  site,
  uploadUsersCSV,
  csvUploadResults,
  getAllLocationsBySite,
  locations,
  isLocationLoading,
  match,
}) => {
  const {
    name,
    companyName,
    companyLogo,
    status,
    gateways,
    slug,
    test,
    contractStartDate,
    contractEndDate,
    stats: {
      // TODO handle flow monitors & leak PODs & Evac Pods
      smartPod: {
        systemAlertsLocationCount,
        actionableAlertsLocationCount,
        totalCount,
      },
    },
  } = site

  let percentComplete = 0

  if (contractStartDate && contractEndDate) {
    const now = moment()
    const startDate = moment(contractStartDate)
    const endDate = moment(contractEndDate)
    const totalContractDays = endDate.diff(startDate, 'days')
    if (startDate.isBefore(now) && endDate.isAfter(now)) {
      const daysCompleted = now.diff(startDate, 'days')
      percentComplete = parseInt((daysCompleted * 100) / totalContractDays)
    } else if (endDate.isBefore(now)) {
      percentComplete = 100
    }
  }

  const offlineGateways = gateways.filter(x => !x.online)

  return (
    <div className="SiteHeader">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>
              {hasRole(
                ROLES.JOBSITE_ADMIN,
                ROLES.ORGANIZATION_ADMIN,
                ROLES.PILLAR_CONTRACTOR,
                ROLES.PILLAR_ADMIN,
                ROLES.SUPER_ADMIN
              ) ? (
                <Anchor to="/sites">Sites</Anchor>
              ) : (
                'Sites'
              )}
            </Crumb>
            <Crumb>
              <div className="dib">
                <div className="flex items-center">
                  {name}
                  {test && (
                    <span className="bg-neon-lime ph2 ba b--moon-gray br2 ml2 f7 ttu">
                      Test
                    </span>
                  )}
                </div>
              </div>
            </Crumb>
          </Bread>
        </div>
        {hasPermission(USER_PERMISSIONS.EDIT_SITE) && (
          <div className="flex flex-wrap justify-center mb3">
            <span>
              <Anchor to={`${match.url}/edit`}>Edit</Anchor>
              <Divider vertical />
            </span>
            <UsersCSVUpload
              uploadUsersCSV={formData => uploadUsersCSV(site.slug, formData)}
              csvUploadResults={csvUploadResults}
            />
            <span>
              <Divider vertical />
              <Anchor to={`${match.url}/compare-locations`}>
                Compare Locations
              </Anchor>
            </span>
            <span>
              <SiteCSVDownload site={site} percentComplete={percentComplete} />
            </span>
            <span>
              <SiteFloorsCSVDownload
                siteName={name}
                siteSlug={slug}
                isLocationLoading={isLocationLoading}
                getAllLocationsBySite={getAllLocationsBySite}
                locations={locations}
              />
            </span>
          </div>
        )}
        <div className="flex justify-center">
          <Logo companyName={companyName} companyLogo={companyLogo} />
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center mb3">
        <div className="mb2">
          <PillarTag
            iconType="environment-o"
            label="Site"
            className={
              status === SITE_STATUS.ACTIVE ? 'bg-green' : 'bg-dark-blue'
            }
          >
            {renderSiteStatus(status)}
          </PillarTag>
        </div>
        <Link
          className="mb2"
          to={`/alerts/all/pods?type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&threshold_alert_type=${THRESHOLD_ALERT_TYPES.ACTIONABLE}&site_slug=${slug}`}
        >
          <PillarTag
            iconType="alert"
            label="Smart Pod Actionable Alerts"
            className={
              actionableAlertsLocationCount > 0 ? 'bg-dark-red' : 'bg-green'
            }
          >
            {actionableAlertsLocationCount}
          </PillarTag>
        </Link>
        <div className="mb2">
          <PillarTag
            iconType="compass"
            label="Smart Pod Locations"
            className={
              totalCount > 0 && totalCount - systemAlertsLocationCount <= 0
                ? 'bg-dark-red'
                : systemAlertsLocationCount > 0
                ? 'bg-orange'
                : 'bg-green'
            }
          >
            {totalCount > 0 &&
              `${totalCount - systemAlertsLocationCount} online`}
            {systemAlertsLocationCount > 0 &&
              ` | ${systemAlertsLocationCount} offline`}
          </PillarTag>
        </div>
        <div className="mb2">
          <PillarTag
            iconType="global"
            label="Network Gateways"
            className={
              gateways.length > 0 &&
              gateways.length - offlineGateways.length <= 0
                ? 'bg-dark-red'
                : offlineGateways.length > 0
                ? 'bg-orange'
                : 'bg-green'
            }
          >
            {gateways.length > 0 &&
              `${gateways.length - offlineGateways.length} online`}
            {offlineGateways.length > 0 &&
              ` | ${offlineGateways.length} offline`}
            {gateways.length <= 0 && offlineGateways.length <= 0 && '0'}
          </PillarTag>
        </div>
      </div>
      {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) &&
        contractStartDate &&
        contractEndDate && (
          <div className="mb3 w-100 w-50-ns center">
            <Progress percent={percentComplete} />
          </div>
        )}
      <Divider />
    </div>
  )
}

SiteHeader.propTypes = {
  site: PropTypes.object.isRequired,
  uploadUsersCSV: PropTypes.func,
  csvUploadResults: PropTypes.object,
  getAllLocationsBySite: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.object),
  isLocationLoading: PropTypes.bool,
  match: PropTypes.object,
}

export default SiteHeader
