import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Divider from './common/Divider'
import Block from './common/Block'
import Logo from './common/Logo'
import { Anchor } from './common/Anchor'
import { Bread, Crumb } from './common/Bread'
import { Spin } from './common/Ant'
import {
  formatShippingVendors,
  getShippingVendorsURL,
} from '../utils/textFormatters'
import { hasPermission } from '../utils/hasPermission'
import { dateTimeFormatter } from '../utils/date'
import { USER_PERMISSIONS } from '../constants'

class ShipmentDetail extends Component {
  static propTypes = {
    site: PropTypes.object.isRequired,
    shipment: PropTypes.object.isRequired,
    isShipmentLoading: PropTypes.bool.isRequired,
    deployment: PropTypes.object.isRequired,
    getShipment: PropTypes.func.isRequired,
    getDeployment: PropTypes.func.isRequired,
    getSite: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { siteSlug, deploymentSlug, shipmentId } = this.props.match.params
    const { getDeployment, getShipment, deployment, getSite, site } = this.props

    getShipment(shipmentId)

    if (site.slug !== siteSlug) {
      getSite(siteSlug)
    }
    if (deployment.slug !== deploymentSlug) {
      getDeployment(siteSlug, deploymentSlug)
    }
  }

  render() {
    const { shipment, deployment, match, isShipmentLoading, site } = this.props

    const {
      trackingNumber,
      vendor,
      returnTrackingNumber,
      returnVendor,
      pods,
      leakPods,
      evacPods,
      anyGatewaySupply,
      gateways,
      flowMonitors,
    } = shipment

    const deploymentTitle = deployment.description
      ? deployment.description
      : dateTimeFormatter(deployment.createdAt, true)

    return isShipmentLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="ShipmentDetail">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/sites">Sites</Anchor>
              </Crumb>
              {site && site.name && (
                <Crumb>
                  <Anchor to={`/sites/${site.slug}`}>{site.name}</Anchor>
                </Crumb>
              )}
              {deploymentTitle && (
                <Crumb>
                  <Anchor
                    to={`/sites/${site.slug}/deployments/${deployment.slug}`}
                  >
                    {deploymentTitle}
                  </Anchor>
                </Crumb>
              )}
              {shipment && <Crumb>Shipment #{shipment.id}</Crumb>}
            </Bread>
          </div>
          <div className="flex justify-center mb3">
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          </div>
          <div className="flex justify-center">
            <Logo
              companyName={site.companyName}
              companyLogo={site.companyLogo}
            />
          </div>
        </div>
        <Divider />
        <div className="flex flex-wrap">
          <Block label="Tracking Number">
            {trackingNumber && vendor ? (
              <a
                className="link dark-blue"
                target="_blank"
                rel="noopener noreferrer"
                href={`${getShippingVendorsURL(vendor, trackingNumber)}`}
              >
                {trackingNumber}
              </a>
            ) : (
              trackingNumber
            )}
          </Block>
          <Block label="Vendor">{formatShippingVendors(vendor)}</Block>
          <Block label="Return Tracking Number">
            {returnTrackingNumber && returnVendor ? (
              <a
                className="link dark-blue"
                target="_blank"
                rel="noopener noreferrer"
                href={`${getShippingVendorsURL(
                  returnVendor,
                  returnTrackingNumber
                )}`}
              >
                {returnTrackingNumber}
              </a>
            ) : (
              returnTrackingNumber
            )}
          </Block>
          <Block label="Return Vendor">
            {formatShippingVendors(returnVendor)}
          </Block>
          <Block label="Smart Pods">
            {!!pods.length && (
              <ul className="pl4 mb0">
                {pods.map(pod => (
                  <li key={pod.pillarId}>
                    {hasPermission(USER_PERMISSIONS.VIEW_POD) ? (
                      <Anchor to={`/inventory/pods/${pod.pillarId}`}>
                        {pod.pillarId}
                      </Anchor>
                    ) : (
                      <span>{pod.pillarId}</span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </Block>
          <Block label="Leak Pucks">
            {!!leakPods.length && (
              <ul className="pl4 mb0">
                {leakPods.map(pod => (
                  <li key={pod.pillarId}>
                    {hasPermission(USER_PERMISSIONS.VIEW_POD) ? (
                      <Anchor to={`/inventory/pods/${pod.pillarId}`}>
                        {pod.pillarId}
                      </Anchor>
                    ) : (
                      <span>{pod.pillarId}</span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </Block>
          <Block label="Evac Pods">
            {!!evacPods.length && (
              <ul className="pl4 mb0">
                {evacPods.map(pod => (
                  <li key={pod.pillarId}>
                    {hasPermission(USER_PERMISSIONS.VIEW_POD) ? (
                      <Anchor to={`/inventory/pods/${pod.pillarId}`}>
                        {pod.pillarId}
                      </Anchor>
                    ) : (
                      <span>{pod.pillarId}</span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </Block>
          <Block label="Network Gateways">
            {!!gateways.length && (
              <ul className="pl4 mb0">
                {gateways.map(gateway => (
                  <li key={`${gateway.pillarId}`}>
                    {hasPermission(USER_PERMISSIONS.VIEW_GATEWAY) ? (
                      <Anchor to={`/inventory/gateways/${gateway.pillarId}`}>
                        {gateway.pillarId}
                      </Anchor>
                    ) : (
                      <span>{gateway.pillarId}</span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </Block>
          <Block label="Water Monitors">
            {!!flowMonitors.length && (
              <ul className="pl4 mb0">
                {flowMonitors.map(fm => (
                  <li key={`${fm.pillarId}`}>
                    {hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) ? (
                      <Anchor to={`/inventory/flow-monitors/${fm.pillarId}`}>
                        {fm.pillarId}
                      </Anchor>
                    ) : (
                      <span>{fm.pillarId}</span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </Block>
          <Block label="Any Gateway Supplies">
            {anyGatewaySupply ? 'Yes' : 'No'}
          </Block>
        </div>
      </section>
    )
  }
}

export default ShipmentDetail
