import React from 'react'
import EditShipmentForm from '../components/forms/EditShipmentForm'
import PropTypes from 'prop-types'
import { updateShipmentAction } from '../actions/deploymentsActions'
import { getStringValues } from '../reducers/shipmentsReducer'

const EditShipment = props => {
  const { shipment } = props

  return (
    <section className="Shipment">
      <EditShipmentForm
        submitAction={updateShipmentAction}
        initialValues={{
          id: shipment.id,
          trackingNumber: shipment.trackingNumber,
          vendor: shipment.vendor,
          returnTrackingNumber: shipment.returnTrackingNumber,
          returnVendor: shipment.returnVendor,
          pods: getStringValues(shipment.podsList),
          leakPods: getStringValues(shipment.leakPodsList),
          evacPods: getStringValues(shipment.evacPodsList),
          gateways: getStringValues(shipment.gatewaysList),
          flowMonitors: getStringValues(shipment.flowMonitorsList),
          anyGatewaySupply: shipment.anyGatewaySupply,
        }}
        {...props}
        enableReinitialize
      />
    </section>
  )
}

EditShipment.propTypes = {
  shipment: PropTypes.object.isRequired,
}

export default EditShipment
