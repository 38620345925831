import React, { Component } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Divider from './common/Divider'
import PODIcon from './common/PODIcon'
import { Tooltip } from './common/Ant'
import { ActionAnchor } from './common/Anchor'
import { hasPermission } from '../utils/hasPermission'
import { USER_PERMISSIONS } from '../constants'

// TODO use/update in constants.js
const POD_TYPES = {
  SMART: 'SMART',
  LEAK: 'LEAK',
  EVAC: 'EVAC',
}

const FLOW = 'FLOW'
const GATEWAY = 'GATEWAY'

const createArrayFromCount = count => [...Array(count).keys()]

class LocationBlocks extends Component {
  static propTypes = {
    floor: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      active: PropTypes.bool.isRequired,
      offlineGatewayCount: PropTypes.number,
      onlineGatewayCount: PropTypes.number,
      planFileName: PropTypes.string,
      stats: PropTypes.object,
    }).isRequired,
    borderPrefix: PropTypes.string.isRequired,
    handleModalOpen: PropTypes.func,
  }

  renderBlocks = (count, title, color, type) =>
    createArrayFromCount(count).map(x => {
      const {
        handleModalOpen,
        floor: { slug, planFileName },
      } = this.props

      const el = (
        <Tooltip title={`${count} ${title}`}>
          <div className="mv1 mr1 dim">
            {type === POD_TYPES.SMART ? (
              <PODIcon color={color} />
            ) : (type === POD_TYPES.LEAK || type === POD_TYPES.EVAC) ? (
              <div
                className={`br1 bg-${color} flex items-center justify-center`}
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              >
                <span
                  className="b white"
                  style={{
                    margin: '-1px -1px 0 0',
                  }}
                >
                  L
                </span>
              </div>
            ) : type === FLOW ? (
              <div
                className={`br1 mr1 bg-${color} rotate-45 flex items-center justify-center`}
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              >
                <span
                  className="b white rotate-315"
                  style={{
                    margin: '-1px -1px 0 0',
                  }}
                >
                  F
                </span>
              </div>
            ) : type === GATEWAY ? (
              <div
                className={`br-100 bg-${color} flex items-center justify-center`}
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              >
                <span
                  className="b white"
                  style={{
                    margin: '-1px -1px 0 0',
                  }}
                >
                  G
                </span>
              </div>
            ) : (
              <div
                className={`br1 bg-${color} flex items-center justify-center`}
                style={{
                  width: '1.25rem',
                  height: '1.25rem',
                }}
              >
                <span
                  className="b white"
                  style={{
                    margin: '-1px -1px 0 0',
                  }}
                >
                  U
                </span>
              </div>
            )}
          </div>
        </Tooltip>
      )

      return handleModalOpen && planFileName ? (
        <ActionAnchor onClick={() => handleModalOpen(slug)} key={x}>
          {el}
        </ActionAnchor>
      ) : (
        <span key={x}>{el}</span>
      )
    })

  render() {
    const {
      floor: {
        active,
        offlineGatewayCount,
        onlineGatewayCount,
        stats: {
          emptyLocations,
          leakPod,
          evacPod,
          smartPod,
          flowMonitor,
          locationCount,
        },
      },
      borderPrefix,
    } = this.props

    const hasGatewayLocations =
      onlineGatewayCount > 0 || offlineGatewayCount > 0

    return (
      <div
        className={cx(
          'LocationBlocks bg-dark-gray b--light-gray pa1',
          borderPrefix,
          {
            'i black-50': !active,
          }
        )}
      >
        {locationCount > 0 || hasGatewayLocations ? (
          <div className="flex flex-wrap items-center justify-center">
            {this.renderBlocks(
              smartPod.actionableAlertsLocationCount,
              'Smart Pod Actionable Alerts',
              'dark-red',
              POD_TYPES.SMART
            )}
            {this.renderBlocks(
              smartPod.informationalAlertsLocationCount,
              'Smart Pod Informational Alerts',
              'gold',
              POD_TYPES.SMART
            )}
            {this.renderBlocks(
              smartPod.systemAlertsLocationCount,
              'Smart Pods Offline',
              'orange',
              POD_TYPES.SMART
            )}
            {this.renderBlocks(
              smartPod.healthyLocationsCount,
              'Smart Pods Online',
              'green',
              POD_TYPES.SMART
            )}

            {/* Leak PODs */}
            {leakPod.totalCount > 0 && (
              <>
                {smartPod.totalCount > 0 ? (
                  <div style={{ marginLeft: '-0.25rem' }}>
                    <Divider vertical />
                  </div>
                ) : (
                  ''
                )}
                {this.renderBlocks(
                  leakPod.actionableAlertsLocationCount,
                  'Leak Smart Pod Actionable Alerts',
                  'dark-red',
                  POD_TYPES.LEAK
                )}
                {this.renderBlocks(
                  leakPod.informationalAlertsLocationCount,
                  'Leak Smart Pod Informational Alerts',
                  'gold',
                  POD_TYPES.LEAK
                )}
                {this.renderBlocks(
                  leakPod.systemAlertsLocationCount,
                  'Leak Pucks Offline',
                  'orange',
                  POD_TYPES.LEAK
                )}
                {this.renderBlocks(
                  leakPod.healthyLocationsCount,
                  'Leak Pucks Online',
                  'green',
                  POD_TYPES.LEAK
                )}
              </>
            )}

            {/* Evac PODs */}
            {evacPod.totalCount > 0 && (
              <>
                {(smartPod.totalCount + leakPod.totalCount) > 0 ? (
                  <div style={{ marginLeft: '-0.25rem' }}>
                    <Divider vertical />
                  </div>
                ) : (
                  ''
                )}
                {this.renderBlocks(
                  evacPod.actionableAlertsLocationCount,
                  'Evac Smart Pod Actionable Alerts',
                  'dark-red',
                  POD_TYPES.EVAC
                )}
                {this.renderBlocks(
                  evacPod.informationalAlertsLocationCount,
                  'Evac Smart Pod Informational Alerts',
                  'gold',
                  POD_TYPES.EVAC
                )}
                {this.renderBlocks(
                  evacPod.systemAlertsLocationCount,
                  'Evac Pucks Offline',
                  'orange',
                  POD_TYPES.EVAC
                )}
                {this.renderBlocks(
                  evacPod.healthyLocationsCount,
                  'Evac Pucks Online',
                  'green',
                  POD_TYPES.EVAC
                )}
              </>
            )}

            {hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
              flowMonitor.totalCount > 0 && (
                <>
                  {(smartPod.totalCount + leakPod.totalCount + evacPod.totalCount) > 0 ? (
                    <div style={{ marginLeft: '-0.25rem' }}>
                      <Divider vertical />
                    </div>
                  ) : (
                    ''
                  )}
                  {this.renderBlocks(
                    flowMonitor.actionableAlertsLocationCount,
                    'Water Monitor Actionable Alerts',
                    'dark-red',
                    FLOW
                  )}
                  {this.renderBlocks(
                    flowMonitor.informationalAlertsLocationCount,
                    'Water Monitor Informational Alerts',
                    'gold',
                    FLOW
                  )}
                  {this.renderBlocks(
                    flowMonitor.systemAlertsLocationCount,
                    'Water Monitors Offline',
                    'orange',
                    FLOW
                  )}
                  {this.renderBlocks(
                    flowMonitor.healthyLocationsCount,
                    'Water Monitors Online',
                    'green',
                    FLOW
                  )}
                </>
              )}

            {hasGatewayLocations && (
              <>
                {(smartPod.totalCount > 0 ||
                  leakPod.totalCount > 0 ||
                  evacPod.totalCount > 0 ||
                  flowMonitor.totalCount > 0) && (
                  <div style={{ marginLeft: '-0.5rem' }}>
                    <Divider vertical />
                  </div>
                )}
                {this.renderBlocks(
                  offlineGatewayCount,
                  'Network Gateways Offline',
                  'orange',
                  GATEWAY
                )}
                {this.renderBlocks(
                  onlineGatewayCount,
                  'Network Gateways Online',
                  'green',
                  GATEWAY
                )}
              </>
            )}

            {emptyLocations > 0 && (
              <>
                {(smartPod.totalCount > 0 ||
                  leakPod.totalCount > 0 ||
                  evacPod.totalCount > 0 ||
                  flowMonitor.totalCount > 0 ||
                  hasGatewayLocations) && (
                  <div style={{ marginLeft: '-0.25rem' }}>
                    <Divider vertical />
                  </div>
                )}
                {this.renderBlocks(
                  emptyLocations,
                  'Unassigned Locations',
                  'silver'
                )}
              </>
            )}
          </div>
        ) : (
          <div className="b tc white">No Locations</div>
        )}
      </div>
    )
  }
}

export default LocationBlocks
