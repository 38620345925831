import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import FloorPlan from './FloorPlan'
import AllLocations from './AllLocations'
import AllGatewayLocations from './AllGatewayLocations'
import RiMetrics from './RiMetrics'
import { Tabs, TabPane, Spin } from './common/Ant'
import Divider from './common/Divider'
import Block from './common/Block'
import FloorHeader from './FloorHeader'
import LocationsSparklines from './LocationsSparklines'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { createQueryString } from '../utils/queryParams'
import { getPathSnippets } from '../utils/helpers'
import { flagEnabled } from '../utils/config'
import { USER_PERMISSIONS, ROLES, POD_TYPES } from '../constants'

const isFlowMonitorEnabled = flagEnabled('REACT_APP_ENABLE_FLOW_MONITOR')

class Floor extends Component {
  static propTypes = {
    getFloor: PropTypes.func.isRequired,
    getAllLocationsByFloor: PropTypes.func.isRequired,
    deleteLocation: PropTypes.func.isRequired,
    setMapLocationCoordinates: PropTypes.func.isRequired,
    updateMapLocationCoordinates: PropTypes.func.isRequired,
    updateGatewayMapLocation: PropTypes.func.isRequired,
    deleteGatewayMapLocation: PropTypes.func.isRequired,
    updateFloor: PropTypes.func.isRequired,
    uploadFloorPlan: PropTypes.func.isRequired,
    deleteMapLocation: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentFloor: PropTypes.object.isRequired,
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    isFloorLoading: PropTypes.bool.isRequired,
    isLocationLoading: PropTypes.bool.isRequired,
    isGatewayLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    getAllAvailablePods: PropTypes.func.isRequired,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object),
    allAvailableLeakPods: PropTypes.arrayOf(PropTypes.object),
    allAvailableEvacPods: PropTypes.arrayOf(PropTypes.object),
    getAllAvailableFlowMonitorsBySite: PropTypes.func.isRequired,
    availableFlowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllGatewaysBySite: PropTypes.func.isRequired,
    siteGateways: PropTypes.arrayOf(PropTypes.object),
    match: PropTypes.object,
    getSite: PropTypes.func.isRequired,
    site: PropTypes.object.isRequired,
    clearVisibleLocations: PropTypes.func.isRequired,
    getFloorSummary: PropTypes.func.isRequired,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    getFloorThresholdUserMappings: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    getRiMetrics: PropTypes.func.isRequired,
    riMetrics: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllLocationGroups: PropTypes.func.isRequired,
    locationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  state = {
    activeTab: undefined,
  }

  componentDidMount() {
    const {
      getSite,
      getFloor,
      getAllAvailablePods,
      getAllAvailableFlowMonitorsBySite,
      getAllGatewaysBySite,
      getFloorThresholdUserMappings,
      match,
    } = this.props
    const { floorId, siteSlug } = match.params

    getSite && getSite(siteSlug)
    getFloor && getFloor(siteSlug, floorId)
    getAllGatewaysBySite(siteSlug)

    if (hasPermission(USER_PERMISSIONS.VIEW_SITE_INVENTORY)) {
      getAllAvailablePods(siteSlug)
      getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.LEAK_POD })
      )
      getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.EVAC_POD })
      )

      if (hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD)) {
        getFloorThresholdUserMappings(siteSlug, floorId)
      }
    }
    isFlowMonitorEnabled &&
      hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
      getAllAvailableFlowMonitorsBySite(siteSlug)

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet)
  }

  handleTabSelection = entity => {
    const { updateQueryParams, match } = this.props
    const { activeTab } = this.state

    if (entity !== match.params.floorId) {
      this.setState({ activeTab: entity })
      updateQueryParams({
        pathname: `${match.url}/${entity}`,
        search: entity === activeTab ? global.location.search : '',
      })
    } else {
      this.setState({ activeTab: 'floor-plan' })
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      accountProfile,
      getAllAvailablePods,
      getAllAvailableFlowMonitorsBySite,
      getAllGatewaysBySite,
      getFloorThresholdUserMappings,
      match,
    } = this.props
    const { siteSlug, floorId } = match.params

    if (
      !accountProfile.role &&
      nextProps.accountProfile.role &&
      hasPermission(USER_PERMISSIONS.VIEW_SITE_INVENTORY)
    ) {
      getAllAvailablePods(siteSlug)
      getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.LEAK_POD })
      )
      getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.EVAC_POD })
      )
      getAllGatewaysBySite(siteSlug)

      if (hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD)) {
        getFloorThresholdUserMappings(siteSlug, floorId)
      }
      isFlowMonitorEnabled &&
        hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
        getAllAvailableFlowMonitorsBySite(siteSlug)
    }
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentWillUnmount() {
    this.props.clearVisibleLocations()
  }

  render() {
    const {
      currentFloor,
      match,
      locations,
      getAllLocationsByFloor,
      getAllGatewaysBySite,
      deleteLocation,
      allAvailablePods,
      allAvailableLeakPods,
      allAvailableEvacPods,
      availableFlowMonitors,
      siteGateways,
      accountProfile,
      windowWidth,
      setMapLocationCoordinates,
      updateMapLocationCoordinates,
      deleteMapLocation,
      isChartDataLoading,
      isFloorLoading,
      isLocationLoading,
      isGatewayLoading,
      isHistoryLoading,
      updateFloor,
      uploadFloorPlan,
      site,
      getFloorSummary,
      snapshotData,
      getLocationSnapshot,
      updateGatewayMapLocation,
      deleteGatewayMapLocation,
      riMetrics,
      updateQueryParams,
      getRiMetrics,
      getAllLocationGroups,
      locationGroups,
    } = this.props
    const { activeTab } = this.state

    const { siteSlug, floorId } = match.params
    const { id, active, notes, floor: floorNum } = currentFloor

    const availableSiteGateways = siteGateways
      ? siteGateways.filter(({ coordinates }) => !coordinates)
      : []

    const gatewayLocations = siteGateways
      ? siteGateways.filter(
          gateway => gateway.coordinates && gateway.floorId === +floorId
        )
      : []

    // TODO refactor floor plan edit mode stuff
    // - make them look nicer
    // - use a range input for zoom
    return (
      <section className="Floor">
        {isFloorLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Switch>
            <Route
              path={`${match.url}/`}
              exact
              render={_props => <Redirect to={`${match.url}/floor-plan`} />}
            />
            <>
              <FloorHeader
                site={site}
                floor={currentFloor}
                locations={locations}
                match={match}
              />
              <Divider />
              <Tabs
                type="card"
                defaultActiveKey="floor-plan"
                activeKey={this.state.activeTab}
                onTabClick={this.handleTabSelection}
              >
                <TabPane tab="Floor Plan" key="floor-plan">
                  <Route
                    path={`${match.url}/floor-plan`}
                    exact
                    render={_props => (
                      <div>
                        {id && activeTab === 'floor-plan' && (
                          <>
                            <FloorPlan
                              getAllLocationsByFloor={getAllLocationsByFloor}
                              getAllGatewaysBySite={getAllGatewaysBySite}
                              floorId={floorId}
                              deleteLocation={deleteLocation}
                              currentFloor={currentFloor}
                              locations={locations}
                              siteSlug={siteSlug}
                              allAvailablePods={allAvailablePods}
                              allAvailableLeakPods={allAvailableLeakPods}
                              allAvailableEvacPods={allAvailableEvacPods}
                              availableFlowMonitors={availableFlowMonitors}
                              siteGateways={siteGateways}
                              availableSiteGateways={availableSiteGateways}
                              updateGatewayMapLocation={
                                updateGatewayMapLocation
                              }
                              deleteGatewayMapLocation={
                                deleteGatewayMapLocation
                              }
                              allowEditMode
                              uploadFloorPlan={uploadFloorPlan}
                              isLocationLoading={isLocationLoading}
                              isChartDataLoading={isChartDataLoading}
                              setMapLocationCoordinates={
                                setMapLocationCoordinates
                              }
                              updateMapLocationCoordinates={
                                updateMapLocationCoordinates
                              }
                              deleteMapLocation={deleteMapLocation}
                              accountProfile={accountProfile}
                              updateFloor={updateFloor}
                              getFloorSummary={getFloorSummary}
                              snapshotData={snapshotData}
                              getLocationSnapshot={getLocationSnapshot}
                              getAllLocationGroups={getAllLocationGroups}
                              locationGroups={locationGroups}
                            />
                            <LocationsSparklines />
                          </>
                        )}
                      </div>
                    )}
                  />
                </TabPane>
                <TabPane tab="Locations" key="locations">
                  <Route
                    path={`${match.url}/locations`}
                    exact
                    render={_props => (
                      <AllLocations
                        getAllLocationsByFloor={getAllLocationsByFloor}
                        locations={locations}
                        deleteLocation={deleteLocation}
                        siteSlug={siteSlug}
                        floorId={floorId}
                        isLocationLoading={isLocationLoading}
                        windowWidth={windowWidth}
                      />
                    )}
                  />
                </TabPane>
                {hasPermission(USER_PERMISSIONS.VIEW_SITE_INVENTORY) && (
                  <TabPane
                    tab="Network Gateway Locations"
                    key="gateway-locations"
                  >
                    <Route
                      path={`${match.url}/gateway-locations`}
                      exact
                      render={_props => (
                        <AllGatewayLocations
                          gatewayLocations={gatewayLocations}
                          deleteGatewayMapLocation={deleteGatewayMapLocation}
                          isGatewayLoading={isGatewayLoading}
                          windowWidth={windowWidth}
                        />
                      )}
                    />
                  </TabPane>
                )}
                <TabPane tab="Details" key="details">
                  <Route
                    path={`${match.url}/details`}
                    exact
                    render={_props => (
                      <div className="flex flex-wrap">
                        <Block label="Status">
                          {active ? (
                            <span className="b">Active</span>
                          ) : (
                            'Inactive'
                          )}
                        </Block>
                        <Block label="Floor Number">{floorNum}</Block>
                        <Block label="Notes">{notes}</Block>
                      </div>
                    )}
                  />
                </TabPane>
                {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
                  <TabPane tab="Reliability Index Metrics" key="ri-metrics">
                    <Route
                      path={`${match.url}/ri-metrics`}
                      exact
                      render={_props => (
                        <RiMetrics
                          getRiMetrics={getRiMetrics}
                          riMetrics={riMetrics}
                          siteSlug={siteSlug}
                          isLoading={isHistoryLoading}
                          floorId={floorId}
                          updateQueryParams={updateQueryParams}
                        />
                      )}
                    />
                  </TabPane>
                )}
              </Tabs>
            </>
          </Switch>
        )}
      </section>
    )
  }
}

export default Floor
