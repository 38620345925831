import produce from 'immer'
import { combineReducers } from 'redux'
import moment from 'moment'
import {
  getAllDeploymentsAction,
  getAllDeploymentsBySiteAction,
  getDeploymentAction,
  getDeploymentEditHistoryAction,
} from '../actions/deploymentsActions'
import { pickErrorMessage } from '../utils/helpers'
import { showErrorMessageAction } from '../actions/uiActions'

// TODO clear Site Deployments from reducer on componentDidUnmount
const bySlug = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllDeploymentsBySiteAction.SUCCESS:
        payload.forEach(deployment => {
          draft[deployment.slug] = deployment
        })
        break
      case getAllDeploymentsAction.SUCCESS:
        payload.items.forEach(deployment => {
          draft[deployment.slug] = deployment
        })
        break
    }
  })

const visibleIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllDeploymentsBySiteAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...payload.map(deployment => deployment.slug)
        )
        break
      case getAllDeploymentsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...payload.items.map(deployment => deployment.slug)
        )
        break
    }
  })

const counts = (
  state = {
    data: [],
    inventoryProjections: {
      podsRequired: 0,
      gatewaysRequired: 0,
      flowMonitorsRequired: 0,
    },
    activeDeploymentsCount: 0,
    transitDeploymentsCount: 0,
  },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getAllDeploymentsAction.SUCCESS:
        const data = payload.counts.inventoryProjections.data.map(x => ({
          ...x,
          time: moment(x.time)
            .add(8, 'hours')
            .valueOf(),
        }))
        Object.assign(draft, payload.counts, { data })
        break
    }
  })

// TODO stop using `current` reducer - simply select 'current' item from byId/bySlug reducers
const current = (
  state = {
    podsList: [],
    leakPodsList: [],
    evacPodsList: [],
    gatewaysList: [],
    flowMonitorsList: [],
    pods: [],
    gateways: [],
    flowMonitors: [],
    responsiblePartyUser: {},
    anyGatewaySupply: false,
    unpackedGatewaySupply: false,
  },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getDeploymentAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const meta = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllDeploymentsAction.SUCCESS:
        Object.assign(draft, payload.meta)
        break
    }
  })

const editHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getDeploymentEditHistoryAction.SUCCESS:
        const editHistory = action.payload.items
        let newEditHistory = []

        if (editHistory.length) {
          editHistory.forEach((row, i) => {
            const { updatedBy, date, changedFields } = row
            let changes

            try {
              changes = JSON.parse(changedFields)
            } catch (error) {
              showErrorMessageAction(pickErrorMessage(error))
            }

            if (changes) {
              Object.keys(changes).forEach((field, j) => {
                const { to, from } = changes[field]
                const tableId = i.toString() + j.toString()
                newEditHistory.push({
                  updatedBy,
                  date,
                  to,
                  from,
                  tableId,
                  field,
                })
              })
            }
          })
        }
        draft.splice(0, draft.length, ...newEditHistory)
        break
    }
  })

const deploymentsReducer = combineReducers({
  bySlug,
  visibleIds,
  counts,
  current,
  meta,
  editHistory,
})

const getDeploymentBySlug = ({ bySlug }, id) => bySlug[id]
const getVisibleDeployments = state =>
  state.visibleIds.map(id => getDeploymentBySlug(state, id))
const getCurrentDeployment = ({ current }) => current
const getDeploymentsMeta = ({ meta }) => meta
const getDeploymentCounts = ({ counts }) => counts
const getVisibleDeploymentEditHistory = state => state.editHistory

export {
  deploymentsReducer as default,
  getCurrentDeployment,
  getDeploymentBySlug,
  getVisibleDeployments,
  getDeploymentsMeta,
  getDeploymentCounts,
  getVisibleDeploymentEditHistory,
}
