import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Table, Column, Spin } from './common/Ant'
import { H3, H4 } from './common/Headers'
import Divider from './common/Divider'
import { dateTimeFormatter } from '../utils/date'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { ROLES, SMOOTH_INTERVAL } from '../constants'
import moment from 'moment'
import DateRange from './common/DateRange'
import { hasRole } from '../utils/hasPermission'
import { toTitleCase } from '../utils/textFormatters'
import Select, { Option } from './common/Select'
import FlowHistoryChart from './charts/FlowHistoryChart'
import FlowMonitorChart from './charts/FlowMonitorChart'

class FlowMonitorData extends Component {
  static propTypes = {
    updateQueryParams: PropTypes.func.isRequired,
    flowMonitorData: PropTypes.object.isRequired,
    getFlowMonitorData: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isByLocation: PropTypes.bool,
    pillarId: PropTypes.string.isRequired,
  }
  now = moment()

  constructor(props) {
    super(props)

    this.state = {
      now: this.now,
      endValue: this.now,
      startValue: moment(this.now).subtract(1, 'd'),
      smoothInterval: SMOOTH_INTERVAL.FIVE_MINUTES,
    }
  }

  static defaultProps = {
    flowMonitorData: {},
  }

  componentDidMount() {
    const { getFlowMonitorData, pillarId } = this.props
    const { startValue, endValue } = this.state

    const searchParams = parseQueryParams(global.location)

    let query
    if (searchParams.startTime && searchParams.endTime) {
      query = {
        startTime: searchParams.startTime,
        endTime: searchParams.endTime,
      }
      this.setState({
        startValue: moment(searchParams.startTime * 1000),
        endValue: moment(searchParams.endTime * 1000),
      })
    } else {
      query = {
        startTime: startValue.unix(),
        endTime: endValue.unix(),
      }
    }

    if (searchParams.smoothInterval || searchParams.smooth) {
      query = {
        ...query,
        smoothInterval: searchParams.smoothInterval,
        smooth: searchParams.smooth,
      }

      this.setState({
        smoothInterval: searchParams.smoothInterval
          ? searchParams.smoothInterval
          : !searchParams.smooth || searchParams.smooth === 't'
          ? SMOOTH_INTERVAL.ONE_HOUR
          : SMOOTH_INTERVAL.RAW,
      })
    }
    if (this.props.isByLocation) {
      query = {
        ...query,
        location: 'location',
      }
    }

    const qs = createQueryString(query)

    getFlowMonitorData(pillarId, qs)
  }

  onCloseDateRange = (startValue, endValue) => {
    const { getFlowMonitorData, pillarId, updateQueryParams } = this.props
    const { smoothInterval } = this.state

    this.setState({ startValue, endValue })

    let query = {
      startTime: startValue.unix(),
      endTime: endValue.unix(),
      smooth: smoothInterval === SMOOTH_INTERVAL.RAW ? 'f' : 't',
      smoothInterval:
        smoothInterval !== SMOOTH_INTERVAL.RAW ? smoothInterval : undefined,
    }
    if (this.props.isByLocation) {
      query = {
        ...query,
        location: 'location',
      }
    }
    const qs = createQueryString(query)

    updateQueryParams({
      pathname: global.location.pathname,
      search: qs,
    })
    getFlowMonitorData(pillarId, qs)
  }

  handleFilterChange = smoothInterval => {
    const { getFlowMonitorData, updateQueryParams, pillarId } = this.props
    const { startValue, endValue } = this.state

    this.setState({ smoothInterval })

    let query = {
      smooth: smoothInterval === SMOOTH_INTERVAL.RAW ? 'f' : 't',
      smoothInterval:
        smoothInterval !== SMOOTH_INTERVAL.RAW ? smoothInterval : undefined,
      startTime: startValue.unix(),
      endTime: endValue.unix(),
    }
    if (this.props.isByLocation) {
      query = {
        ...query,
        location: 'location',
      }
    }
    const qs = createQueryString(query)

    updateQueryParams({
      pathname: global.location.pathname,
      search: qs,
    })

    getFlowMonitorData(pillarId, qs)
  }

  render() {
    const { isLoading, flowMonitorData, updateQueryParams } = this.props
    const { startValue, endValue, smoothInterval } = this.state

    console.log("Flow Monitor Historical Data: ", flowMonitorData)
    console.log("Start Value: ", startValue)
    console.log("End Value: ", endValue)
    console.log("Smooth Value: ", smoothInterval)

    const lastTime =
      flowMonitorData && flowMonitorData.lastData ? (
        <div className="flex">
          <div className="b">Last Flow Reading</div>
          <span className="ml3 f5 fw4">
            {dateTimeFormatter(flowMonitorData.lastData.time)}
          </span>
        </div>
      ) : (
        'Last Flow Reading'
      )

    return isLoading ? (
      <Spin size="large" className="w-100 center mt5" />
    ) : (
      <Fragment>
        <div className="mb3" />
        <H3>{lastTime}</H3>
        {flowMonitorData &&
        flowMonitorData.tableData &&
        flowMonitorData.tableData.length ? (
          <div className="flex-ns">
            <div className="w-40-ns mr5-ns">
              <FlowMonitorChart
                flowValue={
                  flowMonitorData &&
                  flowMonitorData.lastData &&
                  flowMonitorData.lastData.flowGpm
                    ? flowMonitorData.lastData.flowGpm.toString()
                    : '0.0'
                }
                maxFlow={flowMonitorData ? flowMonitorData.maxFlow : 150}
                minFlow={flowMonitorData ? flowMonitorData.minFlow : 0}
              />
            </div>
            <div className="w-60-ns mt4-ns">
              <Table
                dataSource={flowMonitorData.tableData}
                rowKey="name"
                scroll={{ x: 400 }}
                pagination={false}
                tableLayout="auto"
                bordered
              >
                <Column
                  title="Recent Water Flow"
                  width={200}
                  dataIndex="name"
                  render={(text, record) => (text ? text : '--')}
                />
                <Column
                  title="Average Value - gal/min"
                  width={200}
                  dataIndex="avgValue"
                  render={(text, record) => (text ? text : '--')}
                />
              </Table>
            </div>
          </div>
        ) : (
          <H4 className="tc">No Data Available for this sensor</H4>
        )}
        <Divider />
        <H3>Historical Data</H3>
        <div className="charts">
          <div className="mb2 flex-ns">
            <DateRange
              label="Select date range to view Water Monitor readings"
              startValue={startValue}
              endValue={endValue}
              onClose={this.onCloseDateRange}
              shouldLimitRange={!hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN)}
            />
            <Select
              className="ml4-ns"
              label="Smoothing Interval"
              input={{
                value: smoothInterval,
                onChange: x => this.handleFilterChange(x),
              }}
            >
              {Object.keys(SMOOTH_INTERVAL).map(x => (
                <Option value={SMOOTH_INTERVAL[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Select>
          </div>
          {flowMonitorData.data && flowMonitorData.data.length ? (
            <FlowHistoryChart
              data={flowMonitorData.data}
              columns={flowMonitorData.columns}
              updateQueryParams={updateQueryParams}
            />
          ) : (
            <H4 className="tc">No Data Available for Selected Date Range</H4>
          )}
        </div>
      </Fragment>
    )
  }
}

export default FlowMonitorData
