const getWeatherByLatLng = async (lat, lng) => {
  try {
    const response = await fetch(
      `${process.env.VISUALCROSSING_API}${lat}%2C%20${lng}/today?key=${process.env.VISUALCROSSING_SECRET_KEY}`
    )

    if (response.ok) {
      return await response.json()
    } else {
      return Promise.reject('TODO ERRRRRRRRRRRRRRRRRRROR')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default getWeatherByLatLng
