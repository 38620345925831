import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputContainer from '../../containers/common/InputContainer'
import SelectContainer from '../../containers/common/SelectContainer'
import { normalizeDate, formatDateUTC } from '../common/DateSelector'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import { required } from '../../utils/validators'
import CheckboxGroup from '../common/CheckboxGroup'
import Checkbox from '../common/Checkbox'
import { H2, H3, H4 } from '../common/Headers'
// import { ActionAnchor } from '../common/Anchor'
import Form from '../common/Form'
import FormError from '../common/FormError'
import Divider from '../common/Divider'
import { Option } from '../common/Select'
import Button from '../common/Button'
import { Spin } from '../common/Ant'
import { unique } from '../../utils/helpers'
import { createQueryString } from '../../utils/queryParams'
import { isAlphaNumeric } from '../../utils/validators'
import { SHIPPING_VENDOR, DEPLOYMENT_STATUS } from '../../constants'
import { toTitleCase, formatShippingVendors } from '../../utils/textFormatters'

class ShipmentForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    deployment: PropTypes.object.isRequired,
    getSiteAssets: PropTypes.func.isRequired,
    regeneratePackingList: PropTypes.func.isRequired,
    getDeployment: PropTypes.func,
    getAllShipments: PropTypes.func,
    isDeploymentLoading: PropTypes.bool,
    match: PropTypes.object,
    formValues: PropTypes.object,
    error: PropTypes.string,
    siteSlug: PropTypes.string,
    siteAssets: PropTypes.object,
    siteShipments: PropTypes.arrayOf(PropTypes.object),
    isUninstall: PropTypes.bool,
    areShipmentsFinished: PropTypes.bool,
    hasShipped: PropTypes.bool,
    isDelivered: PropTypes.bool,
  }

  state = {
    submitError: false,
  }

  onChange = (key, val) => this.setState({ [key]: val })

  createFilterOptions = (
    options,
    resourceList,
    filterAvailable = true,
    shippedOptions = []
  ) => {
    // we need to seperate packed shipments from those still unpacked
    const { shipments } = this.props.deployment

    if (!shipments) {
      return filterAvailable ? options : []
    }
    // grab all the packed IDs
    const resources = shipments.map(shipment => shipment[resourceList])
    // get one array of all the ids
    const packedInventoryIds = []
      .concat(...resources, ...shippedOptions)
      .filter(unique)
    // separate those resources that match as packed
    const packedInventory = options.filter(
      opt => packedInventoryIds.indexOf(opt.id) > -1
    )
    let availableInventory = options.filter(
      opt => packedInventoryIds.indexOf(opt.id) === -1
    )
    return filterAvailable ? availableInventory : packedInventory
  }

  checkSubmit = (event, shipment, isAllPacked) => {
    const { formValues } = this.props

    // get pods and leak pods from multiple checkGroups to 1
    let allPods = []
    if (shipment.pods.length) {
      shipment.pods[0].forEach(podArr => {
        podArr.forEach(pod => {
          allPods.push(pod)
        })
      })
    }
    let allLeakPods = []
    if (shipment.leakPods.length) {
      shipment.leakPods[0].forEach(podArr => {
        podArr.forEach(pod => {
          allLeakPods.push(pod)
        })
      })
    }
    let allEvacPods = []
    if (shipment.evacPods.length) {
      shipment.evacPods[0].forEach(podArr => {
        podArr.forEach(pod => {
          allEvacPods.push(pod)
        })
      })
    }
    formValues.shipment['pods'] = allPods
    formValues.shipment['leakPods'] = allLeakPods
    formValues.shipment['evacPods'] = allEvacPods

    const { submitAction, handleSubmit } = this.props
    const submit = handleSubmit(submitAction)
    if (
      isAllPacked ||
      shipment.pods.length ||
      shipment.gateways.length ||
      shipment.flowMonitors.length ||
      shipment.leakPods.length ||
      shipment.evacPods.length ||
      shipment.anyGatewaySupply
    ) {
      this.setState({ submitError: false })
      submit(event)
    } else {
      this.setState({ submitError: true })
      event.preventDefault()
    }
  }

  createSitePodOptions = (siteAssets, shippedPodIds) => {
    const { nonDeployedPods, deployedPods } = siteAssets

    const sitePods = deployedPods.filter(p => p.pillarId)

    const groupedFloors = sitePods
      .map(({ floorId }) => floorId)
      .filter(x => x)
      .filter(unique)
      .map(id => sitePods.filter(pod => pod.floorId === id))

    const groupedBuildings = sitePods
      .map(({ buildingId }) => buildingId)
      .filter(x => x)
      .filter(unique)
      .map(b => {
        const floors = groupedFloors.filter(floor => floor[0].buildingId === b)
        const { buildingName } = floors[0][0]
        return {
          buildingName,
          floors: floors.map(floor => ({
            floorName: floor[0].floorName,
            activeOptions: this.createFilterOptions(
              floor,
              'podsList',
              true,
              shippedPodIds
            ),
            disabledOptions: this.createFilterOptions(
              floor,
              'podsList',
              false,
              shippedPodIds
            ),
          })),
        }
      })

    const unassignedFloors = groupedFloors.filter(floor => !floor[0].buildingId)

    if (unassignedFloors.length) {
      groupedBuildings.push({
        buildingName: 'Unassigned',
        floors: unassignedFloors.map(floor => ({
          floorName: floor[0].floorName,
          activeOptions: this.createFilterOptions(
            floor,
            'podsList',
            true,
            shippedPodIds
          ),
          disabledOptions: this.createFilterOptions(
            floor,
            'podsList',
            false,
            shippedPodIds
          ),
        })),
      })
    }

    if (!!Object.keys(nonDeployedPods).length) {
      groupedBuildings.push({
        buildingName: 'Unused Inventory',
        floors: Object.keys(nonDeployedPods).map(podStatus => {
          return {
            floorName: toTitleCase(podStatus),
            activeOptions: this.createFilterOptions(
              nonDeployedPods[podStatus],
              'podsList',
              true,
              shippedPodIds
            ),
            disabledOptions: this.createFilterOptions(
              nonDeployedPods[podStatus],
              'podsList',
              false,
              shippedPodIds
            ),
          }
        }),
      })
    }
    return groupedBuildings
  }

  createSiteLeakPodOptions = (siteAssets, shippedLeakPodIds) => {
    const { nonDeployedLeakPods, deployedLeakPods } = siteAssets

    const siteLeakPods = deployedLeakPods.filter(p => p.pillarId)

    const groupedFloors = siteLeakPods
      .map(({ floorId }) => floorId)
      .filter(x => x)
      .filter(unique)
      .map(id => siteLeakPods.filter(x => x.floorId === id))

    const groupedBuildings = siteLeakPods
      .map(({ buildingId }) => buildingId)
      .filter(x => x)
      .filter(unique)
      .map(b => {
        const floors = groupedFloors.filter(floor => floor[0].buildingId === b)
        const { buildingName } = floors[0][0]

        return {
          buildingName,
          floors: floors.map(floor => ({
            floorName: floor[0].floorName,
            activeOptions: this.createFilterOptions(
              floor,
              'leakPodsList',
              true,
              shippedLeakPodIds
            ),
            disabledOptions: this.createFilterOptions(
              floor,
              'leakPodsList',
              false,
              shippedLeakPodIds
            ),
          })),
        }
      })

    const unassignedFloors = groupedFloors.filter(floor => !floor[0].buildingId)

    if (unassignedFloors.length) {
      groupedBuildings.push({
        buildingName: 'Unassigned',
        floors: unassignedFloors.map(floor => ({
          floorName: floor[0].floorName,
          activeOptions: this.createFilterOptions(
            floor,
            'leakPodsList',
            true,
            shippedLeakPodIds
          ),
          disabledOptions: this.createFilterOptions(
            floor,
            'leakPodsList',
            false,
            shippedLeakPodIds
          ),
        })),
      })
    }

    if (!!Object.keys(nonDeployedLeakPods).length) {
      groupedBuildings.push({
        buildingName: 'Unused Inventory',
        floors: Object.keys(nonDeployedLeakPods).map(status => {
          return {
            floorName: toTitleCase(status),
            activeOptions: this.createFilterOptions(
              nonDeployedLeakPods[status],
              'leakPodsList',
              true,
              shippedLeakPodIds
            ),
            disabledOptions: this.createFilterOptions(
              nonDeployedLeakPods[status],
              'leakPodsList',
              false,
              shippedLeakPodIds
            ),
          }
        }),
      })
    }
    return groupedBuildings
  }

  createSiteEvacPodOptions = (siteAssets, shippedEvacPodIds) => {
    const { nonDeployedEvacPods, deployedEvacPods } = siteAssets

    const siteEvacPods = deployedEvacPods.filter(p => p.pillarId)

    const groupedFloors = siteEvacPods
      .map(({ floorId }) => floorId)
      .filter(x => x)
      .filter(unique)
      .map(id => siteEvacPods.filter(x => x.floorId === id))

    const groupedBuildings = siteEvacPods
      .map(({ buildingId }) => buildingId)
      .filter(x => x)
      .filter(unique)
      .map(b => {
        const floors = groupedFloors.filter(floor => floor[0].buildingId === b)
        const { buildingName } = floors[0][0]

        return {
          buildingName,
          floors: floors.map(floor => ({
            floorName: floor[0].floorName,
            activeOptions: this.createFilterOptions(
              floor,
              'evacPodsList',
              true,
              shippedEvacPodIds
            ),
            disabledOptions: this.createFilterOptions(
              floor,
              'evacPodsList',
              false,
              shippedEvacPodIds
            ),
          })),
        }
      })

    const unassignedFloors = groupedFloors.filter(floor => !floor[0].buildingId)

    if (unassignedFloors.length) {
      groupedBuildings.push({
        buildingName: 'Unassigned',
        floors: unassignedFloors.map(floor => ({
          floorName: floor[0].floorName,
          activeOptions: this.createFilterOptions(
            floor,
            'evacPodsList',
            true,
            shippedEvacPodIds
          ),
          disabledOptions: this.createFilterOptions(
            floor,
            'evacPodsList',
            false,
            shippedEvacPodIds
          ),
        })),
      })
    }

    if (!!Object.keys(nonDeployedEvacPods).length) {
      groupedBuildings.push({
        buildingName: 'Unused Inventory',
        floors: Object.keys(nonDeployedEvacPods).map(status => {
          return {
            floorName: toTitleCase(status),
            activeOptions: this.createFilterOptions(
              nonDeployedEvacPods[status],
              'evacPodsList',
              true,
              shippedEvacPodIds
            ),
            disabledOptions: this.createFilterOptions(
              nonDeployedEvacPods[status],
              'evacPodsList',
              false,
              shippedEvacPodIds
            ),
          }
        }),
      })
    }
    return groupedBuildings
  }

  generateCheckboxOptions = options =>
    options.map(option => ({
      label: option.pillarId,
      value: `${option.id}`,
    }))

  componentDidMount() {
    const {
      isUninstall,
      getSiteAssets,
      getAllShipments,
      siteSlug,
      deployment,
    } = this.props
    const { installType } = deployment

    if (isUninstall) {
      const query = {
        installType,
        deploymentStatus: [
          DEPLOYMENT_STATUS.CREATED,
          DEPLOYMENT_STATUS.PACKING,
          DEPLOYMENT_STATUS.PACKED,
          DEPLOYMENT_STATUS.REVIEWED,
          DEPLOYMENT_STATUS.SHIPPED,
        ],
      }
      const qs = createQueryString(query)

      getSiteAssets(siteSlug)
      getAllShipments(siteSlug, qs)
    }
  }

  render() {
    const {
      submitting,
      error,
      deployment,
      isDeploymentLoading,
      formValues,
      isUninstall,
      siteAssets,
      areShipmentsFinished,
      hasShipped,
      isDelivered,
      siteShipments,
      // TODO enable regeneratePackingList stuff!
      // siteSlug,
      // regeneratePackingList
    } = this.props

    const { submitError } = this.state
    const errorMsg = 'Select at least one asset to pack per shipment.'
    const { pods /*shouldRegenerateList, slug*/ } = deployment

    const gateways = isUninstall ? siteAssets.gateways : deployment.gateways
    const flowMonitors = isUninstall
      ? siteAssets.flowMonitors
      : deployment.flowMonitors

    const leakPods = isUninstall ? siteAssets.leakPods : deployment.leakPods
    const evacPods = isUninstall ? siteAssets.evacPods : deployment.evacPods

    const shippedPodIds = [].concat(
      ...siteShipments.map(shipment => shipment.podsList)
    )
    const shippedGatewayIds = [].concat(
      ...siteShipments.map(shipment => shipment.gatewaysList)
    )

    const shippedFlowMonitorIds = [].concat(
      ...siteShipments.map(shipment => shipment.flowMonitorsList)
    )

    const shippedLeakPodIds = [].concat(
      ...siteShipments.map(shipment => shipment.leakPodsList)
    )

    const shippedEvacPodIds = [].concat(
      ...siteShipments.map(shipment => shipment.evacPodsList)
    )

    const podOptions = isUninstall
      ? this.createSitePodOptions(siteAssets, shippedPodIds)
      : pods
      ? pods.map(pod => {
          return {
            assetLocation: pod.assetLocationPillarId,
            activeOptions: this.createFilterOptions(pod.group, 'podsList'),
            disabledOptions: this.createFilterOptions(
              pod.group,
              'podsList',
              false
            ),
          }
        })
      : []

    const leakPodOptions = isUninstall
      ? this.createSiteLeakPodOptions(siteAssets, shippedLeakPodIds)
      : leakPods
      ? leakPods.map(x => {
          return {
            assetLocation: x.assetLocationPillarId,
            activeOptions: this.createFilterOptions(x.group, 'leakPodsList'),
            disabledOptions: this.createFilterOptions(
              x.group,
              'leakPodsList',
              false
            ),
          }
        })
      : []

    const evacPodOptions = isUninstall
      ? this.createSiteEvacPodOptions(siteAssets, shippedEvacPodIds)
      : evacPods
      ? evacPods.map(x => {
          return {
            assetLocation: x.assetLocationPillarId,
            activeOptions: this.createFilterOptions(x.group, 'evacPodsList'),
            disabledOptions: this.createFilterOptions(
              x.group,
              'evacPodsList',
              false
            ),
          }
        })
      : []

    const packedPods =
      !isUninstall && podOptions.filter(pod => pod.activeOptions.length > 0)

    const packedLeakPods =
      !isUninstall && leakPodOptions.filter(x => x.activeOptions.length > 0)

    const packedEvacPods =
      !isUninstall && evacPodOptions.filter(x => x.activeOptions.length > 0)

    const gatewayOptions = this.createFilterOptions(
      gateways,
      'gatewaysList',
      true,
      shippedGatewayIds
    )
    const flowMonitorOptions = this.createFilterOptions(
      flowMonitors,
      'flowMonitorsList',
      true,
      shippedFlowMonitorIds
    )

    const isAllPacked = isUninstall
      ? areShipmentsFinished || hasShipped || isDelivered
      : areShipmentsFinished ||
        hasShipped ||
        isDelivered ||
        !(
          packedPods.length ||
          gatewayOptions.length ||
          flowMonitorOptions.length ||
          packedLeakPods.length ||
          packedEvacPods.length ||
          deployment.unpackedGatewaySupply
        )

    let buttonText, titleText, formText, dateField, dateButtonLabel

    if (hasShipped) {
      buttonText = 'Mark as Delivered'
      titleText = 'Update Status'
      formText =
        'When all shipments have been delivered, set a delivery date to mark this deployment as delivered.'
      dateField = 'shipment.deliveryDate'
      dateButtonLabel = 'Date Delivered'
    } else {
      buttonText = isAllPacked ? 'Mark as Shipped' : 'Submit For Packing'
      titleText = isAllPacked ? 'Set Shipment Date' : 'Select Assets to Pack'
      formText =
        'It looks like you have packed all assets! After everything has been shipped, set a shipment date to mark this deployment as shipped.'
      dateField = 'shipment.shippingDate'
      dateButtonLabel = 'Date Shipped'
    }

    const disabledGatewayOptions = this.createFilterOptions(
      gateways,
      'gatewaysList',
      false,
      shippedGatewayIds
    )

    const disabledFlowMonitorOptions = this.createFilterOptions(
      flowMonitors,
      'flowMonitorsList',
      false
    )

    const disabledValuesLabel = 'Disabled'

    // NOTE: We want to show a submission error if there's only PODs remaining
    // in an uninstall deployment, just not on every single floor. We display
    // the submission error on the last floor of the last building.
    return isDeploymentLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      !isDelivered && (
        <section className="ShipmentForm mb3">
          <div className="flex justify-between">
            <H2>{titleText}</H2>
            {
              // shouldRegenerateList &&
              // !isUninstall &&
              // !isAllPacked && (
              //   <ActionAnchor
              //     className="mb3"
              //     onClick={() => regeneratePackingList(siteSlug, slug)}
              //     button
              //   >
              //     Regenerate Packing List
              //   </ActionAnchor>
              // )
            }
          </div>
          <Form
            onSubmit={e =>
              this.checkSubmit(e, formValues.shipment, isAllPacked)
            }
          >
            {isAllPacked ? (
              <div>
                <p className="measure-wide">{formText}</p>
                <Field
                  name={dateField}
                  type="date"
                  className="mw5"
                  component={DateSelectorContainer}
                  label={dateButtonLabel}
                  format={formatDateUTC}
                  normalize={normalizeDate}
                  validate={[required]}
                />
              </div>
            ) : (
              <div>
                <div>
                  {!!podOptions.length && (
                    <div className="ba b--light-gray br2 pa3 mb3 bg-near-white">
                      <H3>Smart Pods</H3>
                      {!isUninstall
                        ? podOptions.map((opt, ind) => (
                            <Field
                              name={`shipment.pods[0][${ind}]`}
                              component={CheckboxGroup}
                              availableOptions={this.generateCheckboxOptions(
                                opt.activeOptions
                              )}
                              disabledOptions={this.generateCheckboxOptions(
                                opt.disabledOptions
                              )}
                              key={opt.assetLocation}
                              label={opt.assetLocation}
                              disabledValuesLabel={disabledValuesLabel}
                              className="w-100-ns flex-wrap bg-white ba br2 b--light-gray pa3"
                              meta={{
                                error: errorMsg,
                                touched:
                                  submitError &&
                                  !gatewayOptions.length &&
                                  !flowMonitorOptions.length &&
                                  !leakPodOptions.length &&
                                  !evacPodOptions.length,
                              }}
                            />
                          ))
                        : podOptions.map((entry, i) => (
                            <div
                              className="ba b--light-gray br2 pa3 mb3 bg-light-gray"
                              key={entry.buildingName}
                            >
                              <H4>{entry.buildingName}</H4>
                              {entry.floors.map((floor, j) => (
                                <Field
                                  name={`shipment.pods[${i}][${j}]`}
                                  component={CheckboxGroup}
                                  availableOptions={this.generateCheckboxOptions(
                                    floor.activeOptions
                                  )}
                                  disabledOptions={this.generateCheckboxOptions(
                                    floor.disabledOptions
                                  )}
                                  key={floor.floorName}
                                  label={floor.floorName}
                                  disabledValuesLabel={disabledValuesLabel}
                                  className="w-100-ns flex-wrap bg-white ba br2 b--light-gray pa3"
                                  meta={{
                                    error: errorMsg,
                                    touched:
                                      submitError &&
                                      !gatewayOptions.length &&
                                      !flowMonitorOptions.length &&
                                      !leakPodOptions.length &&
                                      !evacPodOptions.length &&
                                      i === podOptions.length - 1 &&
                                      j === entry.floors.length - 1,
                                  }}
                                />
                              ))}
                            </div>
                          ))}
                    </div>
                  )}
                  {!!gateways.length && (
                    <div className="ba b--light-gray br2 pa3 mb3 bg-near-white">
                      <H3>Network Gateways</H3>
                      <Field
                        name="shipment.gateways"
                        component={CheckboxGroup}
                        availableOptions={this.generateCheckboxOptions(
                          gatewayOptions
                        )}
                        disabledOptions={this.generateCheckboxOptions(
                          disabledGatewayOptions
                        )}
                        disabledValuesLabel={disabledValuesLabel}
                        className="w-100-ns flex-wrap bg-white ba br2 b--light-gray pa3"
                        meta={{
                          error: errorMsg,
                          touched:
                            submitError &&
                            gatewayOptions.length &&
                            !flowMonitorOptions.length &&
                            !leakPodOptions.length &&
                            !evacPodOptions.length,
                        }}
                      />
                    </div>
                  )}
                  {!!flowMonitors.length && (
                    <div className="ba b--light-gray br2 pa3 mb3 bg-near-white">
                      <H3>Water Monitors</H3>
                      <Field
                        name="shipment.flowMonitors"
                        component={CheckboxGroup}
                        availableOptions={this.generateCheckboxOptions(
                          flowMonitorOptions
                        )}
                        disabledOptions={this.generateCheckboxOptions(
                          disabledFlowMonitorOptions
                        )}
                        disabledValuesLabel={disabledValuesLabel}
                        className="w-100-ns flex-wrap bg-white ba br2 b--light-gray pa3"
                        meta={{
                          error: errorMsg,
                          touched: submitError && flowMonitorOptions.length,
                        }}
                      />
                    </div>
                  )}
                  {!!leakPodOptions.length && (
                    <div className="ba b--light-gray br2 pa3 mb3 bg-near-white">
                      <H3>Leak Pucks</H3>
                      {!isUninstall
                        ? leakPodOptions.map((opt, ind) => (
                            <Field
                              name={`shipment.leakPods[0][${ind}]`}
                              component={CheckboxGroup}
                              availableOptions={this.generateCheckboxOptions(
                                opt.activeOptions
                              )}
                              disabledOptions={this.generateCheckboxOptions(
                                opt.disabledOptions
                              )}
                              key={opt.assetLocation}
                              label={opt.assetLocation}
                              disabledValuesLabel={disabledValuesLabel}
                              className="w-100-ns flex-wrap bg-white ba br2 b--light-gray pa3"
                              meta={{
                                error: errorMsg,
                                touched:
                                  submitError &&
                                  !gatewayOptions.length &&
                                  !flowMonitorOptions.length &&
                                  !leakPodOptions.length,
                              }}
                            />
                          ))
                        : leakPodOptions.map((entry, i) => (
                            <div
                              className="ba b--light-gray br2 pa3 mb3 bg-light-gray"
                              key={entry.buildingName}
                            >
                              <H4>{entry.buildingName}</H4>
                              {entry.floors.map((floor, j) => (
                                <Field
                                  name={`shipment.leakPods[${i}][${j}]`}
                                  component={CheckboxGroup}
                                  availableOptions={this.generateCheckboxOptions(
                                    floor.activeOptions
                                  )}
                                  disabledOptions={this.generateCheckboxOptions(
                                    floor.disabledOptions
                                  )}
                                  key={floor.floorName}
                                  label={floor.floorName}
                                  disabledValuesLabel={disabledValuesLabel}
                                  className="w-100-ns flex-wrap bg-white ba br2 b--light-gray pa3"
                                  meta={{
                                    error: errorMsg,
                                    touched:
                                      submitError &&
                                      !gatewayOptions.length &&
                                      !flowMonitorOptions.length &&
                                      !leakPodOptions.length &&
                                      i === leakPodOptions.length - 1 &&
                                      j === entry.floors.length - 1,
                                  }}
                                />
                              ))}
                            </div>
                          ))}
                    </div>
                  )}
                  {!!evacPodOptions.length && (
                    <div className="ba b--light-gray br2 pa3 mb3 bg-near-white">
                      <H3>Evac Pucks</H3>
                      {!isUninstall
                        ? evacPodOptions.map((opt, ind) => (
                            <Field
                              name={`shipment.evacPods[0][${ind}]`}
                              component={CheckboxGroup}
                              availableOptions={this.generateCheckboxOptions(
                                opt.activeOptions
                              )}
                              disabledOptions={this.generateCheckboxOptions(
                                opt.disabledOptions
                              )}
                              key={opt.assetLocation}
                              label={opt.assetLocation}
                              disabledValuesLabel={disabledValuesLabel}
                              className="w-100-ns flex-wrap bg-white ba br2 b--light-gray pa3"
                              meta={{
                                error: errorMsg,
                                touched:
                                  submitError &&
                                  !gatewayOptions.length &&
                                  !flowMonitorOptions.length &&
                                  !evacPodOptions.length,
                              }}
                            />
                          ))
                        : evacPodOptions.map((entry, i) => (
                            <div
                              className="ba b--light-gray br2 pa3 mb3 bg-light-gray"
                              key={entry.buildingName}
                            >
                              <H4>{entry.buildingName}</H4>
                              {entry.floors.map((floor, j) => (
                                <Field
                                  name={`shipment.evacPods[${i}][${j}]`}
                                  component={CheckboxGroup}
                                  availableOptions={this.generateCheckboxOptions(
                                    floor.activeOptions
                                  )}
                                  disabledOptions={this.generateCheckboxOptions(
                                    floor.disabledOptions
                                  )}
                                  key={floor.floorName}
                                  label={floor.floorName}
                                  disabledValuesLabel={disabledValuesLabel}
                                  className="w-100-ns flex-wrap bg-white ba br2 b--light-gray pa3"
                                  meta={{
                                    error: errorMsg,
                                    touched:
                                      submitError &&
                                      !gatewayOptions.length &&
                                      !flowMonitorOptions.length &&
                                      !evacPodOptions.length &&
                                      i === evacPodOptions.length - 1 &&
                                      j === entry.floors.length - 1,
                                  }}
                                />
                              ))}
                            </div>
                          ))}
                    </div>
                  )}

                  {deployment.unpackedGatewaySupply && (
                    <div className="ba b--light-gray br2 pa3 mb3 bg-near-white">
                      <Field
                        name="shipment.anyGatewaySupply"
                        className="w-30-ns w-100"
                        type="checkbox"
                        component={Checkbox}
                        label="All Gateway Supplies"
                      />
                    </div>
                  )}
                </div>
                <div className="flex-ns justify-content">
                  <Field
                    name="shipment.trackingNumber"
                    component={InputContainer}
                    label="Tracking Number"
                    validate={[isAlphaNumeric]}
                    className="w-50-ns mr3-ns"
                    placeholder="Enter tracking information"
                  />
                  <Field
                    name="shipment.vendor"
                    component={SelectContainer}
                    label="Vendor"
                    className="w-50-ns"
                    filterable
                  >
                    {Object.keys(SHIPPING_VENDOR).map(x => (
                      <Option value={x} key={x}>
                        {formatShippingVendors(SHIPPING_VENDOR[x])}
                      </Option>
                    ))}
                  </Field>
                </div>
                <div className="flex-ns justify-content">
                  <Field
                    name="shipment.returnTrackingNumber"
                    component={InputContainer}
                    label="Return Tracking Number"
                    validate={[isAlphaNumeric]}
                    className="w-50-ns mr3-ns"
                    placeholder="Enter return tracking information"
                  />
                  <Field
                    name="shipment.returnVendor"
                    component={SelectContainer}
                    label="Return Vendor"
                    className="w-50-ns"
                    filterable
                  >
                    {Object.keys(SHIPPING_VENDOR).map(x => (
                      <Option value={x} key={x}>
                        {formatShippingVendors(SHIPPING_VENDOR[x])}
                      </Option>
                    ))}
                  </Field>
                </div>
              </div>
            )}
            <Button text={buttonText} type="submit" submitting={submitting} />
            <FormError error={error} />
          </Form>
          <Divider />
        </section>
      )
    )
  }
}

export default reduxForm({ form: 'ShipmentForm' })(ShipmentForm)
