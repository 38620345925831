import produce from 'immer'
import { combineReducers } from 'redux'
import sortBy from 'sort-by'
import {
  getAllSitesAction,
  getSiteAction,
  getSiteAvailableUsersAction,
  updateSiteAction,
  generateGrafanaConfigAction,
  getWeatherByLatLngAction,
  getAllSiteThresholdUserMappingsAction,
  getAllSiteAggregateThresholdUserMappingsAction,
  getSiteAssetsAction,
  CLEAR_WEATHER,
  getAllUsersBySiteAction,
  getPodAssignmentHistoryBySiteAction,
  getAllAggregateThresholdsBySiteAction,
  getActivityLogAction,
  getSiteContractorsAction,
  getRiMetricsAction,
  uploadUsersCSVAction,
  getFloorPlanSnapsAction,
  getInventoryHistoryBySiteAction,
  getAllInvoicesAction,
  getAllDocumentsAction,
} from '../actions/sitesActions'

const bySlug = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllSitesAction.SUCCESS:
        payload.items.forEach(site => {
          draft[site.slug] = site
        })
        break
    }
  })

const visibleSlugs = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllSitesAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(site => site.slug)
        )
        break
    }
  })

const visibleActivityLog = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getActivityLogAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map((activity, id) => {
            return { ...activity, id }
          })
        )
        break
    }
  })

const riMetricsByLocationId = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getRiMetricsAction.SUCCESS:
        payload.forEach(x => (draft[x.locationId] = x))
        break
    }
  })

const visibleRiMetrics = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getRiMetricsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.map(x => x.locationId))
        break
    }
  })

const activityLogMeta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getActivityLogAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const meta = (state = {}, action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllSitesAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const current = (
  state = {
    gateways: [],
    users: [],
    availableUsers: [],
    thresholds: [],
    grafanaConfig: null,
    primaryContactUser: {},
    secondaryContactUser: {},
    stats: {
      smartPod: {},
      leakPod: {},
      evacPod: {},
      flowMonitor: {},
      buildingCount: 0,
      floorCount: 0,
    },
  },
  action
) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getSiteAction.SUCCESS:
        if (payload.users && payload.users.length) {
          payload.users.forEach(
            x => (x.fullName = `${x.firstName} ${x.lastName}`)
          )
        }

        Object.assign(draft, payload)
        break
      case updateSiteAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case getSiteAvailableUsersAction.SUCCESS:
        const availableUsers = payload.filter(
          x => draft.users.map(x => x.id).indexOf(x.id) === -1
        )
        Object.assign(draft, { availableUsers })
        break
      case generateGrafanaConfigAction.SUCCESS:
        draft.grafanaConfig = payload
        break
    }
  })

const invoices = (state = [], { payload, type }) =>
  produce(state, draft => {
    switch (type) {
      case getAllInvoicesAction.SUCCESS:
        draft.splice(0, draft.length, ...payload)
    }
  })

const documents = (state = [], { payload, type }) =>
  produce(state, draft => {
    switch (type) {
      case getAllDocumentsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload)
    }
  })

const currentSiteAssets = (
  state = {
    nonDeployedPods: {},
    gateways: [],
    deployedPods: [],
    flowMonitors: [],
    deployedLeakPods: [],
    nonDeployedLeakPods: {},
    deployedEvacPods: [],
    nonDeployedEvacPods: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case getSiteAssetsAction.SUCCESS:
        const { pods, gateways, flowMonitors, leakPods, evacPods } = action.payload

        const { deployed, ...nonDeployedPods } = pods
        const { deployed: deployedLeakPods, ...nonDeployedLeakPods } = leakPods
        const { deployed: deployedEvacPods, ...nonDeployedEvacPods } = evacPods

        Object.assign(draft, {
          nonDeployedPods: nonDeployedPods ? nonDeployedPods : {},
          deployedPods: deployed ? deployed : [],
          gateways: gateways ? gateways : [],
          flowMonitors: flowMonitors ? flowMonitors : [],
          nonDeployedLeakPods: nonDeployedLeakPods ? nonDeployedLeakPods : {},
          deployedLeakPods: deployedLeakPods ? deployedLeakPods : [],
          nonDeployedEvacPods: nonDeployedEvacPods ? nonDeployedEvacPods : {},
          deployedEvacPods: deployedEvacPods ? deployedEvacPods : [],
        })
        break
    }
  })

const currentWeather = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getWeatherByLatLngAction.SUCCESS:
        Object.assign(draft, payload)
        break
      case CLEAR_WEATHER:
        return {}
    }
  })

const thresholdUserMappings = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllSiteThresholdUserMappingsAction.SUCCESS:
        const result = payload.users
          .map(user => {
            const thresholds = payload.userThresholdMapping
              .filter(x => x.user === user.slug)
              .map(x => {
                const index = payload.thresholds
                  .map(y => y.thresholdSlug)
                  .indexOf(x.threshold)

                const active =
                  x.settings.smsNotifications || x.settings.emailNotifications
                const mappingId = x.settings.id

                return { ...payload.thresholds[index], active, mappingId }
              })

            return Object.assign(user, { thresholds })
          })
          .sort(sortBy('role'))

        draft.splice(0, draft.length, ...result)
        break
    }
  })

const aggregateThresholdUserMappings = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllSiteAggregateThresholdUserMappingsAction.SUCCESS:
        const result = payload.users
          .map(user => {
            const thresholds = payload.userThresholdMapping
              .filter(x => x.user === user.slug)
              .map(x => {
                const index = payload.thresholds
                  .map(y => y.id)
                  .indexOf(x.thresholdMappingId)

                const active =
                  x.settings.smsNotifications || x.settings.emailNotifications
                const mappingId = x.settings.id

                return { ...payload.thresholds[index], active, mappingId }
              })

            return Object.assign(user, { thresholds })
          })
          .sort(sortBy('role'))

        draft.splice(0, draft.length, ...result)
        break
    }
  })

const siteUsersById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllUsersBySiteAction.SUCCESS:
        payload.items.forEach(
          x =>
            (draft[x.id] = { ...x, fullName: `${x.firstName} ${x.lastName}` })
        )
        break
    }
  })

const visibleSiteUserIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllUsersBySiteAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(({ id }) => id)
        )
        break
    }
  })

const siteAggregateThresholdsById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAggregateThresholdsBySiteAction.SUCCESS:
        payload.forEach(x => (draft[x.id] = x))
        break
    }
  })

const visibleAggregateThresholds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAggregateThresholdsBySiteAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.map(({ id }) => id))
        break
    }
  })

const assignmentHistoryById = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getPodAssignmentHistoryBySiteAction.SUCCESS:
        payload.forEach(x => (draft[x.id] = x))
        break
    }
  })

const visibleAssignmentHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getPodAssignmentHistoryBySiteAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.map(({ id }) => id))
        break
    }
  })

const csvUploadResults = (
  state = {
    addedCount: 0,
    failedCount: 0,
    failed: [],
  },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case uploadUsersCSVAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const allSiteContractors = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getSiteContractorsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload)
        break
    }
  })

const floorplanSnaps = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getFloorPlanSnapsAction.SUCCESS:
        draft.splice(0, draft.length, ...payload)
        break
    }
  })

const inventoryHistory = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getInventoryHistoryBySiteAction.SUCCESS:
        const { monthlyInventory } = payload
        const keys = Object.keys(monthlyInventory)
        const result = keys.map(x => ({
          date: x,
          assignedLocationCount: monthlyInventory[x].assignedLocationIds.length,
          gatewaysCount: monthlyInventory[x].gateways.length,
          smartPodsCount: monthlyInventory[x].smartPods.length,
          leakPucksCount: monthlyInventory[x].leakPucks.length,
          evacPucksCount: monthlyInventory[x].evacPucks.length,
          waterMetersCount: monthlyInventory[x].waterMeters.length,
          waterValvesCount: monthlyInventory[x].waterValves.length,
          ...monthlyInventory[x],
        }))
        draft.splice(0, draft.length, ...result)
        break
    }
  })

const lostInventory = (
  state = {
    gateways: [],
    smartPods: [],
    leakPucks: [],
    evacPucks: [],
    waterMeters: [],
    waterValves: [],
  },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getInventoryHistoryBySiteAction.SUCCESS:
        Object.assign(draft, payload.lostInventory)
        break
    }
  })

const sitesReducer = combineReducers({
  allSiteContractors,
  bySlug,
  visibleSlugs,
  current,
  meta,
  currentWeather,
  thresholdUserMappings,
  aggregateThresholdUserMappings,
  currentSiteAssets,
  siteUsersById,
  visibleSiteUserIds,
  siteAggregateThresholdsById,
  visibleAggregateThresholds,
  assignmentHistoryById,
  visibleAssignmentHistory,
  activityLogMeta,
  visibleActivityLog,
  riMetricsByLocationId,
  visibleRiMetrics,
  csvUploadResults,
  floorplanSnaps,
  inventoryHistory,
  lostInventory,
  invoices,
  documents,
})

const getSite = (state, slug) => state.bySlug[slug]
const getCurrentSite = state => state.current
const getVisibleSites = state =>
  state.visibleSlugs.map(slug => getSite(state, slug))
const getSitesMeta = state => state.meta
const getActivityLogMeta = state => state.activityLogMeta
const getCurrentWeather = ({ currentWeather }) => currentWeather
const getSiteThresholdUserMappings = ({ thresholdUserMappings }) =>
  thresholdUserMappings
const getSiteAggregateThresholdUserMappings = ({
  aggregateThresholdUserMappings,
}) => aggregateThresholdUserMappings
const getCurrentSiteAssets = state => state.currentSiteAssets
const getSiteUser = (state, id) => state.siteUsersById[id]
const getVisibleSiteUsers = state =>
  state.visibleSiteUserIds.map(id => getSiteUser(state, id))
const getAggregateThreshold = (state, id) =>
  state.siteAggregateThresholdsById[id]
const getVisibleAggregateThresholds = state =>
  state.visibleAggregateThresholds.map(id => getAggregateThreshold(state, id))
const getVisibleActivityLog = state => state.visibleActivityLog
const getRiMetrics = (state, id) => state.riMetricsByLocationId[id]
const getVisibleRiMetrics = state =>
  state.visibleRiMetrics.map(id => getRiMetrics(state, id))
const getAssignmentHistory = (state, id) => state.assignmentHistoryById[id]
const getVisiblePodAssignmentBySiteHistory = state =>
  state.visibleAssignmentHistory.map(id => getAssignmentHistory(state, id))
const getCSVUploadResults = ({ csvUploadResults }) => csvUploadResults
const getSiteFloorPlanSnaps = ({ floorplanSnaps }) => floorplanSnaps
const getVisibleSiteContractors = ({ allSiteContractors }) => allSiteContractors
const getInventoryHistory = ({ inventoryHistory }) => inventoryHistory
const getLostInventory = ({ lostInventory }) => lostInventory
const getSiteInvoices = ({ invoices }) => invoices
const getSiteDocuments = ({ documents }) => documents

export {
  sitesReducer as default,
  getCurrentSite,
  getVisibleSites,
  getVisibleSiteContractors,
  getSitesMeta,
  getCurrentWeather,
  getSiteThresholdUserMappings,
  getSiteAggregateThresholdUserMappings,
  getCurrentSiteAssets,
  getVisibleSiteUsers,
  getVisibleAggregateThresholds,
  getVisiblePodAssignmentBySiteHistory,
  getVisibleActivityLog,
  getActivityLogMeta,
  getVisibleRiMetrics,
  getCSVUploadResults,
  getSiteFloorPlanSnaps,
  getInventoryHistory,
  getLostInventory,
  getSiteInvoices,
  getSiteDocuments,
}
