import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import SelectContainer from '../../containers/common/SelectContainer'
import Button from '../common/Button'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import Label from '../common/Label'
import { ActionAnchor } from '../common/Anchor'
import Select, { Option } from '../common/Select'
import { required } from '../../utils/validators'
import { hasPermission } from '../../utils/hasPermission'
import { USER_PERMISSIONS } from '../../constants'

class EditLocationForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      locationType: 'pod',
    }
  }

  static propTypes = {
    initialValues: PropTypes.object,
    tags: PropTypes.arrayOf(PropTypes.object),
    submitAction: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    siteSlug: PropTypes.string.isRequired,
    floorId: PropTypes.string.isRequired,
    error: PropTypes.string,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableLeakPods: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableEvacPods: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableFlowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    availableSiteGateways: PropTypes.arrayOf(PropTypes.object),
    podPillarId: PropTypes.string,
    flowMonitorPillarId: PropTypes.string,
    removePodFromLocation: PropTypes.func,
    removeFlowMonitorFromLocation: PropTypes.func,
    handleModalSubmit: PropTypes.func,
    handleModalCancel: PropTypes.func,
    valid: PropTypes.bool.isRequired,
    isAddingNewLocation: PropTypes.bool,
    isFlowLocationTypeSelected: PropTypes.bool,
    isLeakPodTypeSelected: PropTypes.bool,
    isEvacPodTypeSelected: PropTypes.bool,
    isGatewayLocationTypeSelected: PropTypes.bool,
  }

  handleLocationTypeChange = locationType => this.setState({ locationType })

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      valid,
      submitAction,
      siteSlug,
      floorId,
      allAvailablePods,
      allAvailableLeakPods,
      allAvailableEvacPods,
      availableFlowMonitors,
      availableSiteGateways,
      isGatewayLocationTypeSelected,
      podPillarId,
      // initialValues,
      isFlowLocationTypeSelected,
      isLeakPodTypeSelected,
      isEvacPodTypeSelected,
      isAddingNewLocation,
      flowMonitorPillarId,
      removePodFromLocation,
      removeFlowMonitorFromLocation,
      handleModalSubmit,
      handleModalCancel,
      tags,
    } = this.props

    const { locationType } = this.state

    const submit = handleSubmit(submitAction)
    const modalSubmit = event => {
      if (valid) {
        handleModalSubmit()
      }

      submit(event)
    }

    const submitFn = handleModalSubmit ? modalSubmit : submit

    // TODO how to handle using CancelButton?
    const cancelBtnProps = handleModalCancel
      ? { onClick: handleModalCancel }
      : { to: `/sites/${siteSlug}/floors/${floorId}` }

    return (
      <section className="EditLocationForm">
        <Form onSubmit={submitFn}>
          <div className="flex-ns">
            {!isGatewayLocationTypeSelected && !isAddingNewLocation && (
              <Field
                name="name"
                type="text"
                component={InputContainer}
                label="Name"
                validate={[required]}
                className="w-50-ns mr3-ns"
              />
            )}
            {!isGatewayLocationTypeSelected && isAddingNewLocation && (
              <Field
                name="name"
                type="text"
                component={InputContainer}
                label="Name"
                validate={[required]}
                className="w-100-ns"
              />
            )}
            {flowMonitorPillarId ? (
              <div className="w-50-ns mb3">
                <Label>Assigned Water Monitor</Label>
                <div className="flex mt2">
                  <div className="mr2 b">{flowMonitorPillarId}</div>
                  <ActionAnchor onClick={removeFlowMonitorFromLocation}>
                    (remove)
                  </ActionAnchor>
                </div>
              </div>
            ) : podPillarId ? (
              <div className="w-50-ns mb3">
                <Label>Assigned Smart Pod</Label>
                <div className="flex mt2">
                  <div className="mr2 b">{podPillarId}</div>
                  <ActionAnchor onClick={removePodFromLocation}>
                    (remove)
                  </ActionAnchor>
                </div>
              </div>
            ) : isGatewayLocationTypeSelected ? (
              <Field
                name="gatewayId"
                component={SelectContainer}
                label="Network Gateway"
                placeholder="Select a gateway"
                className="w-100-ns"
                filterable
              >
                {availableSiteGateways &&
                  availableSiteGateways.map(x => (
                    <Option value={x.gatewayId} key={x.gatewayId}>
                      {x.gatewayPillarId}
                    </Option>
                  ))}
              </Field>
            ) : (
              !isAddingNewLocation && (
                <Select
                  className="w-50-ns"
                  label="Select Location Type"
                  defaultValue={flowMonitorPillarId ? 'flow' : locationType}
                  input={{
                    value: locationType,
                    onChange: this.handleLocationTypeChange,
                  }}
                >
                  <Option value="pod">Smart Pod</Option>
                  <Option
                    value="leak"
                    disabled={
                      !(allAvailableLeakPods && allAvailableLeakPods.length)
                    }
                  >
                    Leak Puck
                  </Option>
                  {hasPermission(USER_PERMISSIONS.BUILDING_EVACUATION) && (<Option
                        value="evac"
                        disabled={
                        !(allAvailableEvacPods && allAvailableEvacPods.length)
                        }
                    >
                        Evac Pod
                    </Option>)}
                  {hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
                    <Option
                      value="flow"
                      disabled={
                        !(availableFlowMonitors && availableFlowMonitors.length)
                      }
                    >
                      Water Monitor
                    </Option>
                  )}
                </Select>
              )
            )}
          </div>
          <div className="flex-ns">
            {!flowMonitorPillarId &&
              !podPillarId &&
              !isGatewayLocationTypeSelected &&
              (isFlowLocationTypeSelected || locationType === 'flow' ? (
                <Field
                  name="flowMonitorId"
                  component={SelectContainer}
                  label="Water Monitor"
                  placeholder="Select a flow monitor"
                  className="w-30-ns mr3-ns"
                  filterable
                >
                  {availableFlowMonitors.map(x => (
                    <Option value={x.id} key={x.id}>
                      {x.pillarId}
                    </Option>
                  ))}
                </Field>
              ) : (
                <Field
                  name="podId"
                  component={SelectContainer}
                  label="Smart Pod"
                  placeholder="Select a pod"
                  className="w-30-ns mr3-ns"
                  filterable
                >
                  {(locationType === 'leak' || isLeakPodTypeSelected
                    ? allAvailableLeakPods
                    : locationType === 'evac' || isEvacPodTypeSelected
                    ? allAvailableEvacPods
                    : allAvailablePods
                  ).map(x => (
                    <Option value={x.id} key={x.id}>
                      {x.pillarId}
                    </Option>
                  ))}
                </Field>
              ))}
            <Field
              name="description"
              type="text"
              component={InputContainer}
              label="Description"
              className="w-100-ns"
            />
          </div>
          {hasPermission(USER_PERMISSIONS.VIEW_TAG) && tags && (
            <Fragment>
              <Divider />
              <div className="flex-ns">
                <Field
                  name="tagIds"
                  component={SelectContainer}
                  label="Tags"
                  mode="multiple"
                  className="w-100"
                  props={{ placeholder: 'Select Tags' }}
                  filterable
                >
                  {tags.map(tag => (
                    <Option value={tag.id} key={tag.id}>
                      {tag.name}
                    </Option>
                  ))}
                </Field>
              </div>
            </Fragment>
          )}
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <Button invert text="Cancel" {...cancelBtnProps} />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditLocationForm' })(EditLocationForm)
