import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import FormError from '../common/FormError'
import { Steps, Step } from '../common/Ant'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import RadioGroup, { RadioButton } from '../common/RadioGroup'
import Divider from '../common/Divider'
import Block from '../common/Block'
import { normalizeDate, formatDateUTC } from '../common/DateSelector'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import { required, isNumeric } from '../../utils/validators'
import { toTitleCase } from '../../utils/textFormatters'
import { dateFormatter } from '../../utils/date'
import { DEPLOYMENT_TYPE, INSTALL_TYPE, SIZES } from '../../constants'

class CreateDeploymentForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    anyTouched: PropTypes.bool.isRequired,
    touch: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    error: PropTypes.string,
    match: PropTypes.object,
    formValues: PropTypes.object,
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  static defaultProps = {
    formValues: {},
  }

  constructor(props) {
    super(props)

    this.state = {
      current: 0,
      responsiblePartyUser: null,
    }

    this.inputs = {}
  }

  handleNext = event => {
    const { valid, touch } = this.props

    // requiredFields in next step have default values
    // anyTouched not needed here
    if (valid) {
      const current = this.state.current + 1
      this.setState({ current })
    } else {
      // touch all the fields in input
      const fieldNames = Object.keys(this.inputs).map(
        x => this.inputs[x].props.name
      )
      touch(...fieldNames)
    }

    event.preventDefault()
  }

  handlePrev = event => {
    const current = this.state.current - 1
    this.setState({ current })

    event.preventDefault()
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      submitAction,
      formValues,
      siteUsers,
    } = this.props
    const { responsiblePartyUser } = this.state

    const { siteSlug } = this.props.match.params
    const { current } = this.state
    const submit = handleSubmit(submitAction)

    const numSteps = 4
    let steps = [
      {
        title: 'Deployment Details',
        content: (
          <div>
            <div className="flex-l items-center-l justify-around mv2">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="estimatedDate"
                type="date"
                className="w-25-ns"
                component={DateSelectorContainer}
                label="Estimated Date"
                format={formatDateUTC}
                normalize={normalizeDate}
                validate={[required]}
                disabledDateRangeType="before"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="description"
                type="text"
                className="w-25-ns"
                component={InputContainer}
                validate={[required]}
                label="Description"
              />
              <Field
                name="installType"
                type="text"
                size="medium"
                component={RadioGroup}
                label="Install Type"
              >
                {Object.keys(INSTALL_TYPE).map(x => (
                  <RadioButton value={x} key={x}>
                    {toTitleCase(INSTALL_TYPE[x])}
                  </RadioButton>
                ))}
              </Field>
              <Field
                name="deploymentType"
                type="text"
                size="medium"
                component={RadioGroup}
                label="Deployment Type"
              >
                {Object.keys(DEPLOYMENT_TYPE).map(x => (
                  <RadioButton value={x} key={x}>
                    {toTitleCase(DEPLOYMENT_TYPE[x])}
                  </RadioButton>
                ))}
              </Field>
            </div>
            <div className="flex justify-between">
              <Button
                disabled={current === 0}
                size={SIZES.MEDIUM}
                onClick={this.handlePrev}
                icon="arrow-left"
                overrideText
              />
              <Button
                disabled={current >= numSteps - 1}
                size={SIZES.MEDIUM}
                onClick={this.handleNext}
                icon="arrow-right"
                overrideText
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Resource Estimates',
        content: (
          <div>
            <div className="flex-ns justify-around mv2 pl3">
              <Field
                name="estimatedPods"
                type="text"
                component={InputContainer}
                label="Estimated Smart Pods"
                validate={[isNumeric, required]}
                className="mr3-ns w-33-ns w-100"
              />
              <Field
                name="estimatedLeakPods"
                type="text"
                component={InputContainer}
                label="Estimated Leak Pucks"
                validate={[isNumeric, required]}
                className="mr3-ns w-33-ns w-100"
              />
              <Field
                name="estimatedEvacPods"
                type="text"
                component={InputContainer}
                label="Estimated Evac Pods"
                validate={[isNumeric, required]}
                className="mr3-ns w-33-ns w-100"
              />
              <Field
                name="estimatedGateways"
                type="text"
                component={InputContainer}
                label="Estimated Network Gateways"
                validate={[isNumeric, required]}
                className="mr3-ns w-33-ns w-100"
              />
            </div>
            <div className="flex-ns pl3">
              <Field
                name="estimatedFlowMonitors"
                type="text"
                component={InputContainer}
                label="Estimated Water Monitors"
                validate={[isNumeric, required]}
                className="mr3-ns w-50-ns w-100"
              />
            </div>
            <div className="flex justify-between">
              <Button
                disabled={current === 0}
                size={SIZES.MEDIUM}
                onClick={this.handlePrev}
                icon="arrow-left"
                overrideText
              />
              <Button
                disabled={current >= numSteps - 1}
                size={SIZES.MEDIUM}
                onClick={this.handleNext}
                icon="arrow-right"
                overrideText
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Responsible Party',
        content: (
          <div>
            <div className="flex-ns justify-around mv2 pl3">
              <Field
                name="responsiblePartyUserId"
                component={SelectContainer}
                label="Responsible Party"
                placeholder="Select a user to handle this Deployment"
                className="w-100"
                onChange={x => {
                  const index = siteUsers.map(x => x.id).indexOf(x)
                  const responsiblePartyUser = siteUsers[index]

                  this.setState({ responsiblePartyUser })
                }}
                filterable
              >
                {siteUsers.map(x => (
                  <Option value={x.id} key={x.id}>
                    {x.firstName} {x.lastName}
                  </Option>
                ))}
              </Field>
            </div>
            <div className="flex justify-between">
              <Button
                disabled={current === 0}
                size={SIZES.MEDIUM}
                onClick={this.handlePrev}
                icon="arrow-left"
                overrideText
              />
              <Button
                disabled={current >= numSteps - 1}
                size={SIZES.MEDIUM}
                onClick={this.handleNext}
                icon="arrow-right"
                overrideText
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Summary and Submit',
        content: (
          <div>
            <div className="flex flex-wrap">
              <Block size={SIZES.MEDIUM} label="Estimated Network Gateways">
                {formValues.estimatedGateways}
              </Block>
              <Block size={SIZES.MEDIUM} label="Estimated Smart Pods">
                {formValues.estimatedPods}
              </Block>
              <Block size={SIZES.MEDIUM} label="Estimated Leak Pucks">
                {formValues.estimatedLeakPods}
              </Block>
              <Block size={SIZES.MEDIUM} label="Estimated Evac Pods">
                {formValues.estimatedEvacPods}
              </Block>
              <Block size={SIZES.MEDIUM} label="Estimated Water Monitors">
                {formValues.estimatedFlowMonitors}
              </Block>
              <Block size={SIZES.MEDIUM} label="Deployment Type">
                {toTitleCase(formValues.deploymentType)}
              </Block>
              <Block size={SIZES.MEDIUM} label="Install Type">
                {toTitleCase(formValues.installType)}
              </Block>
              <Block size={SIZES.MEDIUM} label="Estimated Date">
                {dateFormatter(formValues.estimatedDate)}
              </Block>
              <Block size={SIZES.MEDIUM} label="Responsible Party">
                {responsiblePartyUser &&
                  `${responsiblePartyUser.firstName} ${responsiblePartyUser.lastName}`}
              </Block>
            </div>
            <div className="flex justify-between">
              <Button
                disabled={current === 0}
                size={SIZES.MEDIUM}
                onClick={this.handlePrev}
                icon="arrow-left"
                overrideText
              />
              <Button
                disabled={current >= numSteps - 1}
                size={SIZES.MEDIUM}
                onClick={this.handleNext}
                icon="arrow-right"
                overrideText
              />
            </div>
          </div>
        ),
      },
    ]

    return (
      <section className="CreateDeploymentForm">
        <Divider />
        <Form onSubmit={submit}>
          <Steps current={current} className="mb3">
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="mb3 ba b--light-gray br2 pa2">
            {steps[this.state.current].content}
          </div>
          {current === steps.length - 1 ? (
            <div className="db flex justify-between">
              <CancelButton defaultLocation={`/sites/${siteSlug}`} />
              <Button text="Submit" type="submit" submitting={submitting} />
            </div>
          ) : null}
          <FormError error={error} />
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'CreateDeploymentForm' })(CreateDeploymentForm)
