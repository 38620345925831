import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Column, Menu, Icon, Dropdown } from './common/Ant'
import { H3 } from './common/Headers'
import { Anchor } from './common/Anchor'
import Divider from './common/Divider'
import { hasPermission } from '../utils/hasPermission'
import {
  formatShippingVendors,
  getShippingVendorsURL,
} from '../utils/textFormatters'
import { USER_PERMISSIONS } from '../constants'

class AllShipments extends Component {
  static propTypes = {
    shipments: PropTypes.arrayOf(PropTypes.object),
    siteSlug: PropTypes.string.isRequired,
    deploymentSlug: PropTypes.string,
    shippingDate: PropTypes.string,
    windowWidth: PropTypes.number,
  }

  render() {
    const {
      siteSlug,
      deploymentSlug,
      shipments,
      windowWidth,
      shippingDate,
    } = this.props

    const isFixedColumn = windowWidth < 750

    // NOTE: If we have tracking # but no vendor,
    // we render tracking # without link
    return (
      <section className="AllShipments mb3">
        <H3 inline>Shipments</H3>
        <Table
          dataSource={shipments}
          rowKey="id"
          pagination={false}
          scroll={{
            x:
              hasPermission(USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT) ||
              hasPermission(USER_PERMISSIONS.EDIT_UNINSTALL_DEPLOYMENT)
                ? 650
                : 550,
          }}
          tableLayout="auto"
          bordered
        >
          <Column
            title="ID"
            dataIndex="id"
            width={100}
            render={(text, record) => (
              <Anchor
                to={`/sites/${siteSlug}/deployments/${deploymentSlug}/shipments/${record.id}`}
              >
                {text}
              </Anchor>
            )}
          />
          <Column
            title="Tracking Number"
            dataIndex="trackingNumber"
            width={100}
            render={(text, { trackingNumber, vendor }) =>
              trackingNumber ? (
                vendor ? (
                  <a
                    className="link dark-blue"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${getShippingVendorsURL(vendor, trackingNumber)}`}
                  >
                    {trackingNumber}
                  </a>
                ) : (
                  trackingNumber
                )
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Vendor"
            dataIndex="vendor"
            width={50}
            render={(text, { vendor }) =>
              vendor ? formatShippingVendors(vendor) : '--'
            }
          />
          <Column
            title="Return Tracking Number"
            dataIndex="returnTrackingNumber"
            width={100}
            render={(text, { returnTrackingNumber, returnVendor }) =>
              returnTrackingNumber ? (
                returnVendor ? (
                  <a
                    className="link dark-blue"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${getShippingVendorsURL(
                      returnVendor,
                      returnTrackingNumber
                    )}`}
                  >
                    {returnTrackingNumber}
                  </a>
                ) : (
                  returnTrackingNumber
                )
              ) : (
                '--'
              )
            }
          />
          <Column
            title="Return Vendor"
            dataIndex="returnVendor"
            width={50}
            render={(text, { returnVendor }) =>
              returnVendor ? formatShippingVendors(returnVendor) : '--'
            }
          />
          <Column
            title="Network Gateways"
            dataIndex="gateways"
            width={50}
            render={(text, { gatewaysList }) => gatewaysList.length}
          />
          <Column
            title="Smart Pods"
            dataIndex="pods"
            width={50}
            render={(text, { podsList }) => podsList.length}
          />
          <Column
            title="Leak Pucks"
            dataIndex="leakPods"
            width={50}
            render={(text, { leakPodsList }) => leakPodsList.length}
          />
          <Column
            title="Evac Pods"
            dataIndex="evacPods"
            width={50}
            render={(text, { evacPodsList }) => evacPodsList.length}
          />
          <Column
            title="Water Monitors"
            dataIndex="flowMonitors"
            width={50}
            render={(text, { flowMonitorsList }) => flowMonitorsList.length}
          />
          <Column
            title="Gateway Supplies"
            dataIndex="anyGatewaySupply"
            width={50}
            render={(text, { anyGatewaySupply }) =>
              anyGatewaySupply ? 'Yes' : 'No'
            }
          />
          {(hasPermission(USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT) ||
            hasPermission(USER_PERMISSIONS.EDIT_UNINSTALL_DEPLOYMENT)) &&
            !shippingDate && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={100}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      <Menu.Item key="edit-shipment">
                        <Anchor
                          to={`/sites/${siteSlug}/deployments/${deploymentSlug}/shipments/${record.id}/edit`}
                        >
                          Edit
                        </Anchor>
                      </Menu.Item>
                    </Menu>
                  )

                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
        </Table>
        <Divider />
      </section>
    )
  }
}

export default AllShipments
