import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditLocationForm from '../components/forms/EditLocationForm'
import { H2 } from './common/Headers'
import { createLocationAction } from '../actions/locationsActions'
import { createQueryString } from '../utils/queryParams'
import { USER_PERMISSIONS, POD_TYPES } from '../constants'
import { hasPermission } from '../utils/hasPermission'
import { flagEnabled } from '../utils/config'

const isFlowMonitorEnabled = flagEnabled('REACT_APP_ENABLE_FLOW_MONITOR')

class CreateLocation extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllTags: PropTypes.func.isRequired,
    match: PropTypes.object,
    getAllAvailablePods: PropTypes.func.isRequired,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableLeakPods: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableEvacPods: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAvailableFlowMonitorsBySite: PropTypes.func.isRequired,
    availableFlowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    accountProfile: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const {
      match,
      accountProfile,
      getAllAvailablePods,
      getAllAvailableFlowMonitorsBySite,
      getAllTags,
    } = this.props
    const { siteSlug } = match.params

    if (accountProfile.role) {
      getAllAvailablePods(siteSlug)
      getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.LEAK_POD })
      )
      getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.EVAC_POD })
      )
      isFlowMonitorEnabled &&
        hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
        getAllAvailableFlowMonitorsBySite(siteSlug)
      getAllTags(
        createQueryString({
          perPage: 'all',
        })
      )
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      match,
      accountProfile,
      getAllAvailableFlowMonitorsBySite,
      getAllAvailablePods,
    } = this.props
    const { siteSlug } = match.params

    if (!accountProfile.role && nextProps.accountProfile.role) {
      getAllAvailablePods(siteSlug)
      getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.LEAK_POD })
      )
      getAllAvailablePods(
        siteSlug,
        createQueryString({ type: POD_TYPES.EVAC_POD })
      )
      //TODO: get either pods or flow monitor based on selection
      isFlowMonitorEnabled &&
        hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) &&
        getAllAvailableFlowMonitorsBySite(siteSlug)
    }
  }

  render() {
    const {
      tags,
      availableFlowMonitors,
      allAvailablePods,
      allAvailableLeakPods,
      allAvailableEvacPods,
      match,
    } = this.props

    return (
      <section className="CreateLocation">
        <H2>Create Location</H2>
        <EditLocationForm
          initialValues={{ tagIds: [] }}
          tags={tags}
          submitAction={createLocationAction}
          allAvailablePods={allAvailablePods}
          allAvailableLeakPods={allAvailableLeakPods}
          allAvailableEvacPods={allAvailableEvacPods}
          availableFlowMonitors={availableFlowMonitors}
          siteSlug={match.params.siteSlug}
          floorId={match.params.floorId}
        />
      </section>
    )
  }
}

export default CreateLocation
